import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "top-section" }
const _hoisted_2 = { class: "mt-5" }
const _hoisted_3 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "container",
    isLoading: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_icon, { onClick: _ctx.closeModal }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" mdi-arrow-left")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createElementVNode("h3", null, _toDisplayString(_ctx.roadMapModel.Title), 1)
          ]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.roadMapModel.Description), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "8",
                  class: "h-100"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, { density: "compact" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_subheader, null, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode("Steps")
                          ])),
                          _: 1
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roadMapModel.Steps, (item, index) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: index,
                            value: item,
                            color: "primary",
                            onClick: ($event: any) => (_ctx.goToLink(item))
                          }, {
                            prepend: _withCtx(() => [
                              (item.Type == 'Course')
                                ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                                      _createTextVNode(" mdi-human-male-board")
                                    ])),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                                      _createTextVNode(" mdi-ab-testing")
                                    ])),
                                    _: 1
                                  }))
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_row, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_col, { cols: "5" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(index + 1) + "- " + _toDisplayString(_ctx.truncate(item.Title)), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, { cols: "2" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getStepLevel(item)), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, { cols: "2" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getStepDuration(item)), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_col, { cols: "3" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getStepCounts(item)), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["value", "onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { cols: "4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_data_table, {
                      items: _ctx.roadMapModel.Talents,
                      headers: _ctx.tableHeaders,
                      "item-value": "name"
                    }, null, 8, ["items", "headers"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["isLoading"]))
}