<template>
  <v-row>
    <v-col cols="12" class="d-flex flex-row">
      <div class="search mr-2">
        <TextField
          v-model="searchName"
          @keyup.enter="searchHandler"
          placeholder="Search For..."
          append-inner-icon="mdi-magnify"
          @click:append-inner="searchHandler"
          hide-details
        />
      </div>
      <div>
        <v-btn class="roadmap-button" @click="toggleNewRoadMap">
          New Training Roadmap
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      searchName: "",
    };
  },
  methods: {
    searchHandler() {
      this.$emit("doSearch", {
        text: this.searchName,
      });
    },
    toggleNewRoadMap() {
      this.$router.push({
        name: "addRoadmap",
      });
    },
  },
  watch: {
    criteria: {
      handler(val) {
        this.searchHandler();
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";

.default-section {
  background-color: $tarmac;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  gap: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.roadmap-button {
  background-color: $racecar;
  height: 50px !important;
  padding: 0px 10px !important;
}
.search {
  width: 80%;
}
@media (max-width: 600px) {
  .roadmap-button {
    width: 100%;
    margin: 0px;
  }
}
</style>
