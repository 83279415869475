import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navigation-drp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, {
      location: "bottom",
      transition: "scale-transition"
    }, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_v_btn, _mergeProps({ variant: "text" }, props, { icon: "mdi-plus" }), null, 16)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, { to: "/client/virtualDesktop" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Smart Workbench")
              ])),
              _: 1
            }),
            _createVNode(_component_v_list_item, { onClick: _ctx.GoToProject }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Project")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_list_item, { to: "/client/projectLead" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Project Lead")
              ])),
              _: 1
            }),
            _createVNode(_component_v_list_item, { to: "/client/portfolioManager" }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Portfolio Manager")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}