import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_loading = _resolveComponent("loading")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        isLoading: _ctx.showLoading,
        class: "h-100"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["isLoading"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSnakbars, (snakbar, index) => {
        return (_openBlock(), _createBlock(_component_v_snackbar, {
          left: "",
          class: "snakbar",
          bottom: "",
          key: snakbar.id,
          color: snakbar.status,
          modelValue: snakbar.show,
          "onUpdate:modelValue": ($event: any) => ((snakbar.show) = $event),
          timeout: snakbar.timeout,
          style: _normalizeStyle(`bottom: ${index * 60 + 10}px;`)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(snakbar.body), 1)
          ]),
          _: 2
        }, 1032, ["color", "modelValue", "onUpdate:modelValue", "timeout", "style"]))
      }), 128))
    ]),
    _: 1
  }))
}