import Utils from "@/Helpers/Utils";
import PortfolioManagerReport from "shared-components/src/models/PortfolioManagerReport";
import ApiService from "./ApiService";
import Timesheet from "shared-components/src/models/Timesheet";
import { ManagerCustomerModel } from "shared-components/src/services/openApi/api";

export default class PortfolioManagerService {
  public static async getList(
    startDate: Date,
    endDate: Date
  ): Promise<PortfolioManagerReport> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/reports/client/portfoliomanager`, {
        startDate,
        endDate,
      })
        .then((result) => {
          if (!result.data) {
            resolve({} as PortfolioManagerReport);
          } else {
            var timesheets = [] as Timesheet[];
            var PortfolioManagerReport = result.data as PortfolioManagerReport;
            result.data.timesheets.forEach((doc: any) => {
              if (doc.Date) {
                doc.Date = new Date(doc.Date);
                doc.Date = Utils.removeTimezoneOffset(doc.Date);
              }
              const item = { id: doc.id, ...doc } as Timesheet;
              timesheets.push(item);
            });
            PortfolioManagerReport.timesheets = timesheets;
            resolve(PortfolioManagerReport);
          }
        })
        .catch((commitmentError) => reject(commitmentError));
    });
  }

  public static async getPortfolioManagerList(): Promise<
    ManagerCustomerModel[]
  > {
    return new Promise((resolve, reject) => {
      ApiService.get(`/client/portfolioManagers`, "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              return { ...doc } as ManagerCustomerModel;
            });
            resolve(items);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
