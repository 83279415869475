import Project from "shared-components/src/models/Project";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DecalGeometry } from "three/examples/jsm/geometries/DecalGeometry";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { mergeGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import { Font, FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Capsule } from "three/examples/jsm/math/Capsule";
import { Octree } from "three/examples/jsm/math/Octree";
import { toRaw } from "vue";
import gsap from "gsap";
import Details from "shared-components/src/models/Details";
import moment from "moment";
import CoreSkill from "shared-components/src/models/CoreSkill";
import { PositionModel } from "shared-components/src/services/openApi/api";

let skills = [] as CoreSkill[];
let details = [] as Details[];
let projectsList = [] as Project[];

let isOrbitMode = false;
let camera = {} as THREE.PerspectiveCamera;
let renderer = {} as THREE.Renderer;
let scene = {} as THREE.Scene;
let controls = null as OrbitControls | null;
let raycaster = {} as THREE.Raycaster;

let playerVelocity = {} as THREE.Vector3;
let playerDirection = {} as THREE.Vector3;
let playerCollider = {} as Capsule;

let worldOctree = {} as Octree;
let stepPerFrame = 5;
let clock = {} as THREE.Clock;
let intersection = [] as THREE.Intersection[];
let keyStates = {} as any;
let mouseMove = false;
let mouse = {} as THREE.Vector2;
let mesh = [] as any[];

let tableObject = {} as THREE.Group;
let chairObject = {} as THREE.Group;
let infoTableObject = {} as THREE.Group;
let animationFrameId = 0;
let fontModel = {} as Font;
let iconFontModel = {} as Font;
let canvasBounds = {} as DOMRect;
let UpdateShowNewPositionStatusFunction = (value: boolean) => {};
let UpdateShowUpdatePositionStatusFunction = (value: boolean) => {};
let UpdateShowNewProjectStatusFunction = (value: boolean) => {};
let UpdateShowSearchTeammemberStatusFunction = (value: boolean) => {};
let UpdateSelectedProjectIdFunction = (value: string) => {};
let UpdateSelectedPositionIdFunction = (value: string) => {};
let UpdateSelectedProjectModelFunction = (value: Project | null) => {};

const ScenHelper = {
  InitScene(
    refScene: any,
    projects: Project[],
    UpdateShowNewPositionStatus: any,
    UpdateShowUpdatePositionStatus: any,
    UpdateShowNewProjectStatus: any,
    UpdateSelectedProjectId: any,
    UpdateSelectedPositionId: any,
    UpdateSelectedProjectModel: any,
    UpdateShowSearchTeammemberStatus: any,
    Details: any,
    Skills: any
  ) {
    UpdateShowNewPositionStatusFunction = UpdateShowNewPositionStatus;
    UpdateShowUpdatePositionStatusFunction = UpdateShowUpdatePositionStatus;
    UpdateShowNewProjectStatusFunction = UpdateShowNewProjectStatus;
    UpdateSelectedProjectIdFunction = UpdateSelectedProjectId;
    UpdateSelectedPositionIdFunction = UpdateSelectedPositionId;
    UpdateSelectedProjectModelFunction = UpdateSelectedProjectModel;
    UpdateShowSearchTeammemberStatusFunction = UpdateShowSearchTeammemberStatus;
    details = Details;
    skills = Skills;
    projectsList = projects;
    camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.rotation.order = "YXZ";
    clock = new THREE.Clock();
    THREE.Cache.enabled = true;
    const r = "../../3dModels/texture/";

    const urls = [
      r + "px.jpg",
      r + "nx.jpg",
      r + "py.jpg",
      r + "ny.jpg",
      r + "pz.jpg",
      r + "nz.jpg",
    ];
    const textureCube = new THREE.CubeTextureLoader().load(urls);
    textureCube.mapping = THREE.CubeRefractionMapping;
    scene = new THREE.Scene();
    scene.background = textureCube;

    const light = new THREE.HemisphereLight(0xffffff, 0xffffff, 3);
    light.position.set(0, 0, 0);
    scene.add(light);

    worldOctree = new Octree();
    playerCollider = new Capsule(
      new THREE.Vector3(0, 1.7, 0),
      new THREE.Vector3(0, 1.7, 0),
      0.35
    );
    playerVelocity = new THREE.Vector3();
    playerDirection = new THREE.Vector3();

    document.addEventListener("keydown", (event) => {
      keyStates[event.code] = true;
    });

    document.addEventListener("keyup", (event) => {
      keyStates[event.code] = false;
    });

    //window.addEventListener("beforeunload", this.disposeScene);
    raycaster = new THREE.Raycaster(
      new THREE.Vector3(),
      new THREE.Vector3(0, -1, 0),
      0,
      10
    );
    mouse = new THREE.Vector2(1, 1);

    refScene.addEventListener("mousedown", () => {
      mouseMove = true;
    });
    refScene.addEventListener("mouseup", (event: any) => {
      mouseMove = false;
    });
    refScene.addEventListener("click", (event: any) => {
      if (!isOrbitMode) {
        mouseMove = false;
        const canvasBo = renderer.domElement.getBoundingClientRect();
        mouse.x =
          ((event.clientX - canvasBo.left) / (canvasBo.right - canvasBo.left)) *
            2 -
          1;
        mouse.y =
          -((event.clientY - canvasBo.top) / (canvasBo.bottom - canvasBo.top)) *
            2 +
          1;
        raycaster.setFromCamera(mouse, camera);

        intersection = raycaster.intersectObjects(mesh, true);
        if (intersection.length > 0) {
          if (intersection[0].object.name == "CreateProjectButton") {
            UpdateShowNewProjectStatusFunction(true);
            cancelAnimationFrame(animationFrameId);
          }
          if (intersection[0].object.name == "ProjectTable") {
            const menuGroup = scene.getObjectByName(
              "MenuGroup-" + intersection[0].object.userData.ProjectGroupId
            );

            if (menuGroup) {
              menuGroup.visible = !menuGroup.visible;
            }
          }
          if (intersection[0].object.name == "MenuGroup") {
            UpdateSelectedProjectIdFunction(intersection[0].object.userData.Id);
            UpdateShowNewPositionStatusFunction(true);
            cancelAnimationFrame(animationFrameId);
          }
          if (
            intersection[0].object.name == "PositionTable" ||
            intersection[0].object.name == "PositionTable-Desk" ||
            intersection[0].object.name == "PositionTable-Screen"
          ) {
            isOrbitMode = true;
            const monitorObject = scene.getObjectByName(
              `PositionMonitor-${intersection[0].object.userData.PositionId}`
            );
            if (monitorObject) {
              const boundingBox = new THREE.Box3();
              boundingBox.setFromObject(monitorObject);
              controls = new OrbitControls(camera, renderer.domElement);
              controls.target.copy(boundingBox.min);
              const center = boundingBox.getCenter(new THREE.Vector3());

              const pl = gsap.timeline();

              let centerX = center.x + 0.34;
              let centerZ = center.z - 0.0;
              let centerY = center.y - 0.0;
              if (monitorObject.userData.PositionIndex % 2 == 1) {
                centerX = center.x - 0.34;
                centerZ = center.z + 0.0;
                centerY = center.y + 0.0;
              }
              pl.to(controls.target, {
                duration: 0.5,
                x: center.x,
                y: center.y,
                z: center.z,
                onUpdate: () => {
                  controls?.update();
                },
              }).to(camera.position, {
                delay: 0.7,
                duration: 1,
                x: centerX,
                y: centerY,
                z: centerZ,
                onUpdate: () => {
                  controls?.update();
                },
                onComplete: () => {
                  if (controls) {
                    controls.enabled = false;
                    const positionStatusGroup = monitorObject.getObjectByName(
                      "PositionStatusGroup"
                    );
                    if (positionStatusGroup) {
                      positionStatusGroup.position.z -= 0.0005;
                    }
                  }
                },
              });
            }
          }
          if (intersection[0].object.name.includes("CommitmentTable")) {
            const commitmentMonitor = scene.getObjectByName(
              `CommitmentTableMonitor-${intersection[0].object.userData.CommitmentId}`
            );
            if (commitmentMonitor) {
              isOrbitMode = true;
              const boundingBox = new THREE.Box3();
              boundingBox.setFromObject(commitmentMonitor);
              controls = new OrbitControls(camera, renderer.domElement);
              controls.target.copy(boundingBox.min);
              const center = boundingBox.getCenter(new THREE.Vector3());

              const pl = gsap.timeline();

              let centerX = center.x + 0.34;
              let centerZ = center.z - 0.0;
              let centerY = center.y - 0.0;

              if (commitmentMonitor.userData.PositionIndex % 2 == 1) {
                centerX = center.x - 0.34;
                centerZ = center.z + 0.0;
                centerY = center.y + 0.0;
              }
              pl.to(controls.target, {
                duration: 0.5,
                x: center.x,
                y: center.y,
                z: center.z,
                onUpdate: () => {
                  controls?.update();
                },
              }).to(camera.position, {
                delay: 0.7,
                duration: 1,
                x: centerX,
                y: centerY,
                z: centerZ,
                onUpdate: () => {
                  controls?.update();
                },
                onComplete: () => {
                  if (controls) {
                    controls.enabled = false;
                  }
                },
              });
            }
          }
        }
      } else {
        mouseMove = false;
        const canvasBo = renderer.domElement.getBoundingClientRect();
        mouse.x =
          ((event.clientX - canvasBo.left) / (canvasBo.right - canvasBo.left)) *
            2 -
          1;
        mouse.y =
          -((event.clientY - canvasBo.top) / (canvasBo.bottom - canvasBo.top)) *
            2 +
          1;
        raycaster.setFromCamera(mouse, camera);

        intersection = raycaster.intersectObjects(mesh, true);
        if (intersection.length > 0) {
          if (intersection[0].object.name == "UpdatePositionData") {
            let projectModel = projectsList.find(
              (item) => item.id == intersection[0].object.userData.ProjectId
            );
            if (projectModel) {
              UpdateSelectedPositionIdFunction(
                intersection[0].object.userData.PositionId
              );
              UpdateSelectedProjectModelFunction(projectModel);
              UpdateShowUpdatePositionStatusFunction(true);
              cancelAnimationFrame(animationFrameId);
            }
          }
          if (intersection[0].object.name == "AssignTeammember") {
            let projectModel = projectsList.find(
              (item) => item.id == intersection[0].object.userData.ProjectId
            );
            if (projectModel) {
              UpdateSelectedPositionIdFunction(
                intersection[0].object.userData.PositionId
              );
              UpdateSelectedProjectModelFunction(projectModel);
              UpdateShowSearchTeammemberStatusFunction(true);
              cancelAnimationFrame(animationFrameId);
            }
          }
          if (intersection[0].object.name == "BackMenu") {
            let projectModel = projectsList.find(
              (item) => item.id == intersection[0].object.userData.ProjectId
            );
            if (projectModel) {
              UpdateSelectedPositionIdFunction("");
              UpdateSelectedProjectModelFunction(null);
              controls?.dispose();
              isOrbitMode = false;
            }
            const monitorObject = scene.getObjectByName(
              `PositionMonitor-${intersection[0].object.userData.PositionId}`
            );
            if (monitorObject) {
              const positionStatusGroup = monitorObject.getObjectByName(
                "PositionStatusGroup"
              );
              if (positionStatusGroup) {
                positionStatusGroup.position.z += 0.0005;
              }
            }
          }
          if (
            intersection[0].object.name == "CommitmentTable-Monitor-BackMenu"
          ) {
            controls?.dispose();
            isOrbitMode = false;
          }
        }
      }
    });
    refScene.addEventListener("mousemove", (event: any) => {
      if (!isOrbitMode) {
        if (mouseMove) {
          camera.rotation.y -= event.movementX / 200;
          camera.rotation.x -= event.movementY / 200;
          camera.position.z = 10;
        } else {
          mouse.x =
            ((event.clientX - canvasBounds.left) /
              (canvasBounds.right - canvasBounds.left)) *
              2 -
            1;
          mouse.y =
            -(
              (event.clientY - canvasBounds.top) /
              (canvasBounds.bottom - canvasBounds.top)
            ) *
              2 +
            1;
          raycaster.setFromCamera(mouse, camera);

          intersection = raycaster.intersectObjects(mesh, true);
          if (intersection.length > 0) {
            (document.querySelector("body") as any).style.cursor = "pointer";
          } else {
            (document.querySelector("body") as any).style.cursor = "default";
          }
        }
      }
    });
    const gltfLoader = new GLTFLoader();
    gltfLoader.loadAsync("../../3dModels/room.glb").then((object) => {
      scene.add(object.scene);
      worldOctree.fromGraphNode(object.scene);
      object.scene.traverse((child: any) => {
        if (child.isMesh) {
          child.castShadow = true;
          child.receiveShadow = true;

          if (child.material.map) {
            child.material.map.anisotropy = 1;
          }
        }
      });
    });
    gltfLoader.loadAsync("../../3dModels/table.glb").then((object) => {
      object.scene.traverse((child: any) => {
        if (
          child.name != "ScreenMonitor2" &&
          child.name != "ScreenMonitor1" &&
          !child.name.includes("desk")
        ) {
          child.name = "PositionTable";
        }

        if (
          child.name.includes("desk") ||
          child.parent?.name.includes("PositionTable-Desk")
        ) {
          child.name = "PositionTable-Desk";
        }

        if (child.isMesh) {
          child.castShadow = true;
          child.receiveShadow = true;

          if (child.material.map) {
            child.material.map.anisotropy = 4;
          }
        }
      });
      tableObject = object.scene;

      gltfLoader.loadAsync("../../3dModels/infoTable.glb").then((object) => {
        object.scene.traverse((child: any) => {
          if (child.name != "monitorScreen") {
            child.name = "ProjectTable";
          }

          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;

            if (child.material.map) {
              child.material.map.anisotropy = 4;
            }
          }
        });
        infoTableObject = object.scene;

        gltfLoader.loadAsync("../../3dModels/chair.glb").then((object) => {
          object.scene.traverse((child: any) => {
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = true;

              if (child.material.map) {
                child.material.map.anisotropy = 4;
              }
            }
          });
          object.scene.scale.set(1.4, 1.4, 1.4);
          object.scene.rotateY(80);
          chairObject = object.scene;
          this.initProjects();
        });
      });
    });

    const loader = new FontLoader();
    loader.load("../../3dModels/icon-font.json", (font) => {
      iconFontModel = font;
    });
    loader.load("../../3dModels/helvetiker_regular.typeface.json", (font) => {
      fontModel = font;
      let createProject = new TextGeometry("Add A new Project", {
        font: font,
        size: 0.5,
        depth: 0.1,
      });
      createProject.computeBoundingBox();
      let materials = [
        new THREE.MeshPhongMaterial({ color: 0x000, flatShading: true }), // front
        new THREE.MeshPhongMaterial({ color: 0x000 }), // side
      ];
      let textMesh1 = new THREE.Mesh(createProject, materials);
      textMesh1.position.setY(2);
      textMesh1.position.setZ(-57.5);
      textMesh1.position.setX(-20);
      textMesh1.name = "CreateProjectButton";
      scene.add(textMesh1);
      mesh.push(textMesh1);
    });

    renderer = new THREE.WebGLRenderer({ antialias: true });
    (renderer as any).setPixelRatio(window.devicePixelRatio);
    const container = document.getElementById("sceneContainer");
    renderer.setSize(container?.offsetWidth ?? 0, container?.offsetHeight ?? 0);
    refScene.appendChild(renderer.domElement);
    canvasBounds = renderer.domElement.getBoundingClientRect();
    window.addEventListener("resize", this.onWindowResize);
    this.animate();
  },
  initProjects() {
    projectsList.forEach((project, index) => {
      ScenHelper.AddProjectToScene(project, index);
    });
    UpdateSelectedProjectIdFunction("");
  },
  updatePlayer(deltaTime: any) {
    let damping = Math.exp(-2 * deltaTime) - 1;

    playerVelocity.addScaledVector(playerVelocity, damping);

    const deltaPosition = playerVelocity.clone().multiplyScalar(deltaTime);
    playerCollider.translate(deltaPosition);

    this.playerCollisions();

    camera.position.copy(playerCollider.end);
  },
  getForwardVector() {
    camera.getWorldDirection(playerDirection);
    playerDirection.y = 0;
    playerDirection.normalize();

    return playerDirection;
  },
  getSideVector() {
    camera.getWorldDirection(playerDirection);
    playerDirection.y = 0;
    playerDirection.normalize();
    playerDirection.cross(camera.up);

    return playerDirection;
  },
  keyboardControls(deltaTime: any) {
    // gives a bit of air control
    const speedDelta = deltaTime * 25;

    if (keyStates["KeyW"]) {
      playerVelocity.add(this.getForwardVector().multiplyScalar(speedDelta));
    }

    if (keyStates["KeyS"]) {
      playerVelocity.add(this.getForwardVector().multiplyScalar(-speedDelta));
    }

    if (keyStates["KeyA"]) {
      playerVelocity.add(this.getSideVector().multiplyScalar(-speedDelta));
    }

    if (keyStates["KeyD"]) {
      playerVelocity.add(this.getSideVector().multiplyScalar(speedDelta));
    }
  },
  playerCollisions() {
    const result = worldOctree.capsuleIntersect(playerCollider);

    if (result) {
      playerCollider.translate(result.normal.multiplyScalar(result.depth));
    }
  },
  animate() {
    if (!isOrbitMode) {
      const deltaTime = Math.min(0.05, clock.getDelta()) / stepPerFrame;
      for (let i = 0; i < stepPerFrame; i++) {
        ScenHelper.keyboardControls(deltaTime);
        ScenHelper.updatePlayer(deltaTime);
      }
    } else {
      if (controls) {
        controls.update();
      }
    }

    renderer.render(toRaw(scene), toRaw(camera));

    animationFrameId = requestAnimationFrame(ScenHelper.animate);
  },
  onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    const container = document.getElementById("sceneContainer");
    renderer.setSize(container?.offsetWidth ?? 0, container?.offsetHeight ?? 0);
    //renderer.setSize(window.innerWidth, window.innerHeight);
  },
  CreateProjectMenu(project: any) {
    const menuGroup = new THREE.Group();

    let projectMenuText = new TextGeometry("Add a new Position", {
      font: fontModel,
      size: 0.2,
      depth: 0.2,
    });

    let materials = [
      new THREE.MeshPhongMaterial({ color: 0xffffff, flatShading: true }), // front
      new THREE.MeshPhongMaterial({ color: 0xffffff }), // side
    ];

    let textProjectName = new THREE.Mesh(projectMenuText, materials);
    const box = new THREE.Box3();
    box.setFromObject(textProjectName);
    let measure = new THREE.Vector3();
    const geometry = new THREE.BoxGeometry(
      box.getSize(measure).x + 0.2,
      box.getSize(measure).y + 0.2,
      0.1
    );
    const material = new THREE.MeshBasicMaterial({ color: 0x000 });
    const cube = new THREE.Mesh(geometry, material);
    cube.geometry.center();
    textProjectName.geometry.center();
    cube.name = "MenuGroup";
    textProjectName.name = "MenuGroup";
    cube.userData = { Id: project.id };
    textProjectName.userData = { Id: project.id };
    menuGroup.add(cube);
    menuGroup.add(textProjectName);
    menuGroup.position.setY(3);
    menuGroup.position.setX(0);
    menuGroup.rotateY(Math.PI / 2);
    menuGroup.visible = false;
    menuGroup.name = "MenuGroup-" + project.id;
    menuGroup.userData = { Id: project.id };
    return menuGroup;
  },
  CreateMenuOfCommitmentTableMonitor(screenMesh: any, position: any) {
    const positionMenuGroup = new THREE.Group();

    let textBack = this.CreateCloseMenuButton(
      "CommitmentTable-Monitor-BackMenu",
      positionMenuGroup
    );

    let textActionButton1 = this.CreateMenuButton(
      "Button 1",
      "Button1",
      positionMenuGroup,
      "a"
    );
    let textActionButton2 = this.CreateMenuButton(
      "Button 2",
      "Button2",
      positionMenuGroup,
      "b"
    );
    let textActionButton3 = this.CreateMenuButton(
      "Button 3",
      "Button3",
      positionMenuGroup,
      "c"
    );
    let textActionButton4 = this.CreateMenuButton(
      "Button 4",
      "Button4",
      positionMenuGroup,
      "d"
    );
    let textActionButton5 = this.CreateMenuButton(
      "Button 5",
      "Button5",
      positionMenuGroup
    );
    let textActionButton6 = this.CreateMenuButton(
      "Button 6",
      "Button6",
      positionMenuGroup
    );
    let textActionButton7 = this.CreateMenuButton(
      "Button 7",
      "Button7",
      positionMenuGroup
    );
    let textActionButton8 = this.CreateMenuButton(
      "Button 8",
      "Button8",
      positionMenuGroup
    );
    let textActionButton9 = this.CreateMenuButton(
      "Button 9",
      "Button9",
      positionMenuGroup
    );
    let textActionButton10 = this.CreateMenuButton(
      "Button 10",
      "Button10",
      positionMenuGroup
    );
    let textActionButton11 = this.CreateMenuButton(
      "Button 11",
      "Button11",
      positionMenuGroup
    );
    let textActionButton12 = this.CreateMenuButton(
      "Button 12",
      "Button12",
      positionMenuGroup
    );

    let textInsightButton1 = this.CreateMenuButton(
      "Button 1",
      "Button1",
      positionMenuGroup,
      "e"
    );
    let textInsightButton2 = this.CreateMenuButton(
      "Button 2",
      "Button2",
      positionMenuGroup,
      "f"
    );
    let textInsightButton3 = this.CreateMenuButton(
      "Button 3",
      "Button3",
      positionMenuGroup,
      "a",
      new THREE.Color(0xff0000)
    );
    let textInsightButton4 = this.CreateMenuButton(
      "Button 4",
      "Button4",
      positionMenuGroup
    );
    let textInsightButton5 = this.CreateMenuButton(
      "Button 5",
      "Button5",
      positionMenuGroup
    );
    let textInsightButton6 = this.CreateMenuButton(
      "Button 6",
      "Button6",
      positionMenuGroup
    );
    let textInsightButton7 = this.CreateMenuButton(
      "Button 7",
      "Button7",
      positionMenuGroup
    );
    let textInsightButton8 = this.CreateMenuButton(
      "Button 8",
      "Button8",
      positionMenuGroup
    );
    let textInsightButton9 = this.CreateMenuButton(
      "Button 9",
      "Button9",
      positionMenuGroup
    );
    let textInsightButton10 = this.CreateMenuButton(
      "Button 10",
      "Button10",
      positionMenuGroup
    );
    let textInsightButton11 = this.CreateMenuButton(
      "Button 11",
      "Button11",
      positionMenuGroup
    );
    let textInsightButton12 = this.CreateMenuButton(
      "Button 12",
      "Button12",
      positionMenuGroup
    );

    let desktopBackground = new THREE.TextureLoader().load(
      "../../3dModels/commitmentBackground.jpg"
    );
    let desktopBackgroundMaterial = new THREE.MeshPhongMaterial({
      map: desktopBackground,
    });

    const box = new THREE.Box3();
    box.setFromObject(screenMesh);
    let measure = new THREE.Vector3();
    const geometry = new THREE.BoxGeometry(
      box.getSize(measure).z,
      box.getSize(measure).y,
      0.0001
    );

    const cube = new THREE.Mesh(geometry, desktopBackgroundMaterial);

    cube.geometry.center();

    textBack.position.y += 0.18;
    textBack.position.x += 0.35;
    // Action Btns
    textActionButton1.position.y += 0.1;
    textActionButton1.position.x -= 0.23;
    textActionButton2.position.y += 0.1;
    textActionButton2.position.x -= 0.09;

    textActionButton3.position.y += 0.07;
    textActionButton3.position.x -= 0.23;
    textActionButton4.position.y += 0.07;
    textActionButton4.position.x -= 0.09;

    textActionButton5.position.y += 0.04;
    textActionButton5.position.x -= 0.23;
    textActionButton6.position.y += 0.04;
    textActionButton6.position.x -= 0.09;

    textActionButton7.position.y += 0.01;
    textActionButton7.position.x -= 0.23;
    textActionButton8.position.y += 0.01;
    textActionButton8.position.x -= 0.09;

    textActionButton9.position.y -= 0.02;
    textActionButton9.position.x -= 0.23;
    textActionButton10.position.y -= 0.02;
    textActionButton10.position.x -= 0.09;

    textActionButton11.position.y -= 0.05;
    textActionButton11.position.x -= 0.23;
    textActionButton12.position.y -= 0.05;
    textActionButton12.position.x -= 0.09;

    // Insight Btns
    textInsightButton1.position.y += 0.1;
    textInsightButton1.position.x += 0.09;
    textInsightButton2.position.y += 0.1;
    textInsightButton2.position.x += 0.23;

    textInsightButton3.position.y += 0.07;
    textInsightButton3.position.x += 0.09;
    textInsightButton4.position.y += 0.07;
    textInsightButton4.position.x += 0.23;

    textInsightButton5.position.y += 0.04;
    textInsightButton5.position.x += 0.09;
    textInsightButton6.position.y += 0.04;
    textInsightButton6.position.x += 0.23;

    textInsightButton7.position.y += 0.01;
    textInsightButton7.position.x += 0.09;
    textInsightButton8.position.y += 0.01;
    textInsightButton8.position.x += 0.23;

    textInsightButton9.position.y -= 0.02;
    textInsightButton9.position.x += 0.09;
    textInsightButton10.position.y -= 0.02;
    textInsightButton10.position.x += 0.23;

    textInsightButton11.position.y -= 0.05;
    textInsightButton11.position.x += 0.09;
    textInsightButton12.position.y -= 0.05;
    textInsightButton12.position.x += 0.23;

    positionMenuGroup.add(cube);

    positionMenuGroup.add(textBack);

    positionMenuGroup.add(textActionButton1);
    positionMenuGroup.add(textActionButton2);
    positionMenuGroup.add(textActionButton3);
    positionMenuGroup.add(textActionButton4);
    positionMenuGroup.add(textActionButton5);
    positionMenuGroup.add(textActionButton6);
    positionMenuGroup.add(textActionButton7);
    positionMenuGroup.add(textActionButton8);
    positionMenuGroup.add(textActionButton9);
    positionMenuGroup.add(textActionButton10);
    positionMenuGroup.add(textActionButton11);
    positionMenuGroup.add(textActionButton12);

    positionMenuGroup.add(textInsightButton1);
    positionMenuGroup.add(textInsightButton2);
    positionMenuGroup.add(textInsightButton3);
    positionMenuGroup.add(textInsightButton4);
    positionMenuGroup.add(textInsightButton5);
    positionMenuGroup.add(textInsightButton6);
    positionMenuGroup.add(textInsightButton7);
    positionMenuGroup.add(textInsightButton8);
    positionMenuGroup.add(textInsightButton9);
    positionMenuGroup.add(textInsightButton10);
    positionMenuGroup.add(textInsightButton11);
    positionMenuGroup.add(textInsightButton12);

    return positionMenuGroup;
  },
  CreateMenuOfPositionTableMonitor(screenMesh: any, position: any) {
    const positionMenuGroup = new THREE.Group();

    let materials = [
      new THREE.MeshPhongMaterial({
        color: 0x000,
        flatShading: true,
      }), // front
      new THREE.MeshPhongMaterial({ color: 0x000 }), // side
    ];
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
    let imageMesh = new THREE.Mesh(
      new THREE.BoxGeometry(0.2, 0.2, 0.0001),
      material
    );
    let decalImage = new THREE.TextureLoader().load("../../code-clan-logo.png");
    let decalMaterial = new THREE.MeshPhongMaterial({
      map: decalImage,
      transparent: true,
      depthTest: true,
      depthWrite: false,
      polygonOffset: true,
      polygonOffsetFactor: -1,
      wireframe: false,
    });

    let decalGeometry = new DecalGeometry(
      imageMesh,
      imageMesh.position,
      new THREE.Euler(0, 0, 0),
      new THREE.Vector3(0.2, 0.2, 0.2)
    );
    let decal = new THREE.Mesh(decalGeometry, decalMaterial);
    let positionText = "";
    let positionBackground: any = null;
    switch (position.Status) {
      case "New":
        positionText = "Teammember Not assigned yet";
        positionBackground = 0xe0e0e0;
        break;
      case "Assigned":
        positionText = "Teammember Assigned";
        positionBackground = 0xd7f792;
        break;
    }
    let positionStatusText = new TextGeometry(positionText, {
      font: fontModel,
      size: 0.02,
      depth: 0.0002,
    });
    let textPositionStatus = new THREE.Mesh(positionStatusText, materials);

    const jobRoleText =
      details.find((item) => item.id == position.JobRoleId)?.Name ?? "";
    let textPositionRole = this.CreateMenuText(
      jobRoleText,
      "JobRole",
      positionMenuGroup,
      true
    );
    let textBack = this.CreateCloseMenuButton("BackMenu", positionMenuGroup);

    let textNumberOfPositions = this.CreateMenuText(
      "Number of Seats: " + position.NoOfPositions,
      "PositionStatus",
      positionMenuGroup
    );
    let textSeatUnfilled = this.CreateMenuText(
      "Seats Unfilled: " +
        (position.NoOfPositions - position.Commitments?.length).toString(),
      "PositionStatus",
      positionMenuGroup
    );

    const jobTitles = details
      .filter((item) => position.JobTitles.includes(item.id))
      .map((item) => item.Name);
    let textJobTitles = this.CreateMenuText(
      "JobTitles: " + (jobTitles.length > 0 ? jobTitles.join(", ") : "-"),
      "JobTitle",
      positionMenuGroup
    );
    let textStartDate = this.CreateMenuText(
      "Start Date: " +
        (position.StartDate
          ? moment(position.StartDate).format("YYYY-MM-DD")
          : "ASAP"),
      "StartDate",
      positionMenuGroup
    );

    const skillList = skills
      .filter((item) => position.Skills.includes(item.id))
      .map((item) => item.Text);
    let textSkills = this.CreateMenuText(
      "Skills: " + (skillList.length > 0 ? skillList.join(", ") : "-"),
      "Skills",
      positionMenuGroup
    );

    let textUpdatePostionDetails = this.CreateEditMenuButton(
      "UpdatePositionData",
      positionMenuGroup
    );

    let textAssignTeamMember = this.CreateMenuButton(
      "Select Team Member",
      "AssignTeammember",
      positionMenuGroup
    );
    let textClone = this.CreateMenuButton("Clone", "clone", positionMenuGroup);
    let textArchive = this.CreateMenuButton(
      "Archive",
      "archive",
      positionMenuGroup
    );

    let textStatus = this.CreateMenuText(
      "Status: " + position.Status,
      "PositionStatus",
      positionMenuGroup,
      true
    );
    let textImpressions = this.CreateMenuButton(
      "174 Impressions",
      "Impressions",
      positionMenuGroup
    );
    let textAssessments = this.CreateMenuButton(
      "57 Assessments",
      "Assessments",
      positionMenuGroup
    );
    let textInterviews = this.CreateMenuButton(
      "23 Interviews",
      "Interviews",
      positionMenuGroup
    );
    let textShortListed = this.CreateMenuButton(
      "5 Shortlisted",
      "Shortlisted",
      positionMenuGroup
    );

    let desktopBackground = new THREE.TextureLoader().load(
      "../../3dModels/background.jpg"
    );
    let desktopBackgroundMaterial = new THREE.MeshPhongMaterial({
      map: desktopBackground,
    });

    const box = new THREE.Box3();
    box.setFromObject(screenMesh);
    let measure = new THREE.Vector3();
    const geometry = new THREE.BoxGeometry(
      box.getSize(measure).z,
      box.getSize(measure).y,
      0.0001
    );

    const cube = new THREE.Mesh(geometry, desktopBackgroundMaterial);
    let positionStatusGroup = new THREE.Group();
    const positionStatusMaterial = new THREE.MeshBasicMaterial({
      color: positionBackground,
    });
    const positionStatusCube = new THREE.Mesh(geometry, positionStatusMaterial);

    positionStatusCube.geometry.center();

    cube.geometry.center();

    textPositionStatus.geometry.center();

    textPositionRole.position.y += 0.18;
    textBack.position.y += 0.18;
    textBack.position.x += 0.35;

    textJobTitles.position.y += 0.13;
    textJobTitles.position.x -= 0.29;
    textJobTitles.position.z -= 0.0001;

    textSkills.position.y += 0.11;
    textSkills.position.x -= 0.29;
    textSkills.position.z -= 0.0001;

    textNumberOfPositions.position.y += 0.09;
    textNumberOfPositions.position.x -= 0.29;
    textNumberOfPositions.position.z -= 0.0001;

    textStartDate.position.y += 0.09;
    textStartDate.position.x -= 0.12;
    textStartDate.position.z -= 0.0001;

    textSeatUnfilled.position.y += 0.09;
    textSeatUnfilled.position.x += 0.05;
    textSeatUnfilled.position.z -= 0.0001;

    textUpdatePostionDetails.position.y += 0.11;
    textUpdatePostionDetails.position.x += 0.29;

    textAssignTeamMember.position.y += 0.02;
    textAssignTeamMember.position.x -= 0.16;
    textClone.position.y -= 0.01;
    textClone.position.x -= 0.16;
    textArchive.position.y -= 0.04;
    textArchive.position.x -= 0.16;

    textStatus.position.y += 0.044;
    textStatus.position.x += 0.16;
    textImpressions.position.y += 0.02;
    textImpressions.position.x += 0.09;
    textAssessments.position.y += 0.02;
    textAssessments.position.x += 0.23;
    textInterviews.position.y -= 0.01;
    textInterviews.position.x += 0.09;
    textShortListed.position.y -= 0.01;
    textShortListed.position.x += 0.23;

    positionMenuGroup.add(cube);
    positionMenuGroup.add(decal);

    positionMenuGroup.add(textJobTitles);
    positionMenuGroup.add(textStartDate);

    positionMenuGroup.add(textSkills);

    positionMenuGroup.add(textNumberOfPositions);
    positionMenuGroup.add(textSeatUnfilled);
    positionMenuGroup.add(textUpdatePostionDetails);

    positionMenuGroup.add(textStatus);
    positionMenuGroup.add(textImpressions);
    positionMenuGroup.add(textAssessments);
    positionMenuGroup.add(textInterviews);
    positionMenuGroup.add(textShortListed);

    positionMenuGroup.add(textPositionRole);
    positionMenuGroup.add(textAssignTeamMember);

    positionMenuGroup.add(textClone);
    positionMenuGroup.add(textArchive);
    positionMenuGroup.add(textBack);

    decal.position.y += 0.08;
    textPositionStatus.position.y -= 0.08;
    decal.name = "PositionTable-Screen";
    textPositionStatus.name = "PositionTable-Screen";
    positionStatusCube.name = "PositionTable-Screen";
    positionStatusGroup.add(positionStatusCube);
    positionStatusGroup.add(textPositionStatus);
    positionStatusGroup.add(decal);
    positionStatusGroup.position.z += 0.0002;
    positionStatusGroup.name = "PositionStatusGroup";

    positionMenuGroup.add(positionStatusGroup);
    return positionMenuGroup;
  },
  CreateBackgroundPositionTableMonitor(screenMesh: any, position: any) {
    const backgroundGroup = new THREE.Group();
    let desktopBackground = new THREE.TextureLoader().load(
      "../../3dModels/screenBackground.jpg"
    );
    let desktopBackgroundMaterial = new THREE.MeshPhongMaterial({
      map: desktopBackground,
    });

    const box = new THREE.Box3();
    box.setFromObject(screenMesh);
    let measure = new THREE.Vector3();
    const geometry = new THREE.BoxGeometry(
      box.getSize(measure).z,
      box.getSize(measure).y,
      0.0001
    );
    const cube = new THREE.Mesh(geometry, desktopBackgroundMaterial);
    cube.name = "PositionTable-Screen";
    backgroundGroup.name = "PositionTable-Screen";
    backgroundGroup.add(cube);
    return backgroundGroup;
  },
  AddPostionTableToProject(projectPosition: any, projectId: string) {
    const projectGroup = scene.children.find(
      (item) => item.userData.ProjectGroupId == projectId
    );
    const projectData = projectsList.find((item) => item.id == projectId);
    if (projectData) {
      const tableObjects = projectGroup?.children.filter((child: any) =>
        Object.hasOwn(child.userData, "PositionId")
      );
      let objectIndex = tableObjects?.length ?? 0;
      let extraIndexes = 0;
      const positionIndex = projectData.Positions.findIndex(
        (item) => item.Id == projectPosition.Id
      );
      projectData.Positions.forEach((item, index) => {
        if (
          index < positionIndex &&
          item.NoOfPositions &&
          item.NoOfPositions % 2 == 0
        ) {
          extraIndexes++;
        }
      });
      objectIndex += extraIndexes;
      if (objectIndex % 2 == 1) {
        objectIndex++;
      }
      if (projectGroup) {
        const box = new THREE.Box3();
        box.setFromObject(projectGroup);
        const newTable = tableObject.clone();
        let screen = newTable.getObjectByName("ScreenMonitor2");
        let screenMonitor1 = newTable.getObjectByName("ScreenMonitor1");
        if (screenMonitor1) {
          let newScreen1 = this.CreateBackgroundPositionTableMonitor(
            screenMonitor1,
            projectPosition
          );
          newScreen1.position.copy(
            screenMonitor1.getWorldPosition(new THREE.Vector3())
          );
          newScreen1.rotation.copy(screenMonitor1.rotation);
          newScreen1.rotateX(Math.PI / 2);
          newScreen1.rotateY(Math.PI);
          newScreen1.parent = newTable;
          newTable.children.splice(
            newTable.children.findIndex(
              (item) => item.name == "ScreenMonitor1"
            ),
            1
          );
          newTable.children.push(newScreen1);
        }
        if (screen) {
          let newScreen = this.CreateMenuOfPositionTableMonitor(
            screen,
            projectPosition
          );
          newScreen.position.copy(screen.getWorldPosition(new THREE.Vector3()));
          newScreen.rotation.copy(screen.rotation);
          newScreen.rotateX(Math.PI / 2);
          newScreen.rotateY(Math.PI);
          newScreen.parent = newTable;
          newScreen.name = `PositionMonitor-${projectPosition.Id}`;
          newTable.children.splice(
            newTable.children.findIndex(
              (item) => item.name == "ScreenMonitor2"
            ),
            1
          );
          newTable.children.push(newScreen);
        }
        let zPosition = 1.81;
        if (objectIndex != 0 && objectIndex != 1)
          zPosition = zPosition + Math.floor(objectIndex / 2) * 2.3;
        newTable.position.z = zPosition;

        if (objectIndex % 2 == 1) {
          newTable.rotateY(Math.PI);
          newTable.position.x = box.min.y - 0.5;
        } else {
          newTable.position.x = box.min.y + 0.5;
        }
        newTable.parent = projectGroup;
        newTable.traverse((child: any) => {
          child.userData = {
            ProjectId: projectData.id,
            PositionId: projectPosition.Id,
            PositionData: newTable.position,
            PositionIndex: objectIndex,
          };
        });
        newTable.userData = {
          ProjectId: projectData.id,
          PositionId: projectPosition.Id,
          PositionData: newTable.position,
          PositionIndex: objectIndex,
          IsPosition: true,
        };
        newTable.name = `PositionTable-${projectPosition.Id}`;
        projectGroup?.children.push(newTable);

        mesh.push(newTable);

        if (projectPosition.NoOfPositions > 0) {
          for (let i = 0; i < projectPosition.NoOfPositions; i++) {
            this.AddNoneCommitmentTableToProject(
              projectPosition,
              projectId,
              i,
              objectIndex + 1
            );
          }
          projectPosition.Commitments.forEach((commitment: any) => {
            this.AddCommitmentTableToProject(
              projectPosition,
              projectId,
              commitment
            );
          });
        }
      }
    }
  },
  UpdatePositionMonitor(positionModel: PositionModel) {
    const monitorObject = scene.getObjectByName(
      `PositionMonitor-${positionModel.Id}`
    );
    if (monitorObject) {
      let newScreen = this.CreateMenuOfPositionTableMonitor(
        monitorObject,
        positionModel
      );
      newScreen.position.copy(monitorObject.position);
      newScreen.rotation.copy(monitorObject.rotation);
      newScreen.parent = monitorObject.parent;
      newScreen.name = `PositionMonitor-${positionModel.Id}`;
      const positionStatusGroup = newScreen.getObjectByName(
        "PositionStatusGroup"
      );
      if (positionStatusGroup) {
        positionStatusGroup.position.z -= 0.0005;
      }
      newScreen.traverse((child: any) => {
        child.userData = monitorObject.userData;
      });
      monitorObject.parent?.children.splice(
        monitorObject.parent?.children.findIndex(
          (item) => item.name == `PositionMonitor-${positionModel.Id}`
        ),
        1
      );
      monitorObject.parent?.children.push(newScreen);
    }
  },
  UpdateProjectTablesPosition(positionModel: PositionModel) {
    const projectGroup = scene.children.find(
      (item) => item.userData.ProjectGroupId == positionModel.ProjectId
    );
    const projectData = projectsList.find(
      (item) => item.id == positionModel.ProjectId
    );
    if (projectData) {
      const positionTableIndex = projectGroup?.children.find(
        (item) =>
          item.userData.PositionId == positionModel.Id &&
          Object.hasOwn(item.userData, "IsPosition")
      )?.userData.PositionIndex;
      const tableObjects = projectGroup?.children.filter(
        (child: any) => child.userData.PositionIndex > positionTableIndex
      );
      tableObjects?.forEach((child) =>
        projectGroup?.children.splice(
          projectGroup?.children.findIndex((item) => item.uuid == child.uuid),
          1
        )
      );

      if (positionModel.NoOfPositions && positionModel.NoOfPositions > 0) {
        const monitorObject = scene.getObjectByName(
          `PositionMonitor-${positionModel.Id}`
        );
        for (let i = 0; i < positionModel.NoOfPositions; i++) {
          this.AddNoneCommitmentTableToProject(
            positionModel,
            positionModel.ProjectId ?? "",
            i,
            monitorObject?.userData.PositionIndex + 1
          );
        }
        if (positionModel.Commitments) {
          positionModel.Commitments.forEach((commitment: any) => {
            this.AddCommitmentTableToProject(
              positionModel,
              positionModel.ProjectId ?? "",
              commitment
            );
          });
        }
      }
      const positionIndexInProject = projectData.Positions.findIndex(
        (item) => item.Id == positionModel.Id
      );
      projectData.Positions.forEach((item) => {
        const pIndex = projectData.Positions.findIndex((i) => i.Id == item.Id);
        if (pIndex > positionIndexInProject) {
          this.AddPostionTableToProject(item, positionModel.ProjectId ?? "");
        }
      });
    }
  },
  AddNoneCommitmentTableToProject(
    projectPosition: any,
    projectId: string,
    positionNumber: number,
    fromIndex: number
  ) {
    const projectGroup = scene.children.find(
      (item) => item.userData.ProjectGroupId == projectId
    );
    const projectData = projectsList.find((item) => item.id == projectId);
    if (projectData) {
      const objectIndex = fromIndex + positionNumber;
      if (projectGroup) {
        const box = new THREE.Box3();
        box.setFromObject(projectGroup);
        const newTable = tableObject.clone();
        let screenMonitor1 = newTable.getObjectByName("ScreenMonitor1");
        let screenMonitor2 = newTable.getObjectByName("ScreenMonitor2");
        if (screenMonitor1) {
          let newScreen1 = this.CreateBackgroundPositionTableMonitor(
            screenMonitor1,
            projectPosition
          );
          newScreen1.position.copy(
            screenMonitor1.getWorldPosition(new THREE.Vector3())
          );
          newScreen1.rotation.copy(screenMonitor1.rotation);
          newScreen1.rotateX(Math.PI / 2);
          newScreen1.rotateY(Math.PI);
          newScreen1.parent = newTable;
          newTable.children.splice(
            newTable.children.findIndex(
              (item) => item.name == "ScreenMonitor1"
            ),
            1
          );
          newTable.children.push(newScreen1);
        }
        if (screenMonitor2) {
          let newScreen2 = this.CreateBackgroundPositionTableMonitor(
            screenMonitor2,
            projectPosition
          );
          newScreen2.position.copy(
            screenMonitor2.getWorldPosition(new THREE.Vector3())
          );
          newScreen2.rotation.copy(screenMonitor2.rotation);
          newScreen2.rotateX(Math.PI / 2);
          newScreen2.rotateY(Math.PI);
          newScreen2.parent = newTable;
          newScreen2.name = "NoneCommitmentMonitor";
          newTable.children.splice(
            newTable.children.findIndex(
              (item) => item.name == "ScreenMonitor2"
            ),
            1
          );
          newTable.children.push(newScreen2);
        }
        newTable.traverse((child: any) => {
          if (child.name != "PositionTable-Desk") {
            child.visible = false;
          }
        });

        newTable.children[0].visible = true;
        newTable.children[0].children[0].visible = true;
        newTable.visible = true;
        let zPosition = 1.86;
        if (objectIndex != 0 && objectIndex != 1)
          zPosition = zPosition + Math.floor(objectIndex / 2) * 2.3;
        newTable.position.z = zPosition;
        if (objectIndex % 2 == 1) {
          newTable.rotateY(Math.PI);
          newTable.position.x = box.min.y - 0.5;
        } else {
          newTable.position.x = box.min.y + 0.5;
          newTable.position.z -= 0.05;
        }
        newTable.parent = projectGroup;
        newTable.traverse((child: any) => {
          child.userData = {
            ProjectId: projectData.id,
            PositionId: projectPosition.Id,
            PositionData: newTable.position,
            PositionIndex: objectIndex,
          };
        });
        newTable.userData = {
          ProjectId: projectData.id,
          PositionId: projectPosition.Id,
          PositionData: newTable.position,
          PositionIndex: objectIndex,
          IsCommitment: true,
        };
        newTable.name = `PositionTable-${projectPosition.Id}`;
        projectGroup?.children.push(newTable);

        mesh.push(newTable);
      }
    }
  },
  AddCommitmentTableToProject(
    projectPosition: any,
    projectId: string,
    commitment: any
  ) {
    const projectGroup = scene.children.find(
      (item) => item.userData.ProjectGroupId == projectId
    );
    const firstFreeTable = projectGroup?.children.find(
      (item) =>
        item.userData.ProjectId == projectId &&
        item.userData.PositionId == projectPosition.Id &&
        item.userData.IsCommitment == true &&
        !Object.hasOwn(item.userData, "CommitmentId")
    );

    if (firstFreeTable) {
      firstFreeTable.name = `CommitmentTable-${commitment.id}`;
      const monitor = firstFreeTable.getObjectByName("NoneCommitmentMonitor");
      if (monitor) {
        let newScreen = this.CreateMenuOfCommitmentTableMonitor(
          monitor,
          projectPosition
        );
        newScreen.position.copy(monitor.position);
        newScreen.rotation.copy(monitor.rotation);
        newScreen.parent = firstFreeTable;
        newScreen.name = `CommitmentTableMonitor-${commitment.id}`;
        newScreen.userData = monitor.userData;
        firstFreeTable.children.splice(
          firstFreeTable.children.findIndex(
            (item) => item.name == "ScreenMonitor2"
          ),
          1
        );
        firstFreeTable.traverse((child: any) => {
          child.visible = true;
          if (child.name != `CommitmentTableMonitor-${commitment.id}`) {
            child.name = `CommitmentTable-${commitment.id}`;
          }
          child.userData["CommitmentId"] = commitment.id;
        });
        firstFreeTable.children.push(newScreen);
      }
      firstFreeTable.userData["CommitmentId"] = commitment.id;
      firstFreeTable.name = `PositionTable-${commitment.id}`;

      this.AddChairToPositionTable(commitment.id);
    }
  },
  AddChairToPositionTable(commitmentId: string) {
    let tableObject = scene.getObjectByName(`PositionTable-${commitmentId}`);
    if (tableObject) {
      const newChair = chairObject.clone();
      newChair.parent = tableObject;

      newChair.position.x = tableObject.userData.PositionData.x + 0.5;
      newChair.position.y = 0;
      newChair.position.z = 0;
      if (tableObject.userData.PositionIndex % 2 == 1) {
        newChair.position.x = tableObject.userData.PositionData.x + 1.5;
      }
      tableObject.children.push(newChair);

      mesh.push(newChair);
    }
  },
  AddProjectToScene(project: any, index: any) {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(scene);

    const tableGroup = new THREE.Group();
    tableGroup.position.setZ(
      boundingBox.min.z + (Math.floor(index / 5) + 1) * 15
    );
    tableGroup.position.setX(boundingBox.min.x + ((index % 5) + 1) * 10);

    const newTable = infoTableObject.clone();
    newTable.userData = project;
    let screen = newTable?.children.find(
      (item) => item.name == "monitorScreen"
    );

    if (screen) {
      let newScreen = this.AddProjectNameToInfoTableMonitor(project, screen);
      newScreen.position.copy(screen.position);
      newScreen.rotation.copy(screen.rotation);
      newScreen.rotateX(Math.PI / 2);
      newScreen.rotateY(Math.PI);
      newScreen.parent = newTable;
      newTable?.children.pop();
      newTable?.children.push(newScreen);
    }

    let plaqu = this.AddProjectPlaqueToInfoTable(project);
    let menu = this.CreateProjectMenu(project);
    tableGroup.add(newTable);
    tableGroup.add(plaqu);
    tableGroup.name = "ProjectTable";
    tableGroup.userData = { ProjectGroupId: project.id };
    tableGroup.traverse(
      (child) => (child.userData = { ProjectGroupId: project.id })
    );
    tableGroup.add(menu);
    scene.add(tableGroup);
    mesh.push(tableGroup);

    if (project.Positions) {
      project.Positions.forEach((position: any) => {
        this.AddPostionTableToProject(position, project.id);
      });
    }
  },
  AddProjectPlaqueToInfoTable(project: any) {
    const plaqueGroup = new THREE.Group();

    let projectNameText = new TextGeometry(project.Name, {
      font: fontModel,
      size: 0.2,
      depth: 0.2,
    });

    let materials = [
      new THREE.MeshPhongMaterial({ color: 0x000, flatShading: true }), // front
      new THREE.MeshPhongMaterial({ color: 0x000 }), // side
    ];

    let textProjectName = new THREE.Mesh(projectNameText, materials);
    const box = new THREE.Box3();
    box.setFromObject(textProjectName);
    let measure = new THREE.Vector3();
    const geometry = new THREE.BoxGeometry(
      box.getSize(measure).x + 0.2,
      box.getSize(measure).y + 0.2,
      0.1
    );
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
    const cube = new THREE.Mesh(geometry, material);
    cube.geometry.center();
    textProjectName.geometry.center();
    cube.name = "ProjectTable";
    textProjectName.name = "ProjectTable";
    plaqueGroup.add(cube);
    plaqueGroup.add(textProjectName);
    plaqueGroup.position.setY(3.5);
    plaqueGroup.position.setX(0);
    plaqueGroup.rotateY(Math.PI / 2);
    plaqueGroup.name = "ProjectTable";
    return plaqueGroup;
  },
  CreateCloseMenuButton(name: string, parent: any) {
    const menu = new THREE.Group();

    let buttonBackground = new THREE.TextureLoader().load(
      "../../3dModels/close-icon.png"
    );
    let buttonBackgroundMaterial = new THREE.MeshPhongMaterial({
      map: buttonBackground,
      transparent: true,
    });

    const geometry = new THREE.BoxGeometry(0.03, 0.03, 0.0002);

    const menuBackground = new THREE.Mesh(geometry, buttonBackgroundMaterial);
    menuBackground.geometry.center();
    menuBackground.name = name;
    menuBackground.parent = menu;
    menu.add(menuBackground);
    menu.name = name;
    menu.parent = parent;
    return menu;
  },
  CreateEditMenuButton(name: string, parent: any) {
    const menu = new THREE.Group();

    let buttonBackground = new THREE.TextureLoader().load(
      "../../3dModels/edit-icon.png"
    );
    let buttonBackgroundMaterial = new THREE.MeshPhongMaterial({
      map: buttonBackground,
      transparent: true,
    });

    const geometry = new THREE.BoxGeometry(0.03, 0.03, 0.0002);

    const menuBackground = new THREE.Mesh(geometry, buttonBackgroundMaterial);
    menuBackground.geometry.center();
    menuBackground.name = name;
    menuBackground.parent = menu;
    menu.add(menuBackground);
    menu.name = name;
    menu.parent = parent;
    return menu;
  },
  CreateMenuButton(
    text: string,
    name: string,
    parent: any,
    icon: null | string = null,
    color: null | THREE.Color = null
  ) {
    const menu = new THREE.Group();
    let materials = [
      new THREE.MeshPhongMaterial({ color: 0xffffff, flatShading: true }), // front
      new THREE.MeshPhongMaterial({ color: 0xffffff }), // side
    ];
    let textObj = new THREE.Mesh();
    const textModel = new TextGeometry(text, {
      font: fontModel,
      size: 0.008,
      depth: 0.0003,
    });
    if (icon != null) {
      const iconG = new TextGeometry(icon, {
        font: iconFontModel,
        size: 0.008,
        depth: 0.0003,
      });
      iconG.translate(-0.017, 0, 0);
      textModel.translate(0, 0, 0);
      const mergedGeometry = mergeGeometries([textModel, iconG], true);
      textObj = new THREE.Mesh(mergedGeometry, materials);
    } else {
      textObj = new THREE.Mesh(textModel, materials);
    }

    let buttonBackground = new THREE.TextureLoader().load(
      "../../3dModels/button-background.png"
    );
    let buttonBackgroundMaterial = new THREE.MeshPhongMaterial({
      map: buttonBackground,
      transparent: true,
    });
    if (color != null) {
      buttonBackgroundMaterial.color = color;
    }
    const geometry = new THREE.BoxGeometry(0.12, 0.025, 0.0002);

    const menuBackground = new THREE.Mesh(geometry, buttonBackgroundMaterial);
    menuBackground.geometry.center();
    textObj.geometry.center();
    menuBackground.name = name;
    textObj.name = name;
    menuBackground.parent = menu;
    textObj.parent = menu;
    menu.add(menuBackground);
    menu.add(textObj);
    menu.name = name;
    menu.parent = parent;
    return menu;
  },
  CreateMenuText(
    text: string,
    name: string,
    parent: any,
    isCentre: boolean = false
  ) {
    const menu = new THREE.Group();

    let textModel = new TextGeometry(text, {
      font: fontModel,
      size: 0.008,
      depth: 0.0003,
    });

    let materials = [
      new THREE.MeshPhongMaterial({ color: 0x000, flatShading: true }), // front
      new THREE.MeshPhongMaterial({ color: 0x000 }), // side
    ];
    let textObj = new THREE.Mesh(textModel, materials);
    if (isCentre) {
      textObj.geometry.center();
    }
    textObj.name = name;
    textObj.parent = menu;
    menu.add(textObj);
    menu.name = name;
    menu.parent = parent;
    return menu;
  },
  AddProjectNameToInfoTableMonitor(project: any, screenMesh: any) {
    const plaqueGroup = new THREE.Group();

    let projectNameText = new TextGeometry(project.Name, {
      font: fontModel,
      size: 0.05,
      depth: 0.0002,
    });

    let materials = [
      new THREE.MeshPhongMaterial({ color: 0x000, flatShading: true }), // front
      new THREE.MeshPhongMaterial({ color: 0x000 }), // side
    ];

    let textProjectName = new THREE.Mesh(projectNameText, materials);

    const box = new THREE.Box3();
    box.setFromObject(screenMesh);
    let measure = new THREE.Vector3();
    const geometry = new THREE.BoxGeometry(
      box.getSize(measure).z,
      box.getSize(measure).y,
      0.0001
    );
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
    const cube = new THREE.Mesh(geometry, material);
    cube.geometry.center();
    textProjectName.geometry.center();
    cube.name = "ProjectTable";
    textProjectName.name = "ProjectTable";
    plaqueGroup.add(cube);
    plaqueGroup.add(textProjectName);
    plaqueGroup.name = "ProjectTable";

    return plaqueGroup;
  },
  DisposeScene() {
    if (scene && renderer) {
      scene.traverse((node) => {
        if (node instanceof THREE.Mesh) {
          //(renderer as any).deallocateObject(node);
          if (node.geometry) {
            node.geometry.dispose();
          }
        }
      });
      (renderer as any).dispose();
    }
  },
};

export default ScenHelper;
