
export default class Utils {

  public static addTimezoneOffsetToStartDate(date: Date | null): Date | null {
    if (typeof date === "string") {
      date = this.vsDateToDatetime(date);
    }
    if (!date) {
      return date;
    }
    date = this.setDateTimes(date, 0, 0, 0, 0);
    return this.addTimezoneOffset(date);
  }

  public static addTimezoneOffsetToEndDate(date: Date | null): Date | null {
    if (typeof date === "string") {
      date = this.vsDateToDatetime(date);
    }
    if (!date) {
      return date;
    }
    date = this.setDateTimes(date, 23, 59, 59, 999);
    return this.addTimezoneOffset(date);
  }

  public static addTimezoneOffset(date: any): Date {
    if (typeof date === "string") {
      date = this.vsDateToDatetime(date);
    }
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
  }
  public static setDateTimes(date: Date | null, hour: number, minute: number, second: number, millisecond: number) {
    if (date != null) {
      date.setHours(hour);
      date.setMinutes(minute);
      date.setSeconds(second);
      date.setMilliseconds(millisecond);
    }
    return date;
  }

  private static isTimstamp(date: any) {
    return date.seconds ? true : false;
  }
  
  public static timestampToDate(tms: any): Date {
    if (tms instanceof Date) {
      return tms;
    } else {
      return new Date((tms._seconds || tms.seconds) * 1000);
    }
  }

  public static toVsDateFormat(date: Date, disableDay = false): string | null {
    try {
      if (typeof date === "undefined" || date == null) {
        return null;
      }
      if (typeof date === "string") {
        date = this.vsDateToDatetime(date);
      }
      if (this.isTimstamp(date)) {
        date = this.timestampToDate(date);
      }

      const y = date.getFullYear().toString();
      let m = (date.getMonth() + 1).toString();
      let d = date.getDate().toString();

      if (m.length < 2) {
        m = "0" + m;
      }
      if (d.length < 2) {
        d = "0" + d;
      }
      if (disableDay) {
        return [y, m].join("-");
      } else {
        return [y, m, d].join("-");
      }
    } catch (error) {
      return "";
    }
  }

  public static vsDateToDatetime(date: string): Date {
    if (typeof date === "string") {
      const parts = date.split("-").map((p) => parseInt(p));
      return new Date(Date.UTC(parts[0], parts[1] - 1, parts[2], 0, 0, 0, 0));
    } else {
      const dateTimeType = date as Date;
      return dateTimeType;
    }
  }

}
