import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list-item" }
const _hoisted_2 = { class: "selectCourses-container" }
const _hoisted_3 = { class: "selectCourses-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_CourseList = _resolveComponent("CourseList")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_AssessmentList = _resolveComponent("AssessmentList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_btn, { class: "primary_btn" }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Start ")
        ])),
        _: 1
      }),
      (_ctx.steps && _ctx.steps.length > 0)
        ? (_openBlock(), _createBlock(_component_draggable, {
            key: 0,
            list: _ctx.steps,
            disabled: !_ctx.enabled,
            "item-key": "name",
            class: "list-group",
            "ghost-class": "ghost",
            onStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dragging = true)),
            onEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dragging = false))
          }, {
            item: _withCtx(({ element }) => [
              _createVNode(_component_v_btn, { class: "list-buttons" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    onClick: ($event: any) => (_ctx.deleteButton(element.Id))
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("mdi-close")
                    ])),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createTextVNode(" " + _toDisplayString(element.Title), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["list", "disabled"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_menu, {
        "offset-y": "",
        transition: "scale-transition"
      }, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_btn, _mergeProps({ class: "secondary_btn" }, props), {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" Add ")
            ])),
            _: 2
          }, 1040)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, { link: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { onClick: _ctx.showSelectCourse }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { left: "" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("mdi-plus")
                        ])),
                        _: 1
                      }),
                      _cache[10] || (_cache[10] = _createTextVNode(" Add a Course "))
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, { link: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { onClick: _ctx.showSelectAssessment }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { left: "" }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("mdi-plus")
                        ])),
                        _: 1
                      }),
                      _cache[12] || (_cache[12] = _createTextVNode(" Add a Assessment "))
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, { class: "primary_btn" }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [
          _createTextVNode(" finish ")
        ])),
        _: 1
      })
    ]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showCoursePopup,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showCoursePopup) = $event)),
      fullscreen: "",
      scrim: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_overlay, {
              class: "filter-overlay",
              modelValue: _ctx.overlay,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.overlay) = $event)),
              contained: "",
              "scroll-strategy": "block",
              "location-strategy": "connected",
              opacity: "0.8",
              persistent: "",
              onClick: _ctx.clickOnOverlay
            }, null, 8, ["modelValue", "onClick"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_CourseList, {
                ref: "selectCoursesRef",
                backButtonVisibility: true,
                showAddToRoadMap: true,
                onAddCourseToRoadMap: _ctx.addStepToRoadMap,
                onCloseCoursesList: _ctx.closeCoursesList,
                onShowOverlay: _ctx.showOverlay,
                stepIds: _ctx.getStepIds
              }, null, 8, ["onAddCourseToRoadMap", "onCloseCoursesList", "onShowOverlay", "stepIds"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showAssessmentPopup,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showAssessmentPopup) = $event)),
      fullscreen: "",
      scrim: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_overlay, {
              class: "filter-overlay",
              modelValue: _ctx.overlay,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.overlay) = $event)),
              contained: "",
              "scroll-strategy": "block",
              "location-strategy": "connected",
              opacity: "0.8",
              persistent: "",
              onClick: _ctx.clickOnOverlay
            }, null, 8, ["modelValue", "onClick"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AssessmentList, {
                ref: "selectCoursesRef",
                backButtonVisibility: true,
                showAddToRoadMap: true,
                onAddAssessmentToRoadMap: _ctx.addStepToRoadMap,
                onCloseAssessmentsList: _ctx.closeSelectAssessment,
                onShowOverlay: _ctx.showOverlay,
                stepIds: _ctx.getStepIds
              }, null, 8, ["onAddAssessmentToRoadMap", "onCloseAssessmentsList", "onShowOverlay", "stepIds"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}