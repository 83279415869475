<template>
  <div>
    <div class="list-item">
      <v-btn class="primary_btn"> Start </v-btn>
      <draggable
        :list="steps"
        :disabled="!enabled"
        item-key="name"
        class="list-group"
        ghost-class="ghost"
        @start="dragging = true"
        @end="dragging = false"
        v-if="steps && steps.length > 0"
      >
        <template #item="{ element }">
          <v-btn class="list-buttons">
            <v-icon @click="deleteButton(element.Id)">mdi-close</v-icon>
            {{ element.Title }}
          </v-btn>
        </template>
      </draggable>
      <v-menu offset-y transition="scale-transition">
        <template v-slot:activator="{ props }">
          <v-btn class="secondary_btn" v-bind="props"> Add </v-btn>
        </template>

        <v-list>
          <v-list-item link>
            <v-list-item-title @click="showSelectCourse">
              <v-icon left>mdi-plus</v-icon>
              Add a Course
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="showSelectAssessment">
              <v-icon left>mdi-plus</v-icon>
              Add a Assessment
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn class="primary_btn"> finish </v-btn>
    </div>
    <v-dialog v-model="showCoursePopup" fullscreen :scrim="false">
      <v-card>
        <v-overlay
          class="filter-overlay"
          v-model="overlay"
          contained
          scroll-strategy="block"
          location-strategy="connected"
          opacity="0.8"
          persistent
          @click="clickOnOverlay"
        >
        </v-overlay>
        <div class="selectCourses-container">
          <CourseList
            ref="selectCoursesRef"
            :backButtonVisibility="true"
            :showAddToRoadMap="true"
            @AddCourseToRoadMap="addStepToRoadMap"
            @CloseCoursesList="closeCoursesList"
            @showOverlay="showOverlay"
            :stepIds="getStepIds"
          ></CourseList>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAssessmentPopup" fullscreen :scrim="false">
      <v-card>
        <v-overlay
          class="filter-overlay"
          v-model="overlay"
          contained
          scroll-strategy="block"
          location-strategy="connected"
          opacity="0.8"
          persistent
          @click="clickOnOverlay"
        >
        </v-overlay>
        <div class="selectCourses-container">
          <AssessmentList
            ref="selectCoursesRef"
            :backButtonVisibility="true"
            :showAddToRoadMap="true"
            @AddAssessmentToRoadMap="addStepToRoadMap"
            @CloseAssessmentsList="closeSelectAssessment"
            @showOverlay="showOverlay"
            :stepIds="getStepIds"
          ></AssessmentList>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import draggable from "vuedraggable";
import CourseList from "../Course/CourseList.vue";
import AssessmentList from "../Assessment/AssessmentList.vue";
import { RoadMapStepModel } from "shared-components/src/models/RoadMapModel";

export default defineComponent({
  components: {
    draggable,
    CourseList,
    AssessmentList,
  },
  props: {
    steps: {
      type: Array as () => RoadMapStepModel[],
    },
  },
  data() {
    return {
      enabled: true,
      id: 1,
      dragging: false,
      showCoursePopup: false,
      showAssessmentPopup: false,
      overlay: false,
    };
  },
  methods: {
    clickOnOverlay() {
      this.overlay = false;
      (this.$refs.selectCoursesRef as any).clickOnOverlay();
    },
    showOverlay(event: any) {
      this.overlay = event;
    },
    addStepToRoadMap(stepDetails: any) {
      this.$emit("AddStepToRoadMap", stepDetails);
    },
    deleteButton(id: number) {
      this.$emit("DeleteStepFromRoadMap", id);
    },
    showSelectCourse() {
      this.showCoursePopup = true;
    },
    showSelectAssessment() {
      this.showAssessmentPopup = true;
    },
    closeCoursesList() {
      this.showCoursePopup = false;
    },
    closeSelectAssessment() {
      this.showAssessmentPopup = false;
    },
  },
  computed: {
    getStepIds() {
      return this.steps?.map((item: any) => item.Id);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";

.ghost {
  opacity: 0.5;
  background: $track_lane;
}

.list-item,
.list-group {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
}

.list-buttons {
  display: inline-block;
  padding: 5px;
  border: 1px solid $track_lane;
  border-radius: 5px;
}

.selectCourses-container {
  padding: 16px;
}

@media only screen and (max-width: 600px) {
  .list-item {
    flex-direction: column;
  }
  .list-buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
  }
}
</style>
