<template>
  <div class="container">
    <div class="elements">
      <v-form
        ref="searchFrom"
        v-model="valid"
        lazy-validation
        class="form-inputs"
      >
        <v-row>
          <v-col cols="12" md="6">
            <span>Who are you looking for ?</span>
          </v-col>
          <v-col cols="12" md="6">
            <AutoCompleteField
              dense
              label="Select your Role requirements"
              item-title="Name"
              item-value="id"
              :rules="[rules.required]"
              :items="getJobRoles"
              v-model="criteria.jobRole"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <span>Desired Skills (Most important to least important)</span>
          </v-col>
          <v-col cols="12" md="6">
            <AutoCompleteField
              dense
              :rules="[rules.required, rules.maxSelection]"
              label="Select your Skills requirements"
              v-model="criteria.skills"
              :items="getSkills"
              item-title="Text"
              item-value="id"
              multiple
              chips
              closable-chips
              outlined
              :counter="5"
            >
              <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :title="item?.raw?.Text"
                  :value="item?.raw?.id"
                ></v-list-item>
              </template>
            </AutoCompleteField>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-btn @click="goToSearch"> Find it for me </v-btn>
        </v-row>
      </v-form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRaw } from "vue";
import store from "../../store";
import { SearchTeammemberRequestModel } from "shared-components/src/services/openApi/api";

export default defineComponent({
  props: ["details", "skills"],
  async mounted() {},
  components: {},
  data() {
    return {
      criteria: {} as SearchTeammemberRequestModel,
      valid: true,
      rules: {
        required: (value: any) =>
          (!(value instanceof Array) && !!value) ||
          (value instanceof Array && value.length > 0) ||
          "Required.",
        maxSelection: (value: any) =>
          (value instanceof Array && value.length <= 5) || "Max Selection is 5",
      },
    };
  },
  methods: {
    async goToSearch() {
      const isValid = await (this.$refs.searchFrom as any).validate();
      if (isValid.valid) {
        store.dispatch("setCriteria", {
          searchEmployeeCriteria: { ...this.criteria },
        });
        this.$router.push({
          name: "search",
        });
      }
    },
  },
  computed: {
    getJobRoles() {
      return toRaw(this.details).filter((item: any) => item.Type == "JobRole");
    },
    getSkills() {
      return toRaw(this.skills);
    },
  },
});
</script>
<style scoped lang="scss">
.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  .v-row {
    margin: 0;
  }
  .elements {
    margin: auto;
    width: 100%;
  }
}
</style>
