import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_AutoCompleteField, {
                    label: "Select",
                    items: _ctx.teammemberList,
                    "item-title": "FullName",
                    "item-value": "Id",
                    modelValue: _ctx.teamMembersSelected,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.teamMembersSelected) = $event)),
                    multiple: "",
                    chips: "",
                    "return-object": ""
                  }, null, 8, ["items", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                class: "action-btns"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    onClick: _ctx.cancelSelection,
                    class: "secondary_btn"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    onClick: _ctx.saveAndClose,
                    class: "primary_btn_v2"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Save")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}