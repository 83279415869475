import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "boxs-container" }
const _hoisted_2 = {
  key: 0,
  class: "first-page"
}
const _hoisted_3 = {
  key: 1,
  class: "second-page"
}
const _hoisted_4 = {
  key: 0,
  class: "first-page"
}
const _hoisted_5 = {
  key: 1,
  class: "second-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickSearch = _resolveComponent("QuickSearch")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["item-box", { 'large-mode': _ctx.mode == 'quickSearch' }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeMode('quickSearch')))
    }, [
      (_ctx.mode != 'quickSearch')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[4] || (_cache[4] = [
            _createTextVNode(" Search By Skill "),
            _createElementVNode("p", { class: "description" }, "Normal search", -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.mode == 'quickSearch')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_QuickSearch, {
              details: _ctx.details,
              skills: _ctx.skills
            }, null, 8, ["details", "skills"])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["item-box", { 'large-mode': _ctx.mode == 'aisearch' }]),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeMode('aisearch')))
    }, [
      (_ctx.mode != 'aisearch')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[5] || (_cache[5] = [
            _createTextVNode(" Search By JD "),
            _createElementVNode("p", { class: "description" }, "AI search", -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.mode == 'aisearch')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_v_form, {
              ref: "searchFromJD",
              modelValue: _ctx.valid,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valid) = $event)),
              "lazy-validation": "",
              class: "form-inputs jd-form-search"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TextAreaField, {
                  dense: "",
                  label: "Type your Job Description",
                  rules: [_ctx.rules.required],
                  modelValue: _ctx.jobDescription,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.jobDescription) = $event)),
                  outlined: ""
                }, null, 8, ["rules", "modelValue"]),
                _createVNode(_component_v_btn, { onClick: _ctx.SearchAi }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Find it for me")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}