import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "actionBtns mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_TimeSheetProcessingAddon = _resolveComponent("TimeSheetProcessingAddon")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_PayrollAddon = _resolveComponent("PayrollAddon")!
  const _component_ContractAddon = _resolveComponent("ContractAddon")!
  const _component_VirtualDesktopAddon = _resolveComponent("VirtualDesktopAddon")!
  const _component_OfficeSpaceAddon = _resolveComponent("OfficeSpaceAddon")!
  const _component_LineManagerAddon = _resolveComponent("LineManagerAddon")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_form, {
      ref: "mainForm",
      loading: _ctx.loading,
      disabled: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { flat: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_expansion_panels, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_expansion_panel, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.timeSheetProcessing.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  class: "d-flex justify-start",
                                  cols: "4"
                                }, {
                                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                                    _createTextVNode(" Timesheet Processing ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_TimeSheetProcessingAddon, {
                              timeSheetProcessing: _ctx.model.timeSheetProcessing
                            }, null, 8, ["timeSheetProcessing"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.payRoll.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  class: "d-flex justify-start",
                                  cols: "4"
                                }, {
                                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                                    _createTextVNode(" Payroll ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_PayrollAddon, {
                              payRoll: _ctx.model.payRoll
                            }, null, 8, ["payRoll"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.contract.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  class: "d-flex justify-start",
                                  cols: "4"
                                }, {
                                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                                    _createTextVNode(" Contract ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ContractAddon, {
                              contract: _ctx.model.contract
                            }, null, 8, ["contract"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.virtualDesktop.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "4" }, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode(" Virtual Desktop ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_VirtualDesktopAddon, {
                              showEnableCheckBox: true,
                              virtualDesktopSpec: _ctx.model.virtualDesktop,
                              diskValues: _ctx.diskValues,
                              cpuValues: _ctx.cpuValues,
                              ramValues: _ctx.ramValues
                            }, null, 8, ["virtualDesktopSpec", "diskValues", "cpuValues", "ramValues"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, {
                      disabled: 
                _ctx.commitmentModel.LocationType == 'Remote' ||
                _ctx.commitmentModel.OfficeLocationId != 'OfficeSpace'
              
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.officeSpace.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  class: "d-flex justify-start",
                                  cols: "4"
                                }, {
                                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createTextVNode(" Office Space ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_OfficeSpaceAddon, {
                              calculateOfficeSpace: _ctx.calculateOfficeSpace,
                              officeSpace: _ctx.model.officeSpace,
                              officeSpaces: _ctx.officeSpaces
                            }, null, 8, ["calculateOfficeSpace", "officeSpace", "officeSpaces"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_v_expansion_panel, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, {
                          class: _normalizeClass({ active: _ctx.model.lineManager.Enabled })
                        }, {
                          default: _withCtx(({ expanded }) => [
                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  class: "d-flex justify-start",
                                  cols: "4"
                                }, {
                                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                                    _createTextVNode(" Line Manager ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["class"]),
                        _createVNode(_component_v_expansion_panel_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_LineManagerAddon, {
                              lineManagerAddon: _ctx.model.lineManager,
                              lineManagers: _ctx.lineManagers
                            }, null, 8, ["lineManagerAddon", "lineManagers"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, null, {
                          default: _withCtx(({ expanded }) => [
                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  class: "d-flex justify-start",
                                  cols: "4"
                                }, {
                                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                                    _createTextVNode(" Traning Roadmaps ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_expansion_panel, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_expansion_panel_title, null, {
                          default: _withCtx(({ expanded }) => [
                            _createVNode(_component_v_row, { "no-gutters": "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  class: "d-flex justify-start",
                                  cols: "4"
                                }, {
                                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                                    _createTextVNode(" Talent Development Manager ")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loading", "disabled"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_btn, {
        class: "secondary_btn",
        onClick: _ctx.backStep,
        loading: _ctx.loading
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("Previous")
        ])),
        _: 1
      }, 8, ["onClick", "loading"]),
      _createVNode(_component_v_btn, {
        class: "primary_btn_v2",
        onClick: _ctx.nextStep,
        loading: _ctx.loading
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode("Save")
        ])),
        _: 1
      }, 8, ["onClick", "loading"])
    ])
  ]))
}