import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "elements" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_form, {
        ref: "searchFrom",
        modelValue: _ctx.valid,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valid) = $event)),
        "lazy-validation": "",
        class: "form-inputs"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("span", null, "Who are you looking for ?", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AutoCompleteField, {
                    dense: "",
                    label: "Select your Role requirements",
                    "item-title": "Name",
                    "item-value": "id",
                    rules: [_ctx.rules.required],
                    items: _ctx.getJobRoles,
                    modelValue: _ctx.criteria.jobRole,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.criteria.jobRole) = $event)),
                    outlined: ""
                  }, null, 8, ["rules", "items", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("span", null, "Desired Skills (Most important to least important)", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AutoCompleteField, {
                    dense: "",
                    rules: [_ctx.rules.required, _ctx.rules.maxSelection],
                    label: "Select your Skills requirements",
                    modelValue: _ctx.criteria.skills,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.criteria.skills) = $event)),
                    items: _ctx.getSkills,
                    "item-title": "Text",
                    "item-value": "id",
                    multiple: "",
                    chips: "",
                    "closable-chips": "",
                    outlined: "",
                    counter: 5
                  }, {
                    item: _withCtx(({ props, item }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: item?.raw?.Text,
                        value: item?.raw?.id
                      }), null, 16, ["title", "value"])
                    ]),
                    _: 1
                  }, 8, ["rules", "modelValue", "items"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "justify-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, { onClick: _ctx.goToSearch }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Find it for me ")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}