<template>
  <div></div>
</template>
<script lang="ts">
import router from "@/router";
import { defineComponent } from "vue";
import { Paths } from "shared-components/src/definitions/constants";
import { useStore } from "vuex";
import { defaultUser } from "@/store/state";

export default defineComponent({
  mounted() {
    const storeObject = useStore();
    var clientInfo = defaultUser;
    localStorage.idToken = "";
    localStorage.staySignedIn = "false";
    storeObject.dispatch("setUserInfo", { clientInfo, vm: this });
    storeObject.dispatch("setSendLoginStatus", false);
    router.push(Paths.Client.Login);
  },
});
</script>
