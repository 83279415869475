import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "main-selected-chips-box" }
const _hoisted_2 = { class: "chip-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createTextVNode(" Filters: ")),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criteriaChips, (item, index) => {
        return (_openBlock(), _createBlock(_component_v_chip, {
          closable: item.closable,
          key: index,
          color: item.background,
          variant: "outlined",
          "onClick:close": ($event: any) => (_ctx.RemoveFromCriteria(item))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.title), 1)
          ]),
          _: 2
        }, 1032, ["closable", "color", "onClick:close"]))
      }), 128))
    ])
  ]))
}