import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_flex = _resolveComponent("v-flex")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.isLoading,
    class: "login-form magiclink-form"
  }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_v_card_title, {
            key: 0,
            class: "justify-center"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("p", null, "Checking the link...", -1)
            ])),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_card_title, { class: "justify-center" }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, "Welcome back " + _toDisplayString(_ctx.fullName), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_layout, { wrap: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_flex, { xs12: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_checkbox, {
                          modelValue: _ctx.staySignedIn,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.staySignedIn) = $event)),
                          label: "Keep me signed in"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_btn, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.gotoDashboard())),
                class: "fullSize-button",
                color: "accent"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Continue to my dashboard")
                ])),
                _: 1
              })
            ])
          ]))
    ]),
    _: 1
  }, 8, ["loading"]))
}