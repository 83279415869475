<template>
  <v-dialog
    v-model="showActionDialog"
    persistent
    max-width="800px"
  >
    <v-form ref="mainForm">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title>
          <div class="card-title">
            <div class="card-title-left">{{getActionTitle()}}</div>
            <div class="card-title-right">
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <DateInputField
                label="Effective Date"
                :rules="[rules.required]"
                first-day-of-week="1"
                v-model="selectedEffectiveDate"
                hide-actions
                placeholder="Start date"
                prepend-icon=""
                :min="getMinDate"
              />
            </v-col>
          </v-row>
          <VirtualDesktopAddon 
            :showEnableCheckBox="false"
            :virtualDesktopSpec="virtualDesktopSpec"
            :diskValues="diskValues"
            :cpuValues="cpuValues"
            :ramValues="ramValues"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="loading" :loading="loading" color="primary" variant="elevated" @click="closeDialog">Cancel</v-btn>
          <v-btn :disabled="loading" :loading="loading" color="primary" variant="elevated" @click="applyAction">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { ClientCommitmentApi, CommitmentActionDetails, CommitmentActionItem, CommitmentActionModel, CommitmentActionVirtualDesktopDetails, CommitmentActionWithAltItemTypeEnum } from "shared-components/src/services/openApi/api";
import store from "@/store";
import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue";
import rules from "shared-components/src/utils/validations";
import moment from "moment";

export default defineComponent({
  components: {
    VirtualDesktopAddon
  },
  props: ["commitmentId", "commitmentAction"],
  data() {
    return {
      rules,
      ramValues: {
        0: "8",
        1: "16",
        2: "24",
        3: "32",
      },
      cpuValues: {
        0: "2",
        1: "4",
        2: "6",
      },
      diskValues: {
        0: "128",
        1: "256",
        2: "512",
      },
      virtualDesktopSpec: {
        AdditionalSecurityBL: false,
        AdditionalSecurityNG: false,
        Cpu: 0,
        Disk: 0,
        Level: null as string | null,
        Location: null as string | null,
        OperatingSystem: null as string | null,
        Policy: null as string | null,
        Ram: 0,
      },
      loading: false,
      showActionDialog: true,
      selectedEffectiveDate: null as Date | null,
    };
  },
  async created() {
    
  },
  mounted() {},
  methods: {
    getActionTitle(){
      switch (this.commitmentAction.Type){
        case CommitmentActionWithAltItemTypeEnum.CustomerAddVirtualDesktop: 
          return "Add a new Virtual Desktop";
      }
    },
    closeDialog() {
      this.$emit("OnClose");
    },
    async applyAction(){
      const isValid = await (this.$refs.mainForm as any).validate();
      if (isValid.valid) {
        try{
            this.loading = true;
            const requestModel = {
              ActionItems: [],
              CommitmentId: this.commitmentId,
              EffectiveDate: moment(this.selectedEffectiveDate).format("YYYY-MM-DDT00:00:00Z"),
            } as CommitmentActionModel;
            if(this.commitmentAction.Type == CommitmentActionWithAltItemTypeEnum.CustomerAddVirtualDesktop){
             
              requestModel.ActionItems?.push({
                Details: {
                  VirtualDesktop: {
                    AdditionalSecurityBL: this.virtualDesktopSpec.AdditionalSecurityBL,
                    AdditionalSecurityNG: this.virtualDesktopSpec.AdditionalSecurityNG,
                    Cpu: Number(Object.values(this.cpuValues)[this.virtualDesktopSpec.Cpu]),
                    Disk: Number(Object.values(this.diskValues)[this.virtualDesktopSpec.Disk]),
                    Ram: Number(Object.values(this.ramValues)[this.virtualDesktopSpec.Ram]),
                    Level: this.virtualDesktopSpec.Level,
                    Location: this.virtualDesktopSpec.Location,
                    OperatingSystem: this.virtualDesktopSpec.OperatingSystem,
                    Policy: this.virtualDesktopSpec.Policy
                  } as CommitmentActionVirtualDesktopDetails
                } as CommitmentActionDetails,
                Type: CommitmentActionWithAltItemTypeEnum.CustomerAddVirtualDesktop
              } as CommitmentActionItem)
            }
            const resposne = await new ClientCommitmentApi().clientApplyActionToCommitment(requestModel);
            store.dispatch("showSuccessMessage", "Action applied successfully");
            this.$emit("OnSuccess", resposne);
        }finally{
          this.loading = false;
        }
      }
    }
  },
  computed: {
    getMinDate() {
      const today = moment();
      today.add(this.commitmentAction.Alt, "days")
      return today.toDate().toDateString();
    },
  }
});
</script>
<style lang="scss" scoped>

</style>
