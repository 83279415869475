<template>
  <v-form ref="frmMain" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title> Add new Project </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <TextField
              label="Name"
              density="compact"
              variant="outlined"
              v-model="model.name"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" md="12">
            <TextAreaField
              label="Description"
              density="compact"
              variant="outlined"
              v-model="model.description"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <div class="v-card-actions">
        <v-col cols="12" md="12">
          <v-btn
            class="right"
            color="secondary"
            @click="save"
            :loading="loading"
            :disabled="loading"
            ><v-icon>mdi-check</v-icon>Add</v-btn
          >
          <v-btn
            variant="text"
            color="accent"
            class="right mr-5"
            @click="cancel"
            :disabled="loading"
            >cancel</v-btn
          >
        </v-col>
      </div>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: ["addLoading"],
  data() {
    return {
      valid: false,
      model: {
        name: "",
        description: "",
      },
      loading: false,
      rules: {
        required: (value: any) =>
          (!(value instanceof Array) && !!value) ||
          (value instanceof Array && value.length > 0) ||
          "Required.",
      },
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async save() {
      const isValid = await (this.$refs.frmMain as any).validate();
      if (isValid.valid) {
        this.loading = true;
        this.$emit("success", this.model);
      }
    },
  },
  watch: {
    addLoading(newVal) {
      this.loading = newVal;
    },
  },
});
</script>
