import ApiService from "./ApiService";
import Dashboard, {
  DashboardEpic,
} from "shared-components/src/models/Dahboard";

export default class DashboardService {
  public static async getProjects(
    startDate: Date,
    endDate: Date,
    teammemberId = "",
    projectId = ""
  ): Promise<Dashboard> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/client/dashboard`, {
        startDate,
        endDate,
        teammemberId,
        projectId,
      })
        .then((result) => {
          if (!result.data) {
            resolve({} as Dashboard);
          } else {
            var dashboard = result.data as Dashboard;
            dashboard.projects.forEach((c) => {
              var result = [] as DashboardEpic[];
              c.epics.forEach((e) => {
                var exist = result.some((r) => r.name == e.name);
                if (!exist) {
                  var epicItem = {
                    name: e.name,
                    netEffort: c.epics
                      .filter((ei) => ei.name === e.name)
                      .map((item) => item.netEffort)
                      .reduce((prev, next) => prev + next),
                  } as DashboardEpic;
                  result.push(epicItem);
                }
              });
              c.epics = result;
            });
            resolve(dashboard);
          }
        })
        .catch((error) => reject(error));
    });
  }
}
