import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "assessment-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_AssessmentFilter = _resolveComponent("AssessmentFilter")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_AssessmentListItem = _resolveComponent("AssessmentListItem")!
  const _component_empty_course_card = _resolveComponent("empty-course-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showAsPage)
      ? (_openBlock(), _createBlock(_component_v_overlay, {
          key: 0,
          modelValue: _ctx.overlay,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.overlay) = $event)),
          "scroll-strategy": "block",
          "location-strategy": "connected",
          opacity: "0.8",
          persistent: "",
          onClick: _ctx.clickOnOverlay
        }, null, 8, ["modelValue", "onClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AssessmentFilter, {
      onShowOverlay: _ctx.filterToggle,
      onBackBtnClicked: _ctx.handelBackToRoadMap,
      onDoSearch: _ctx.handleSearchFilter,
      onTimeChipClosed: _ctx.handelTimeChipClosed,
      ShowBackBtn: _ctx.backButtonVisibility,
      showAssessmentBtn: _ctx.assessmentBtnVisiblity,
      top: _ctx.topValue,
      height: _ctx.heightValue,
      padding: _ctx.paddingValue,
      overlay: _ctx.overlay,
      showFilter: _ctx.showFilter,
      skills: _ctx.skills
    }, null, 8, ["onShowOverlay", "onBackBtnClicked", "onDoSearch", "onTimeChipClosed", "ShowBackBtn", "showAssessmentBtn", "top", "height", "padding", "overlay", "showFilter", "skills"]),
    _createVNode(_component_v_row, { class: "card-list" }, {
      default: _withCtx(() => [
        (_ctx.isDetailsLoading)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 0,
              cols: "12",
              class: "loading"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_progress_circular, {
                  width: 3,
                  size: 70,
                  color: "red",
                  indeterminate: ""
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assessments, (assessment) => {
          return (_openBlock(), _createBlock(_component_v_col, {
            cols: "4",
            md: "3",
            sm: "6",
            xs: "12",
            key: assessment.Id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AssessmentListItem, {
                assessment: assessment,
                showAddToRoadMap: _ctx.showAddToRoadMap,
                onAddAssessmentToRoadMap: _ctx.addToRoadMap,
                stepIds: _ctx.stepIds
              }, null, 8, ["assessment", "showAddToRoadMap", "onAddAssessmentToRoadMap", "stepIds"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (_ctx.showEmptyAssessmentCard)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 1,
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_empty_course_card)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.showErrorAlert)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 1,
          type: "error",
          dismissible: "",
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showErrorAlert = false))
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Error fetching data. Please try again. ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}