import ApiService from "./ApiService";
import {Activity} from "shared-components/src/models/Activity";

export default class ActivityService {
    public static async getActivities(): Promise<Activity[]> {
        return new Promise((resolve, reject) => {
            ApiService.get(`/client/dashboard/activities`).then(result => {
                if (!result.data) {
                    resolve([] as Activity[]);
                } else {
                    var activities = result.data as Activity[];
                    resolve(activities);
                }
            }).catch(error => reject(error));
            
        });
    }
}
