<template>
  <v-card class="mx-auto" variant="tonal" color="primary">
    <v-card-text bg-color="primary">
      <SearchBox :description="positionJD" @searchByDescription="searchByDescription"/>
      <AutoCompleteField dense label="Role" item-title="Name" item-value="Name" :items="details"
        v-model="searchCriteria.jobRoles" multiple chips closable-chips outlined />
      <AutoCompleteField dense label="Skills" v-model="searchCriteria.skills" :items="skills" item-title="Text"
        item-value="Text" multiple chips closable-chips outlined>
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :title="item?.raw?.Text" :value="item?.raw?.id"></v-list-item>
        </template>
      </AutoCompleteField>
      <AutoCompleteField dense label="Min. Total Experience" :items="experienceYearList"
        v-model="searchCriteria.totalExperience" outlined />
      <AutoCompleteField dense label="Location" item-title="text" item-value="text" :items="locations" multiple chips
        closable-chips outlined v-model="searchCriteria.locations">
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :title="item?.raw?.text" :value="item?.raw?.text"></v-list-item>
        </template>
      </AutoCompleteField>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import CoreSkill from "shared-components/src/models/CoreSkill";
import Details from "shared-components/src/models/Details";
import { PropType, defineComponent } from "vue";
import { Profile } from "shared-components/src/definitions/config";
import { AzureAISearchTeammemberRequestModel } from "shared-components/src/services/openApi/api";
import SearchBox from "./SearchBox.vue";

export default defineComponent({
  props: {
    positionJD: {
      type: String,
      required: false,
    },
    criteria: {
      type: Object as PropType<AzureAISearchTeammemberRequestModel>,
      required: true,
    },
    experienceYearList: {
      type: Array as () => string[],
      default: Profile.Members.SkillExperienceList
    },
    locations: {
      type: Array,
      default: [
        { text: "Australia" }, { text: "Dubai" }, { text: "Hong Kong" }, { text: "India" }, { text: "Iran" }, { text: "Malaysia" }, { text: "Philippines" }, { text: "Singapore" }, { text: "Taiwan" }],
    },
    skills: {
      type: Array as () => CoreSkill[],
      required: true,
    },
    details: {
      type: Array as () => Details[],
      required: true,
    },
  },
  async mounted() {
    this.ageRangeMin = this.ageRange[0];
    this.ageRangeMax = this.ageRange[1];
    // this.searchCriteria.skills = this.criteria.skills;
    // this.searchCriteria.jobRoles = this.criteria.jobRoles;
  },
  components: { SearchBox },
  data() {
    return {
      tab: 'filters',
      searchCriteria: this.criteria,
      ageRange: this.criteria.age ?? [10, 70],
      ageRangeMin: 10,
      ageRangeMax: 70,
    };
  },
  methods: {
    searchByDescription(value: string) {
      this.searchCriteria.description =  value;
    },
    AgeChanged() {
      if (
        !this.searchCriteria.age ||
        (this.ageRange as any)[0] != (this.searchCriteria.age as any)[0] ||
        (this.ageRange as any)[1] != (this.searchCriteria.age as any)[1]
      ) {
        this.searchCriteria.age = this.ageRange;
      }

    },
  },
  watch: {
    criteria: {
      handler(newVal) {
        this.searchCriteria = newVal;
      },
      deep: true,
    },
    searchCriteria: {
      handler(val) {
        this.$emit("doSearch", this.searchCriteria);
      },
      deep: true,
    },
  },
});
</script>
<style scoped lang="scss">
.main-filter-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;

  .filter-header {
    color: #fff;
  }

  .filter-body {
    background-color: #fff;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    border-radius: 5px;
  }

}
</style>
