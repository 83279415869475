import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "epic-section"
}
const _hoisted_2 = {
  key: 0,
  class: "text-center mt-1"
}
const _hoisted_3 = {
  key: 1,
  class: "no-epic"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.project.epics && _ctx.project.epics.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass({'min-height': (!_ctx.showMore && _ctx.project.epics && _ctx.project.epics.length > 0) || (!_ctx.project.epics || _ctx.project.epics.length == 0)})
        }, [
          _createVNode(_component_v_row, { class: "justify-center ma-0" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.epics, (epic) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  cols: "10",
                  xs: "12",
                  sm: "5",
                  md: "5",
                  key: epic.name,
                  class: _normalizeClass(["rounded-lg listItem ma-3", epic.netEffort === 0 ? 'notWorking' : ''])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          md: "8",
                          sm: "7",
                          xs: "5"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(epic.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          class: "text-end",
                          md: "4",
                          sm: "5",
                          xs: "2"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(epic.netEffort), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["class"]))
              }), 128))
            ]),
            _: 1
          })
        ], 2),
        (_ctx.project.epics && _ctx.project.epics.length > 4)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_v_btn, {
                variant: "text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMore = !_ctx.showMore))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(!_ctx.showMore ? 'Show More' : "Hide"), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
        _createElementVNode("h3", null, "There are no Epic in this Project!", -1)
      ])))
}