<template>
  <v-card>
    <v-row>
      <v-col>
        <h3>Planning page</h3>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {};
  },
});
</script>

<style scoped></style>
