import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mobile-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoadMapFilter = _resolveComponent("RoadMapFilter")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_RoadMapDetails = _resolveComponent("RoadMapDetails")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_SelectTalentsPopup = _resolveComponent("SelectTalentsPopup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_RoadMapFilter, { onDoSearch: _ctx.handleSearchFilter }, null, 8, ["onDoSearch"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_v_data_table, {
              items: _ctx.roadMaps,
              headers: [],
              "item-value": "name",
              loading: _ctx.isLoading
            }, {
              item: _withCtx(({ item }) => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "10" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.Title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_col, {
                        cols: "2",
                        class: "text-right"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_menu, null, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_icon, _mergeProps(props, { class: "button" }), {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                  _createTextVNode("mdi-dots-vertical")
                                ])),
                                _: 2
                              }, 1040)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_list, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actionList, (actionButton) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, { key: actionButton }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_btn, {
                                              onClick: ($event: any) => (_ctx.doAction(actionButton, item))
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(actionButton == "Status"
                                ? item.Status == "Active"
                                  ? "Inactive"
                                  : "Active"
                                : actionButton), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Talents")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.Talents.length), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("Steps")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getSteps(item)), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode("Info")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            class: "button",
                            onClick: ($event: any) => (_ctx.showRoadMapDetails(item.Id))
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode("mdi-information")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("Status")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.Status), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              _: 1
            }, 8, ["items", "loading"])
          ]))
        : (_openBlock(), _createBlock(_component_v_data_table, {
            key: 1,
            items: _ctx.roadMaps,
            headers: _ctx.tableHeaders,
            "item-value": "name",
            loading: _ctx.isLoading
          }, {
            "item.Talents": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(item.Talents.length), 1)
            ]),
            "item.Steps": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(_ctx.getSteps(item)), 1)
            ]),
            "item.Info": _withCtx(({ item }) => [
              _createVNode(_component_v_icon, {
                class: "button",
                onClick: ($event: any) => (_ctx.showRoadMapDetails(item.Id))
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("mdi-information")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]),
            "item.actions": _withCtx(({ item }) => [
              _createVNode(_component_v_menu, null, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_icon, _mergeProps(props, { class: "button" }), {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("mdi-dots-vertical")
                    ])),
                    _: 2
                  }, 1040)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actionList, (actionButton) => {
                        return (_openBlock(), _createBlock(_component_v_list_item, { key: actionButton }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  onClick: ($event: any) => (_ctx.doAction(actionButton, item))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(actionButton == "Status"
                        ? item.Status == "Active"
                          ? "Inactive"
                          : "Active"
                        : actionButton), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["items", "headers", "loading"]))
    ]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showRoadMapDetailsDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showRoadMapDetailsDialog) = $event)),
      fullscreen: "",
      scrim: false
    }, {
      default: _withCtx(() => [
        (_ctx.selectedRoadMapToShowDetails)
          ? (_openBlock(), _createBlock(_component_RoadMapDetails, {
              key: 0,
              roadMapId: _ctx.selectedRoadMapToShowDetails,
              onCloseModal: _ctx.closeShowDetailsDialog
            }, null, 8, ["roadMapId", "onCloseModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showDeleteDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDeleteDialog) = $event)),
      "max-width": "500px",
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { isLoading: _ctx.isLoading }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "text-h5" }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Are you sure you want to delete this item?")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "action-btns"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          class: "secondary_btn",
                          onClick: _ctx.cancelDeletePopup
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("Cancel")
                          ])),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_v_btn, {
                          class: "primary_btn_v2",
                          onClick: _ctx.deleteRoadMapItem
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode("Delete")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["isLoading"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showUpdateStatusDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showUpdateStatusDialog) = $event)),
      "max-width": "600px",
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { isLoading: _ctx.isLoading }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "text-h6" }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("Are you sure you want to change the status of this item?")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "action-btns"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          class: "secondary_btn",
                          onClick: _ctx.cancelStatusPopup
                        }, {
                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createTextVNode("Cancel")
                          ])),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_v_btn, {
                          class: "primary_btn_v2",
                          onClick: _ctx.updateRoadMapStatus
                        }, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createTextVNode("Change")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["isLoading"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      "max-width": "600px",
      modelValue: _ctx.showAddTalentDialog,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showAddTalentDialog) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SelectTalentsPopup, {
          talents: _ctx.talents,
          teamMembers: _ctx.teamMembersList,
          onAddTalentsToRoadMap: _ctx.addTalentsToRoadMap,
          onCancelSelection: _ctx.cancelAddTalentDialog
        }, null, 8, ["talents", "teamMembers", "onAddTalentsToRoadMap", "onCancelSelection"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}