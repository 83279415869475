import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "card-title-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_VirtualDesktopAddon = _resolveComponent("VirtualDesktopAddon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showActionDialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showActionDialog) = $event)),
    persistent: "",
    "max-width": "800px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, { ref: "mainForm" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            loading: _ctx.loading,
            disabled: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getActionTitle()), 1),
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-title-right" }, null, -1))
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "6" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DateInputField, {
                            label: "Effective Date",
                            rules: [_ctx.rules.required],
                            "first-day-of-week": "1",
                            modelValue: _ctx.selectedEffectiveDate,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedEffectiveDate) = $event)),
                            "hide-actions": "",
                            placeholder: "Start date",
                            "prepend-icon": "",
                            min: _ctx.getMinDate
                          }, null, 8, ["rules", "modelValue", "min"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_VirtualDesktopAddon, {
                    showEnableCheckBox: false,
                    virtualDesktopSpec: _ctx.virtualDesktopSpec,
                    diskValues: _ctx.diskValues,
                    cpuValues: _ctx.cpuValues,
                    ramValues: _ctx.ramValues
                  }, null, 8, ["virtualDesktopSpec", "diskValues", "cpuValues", "ramValues"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    disabled: _ctx.loading,
                    loading: _ctx.loading,
                    color: "primary",
                    variant: "elevated",
                    onClick: _ctx.closeDialog
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  }, 8, ["disabled", "loading", "onClick"]),
                  _createVNode(_component_v_btn, {
                    disabled: _ctx.loading,
                    loading: _ctx.loading,
                    color: "primary",
                    variant: "elevated",
                    onClick: _ctx.applyAction
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Apply")
                    ])),
                    _: 1
                  }, 8, ["disabled", "loading", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["loading", "disabled"])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}