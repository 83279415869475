<template>
  <div
    class="profile-dropdown mt-2 mr-2"
    v-closeOnClick
    @click.prevent="TOGGLE_PROFILE_DROPDOWN()"
    :class="{ 'is-open elevation-10': profileDropdown }"
    v-if="clientInfo && clientInfo.email"
  >
    <HeaderNavigationMenu
      class="navigation-drp"
      v-if="userIsClient || userIsPortfolioManager"
    />
    <NotificationDropDown
      :userId="clientInfo.userId"
      :isClient="true"
      class="notification-drp"
      zIndex="3000"
      :viewAllRoute="`/client/notifications`"
    />
    <div class="header pa-3">
      <div v-show="!hideName" class="header--title mr-2">
        <div class="user-name">{{ clientInfo.fullName }}</div>
      </div>
      <v-icon
        :color="profileDropdown ? 'primary' : 'secondary'"
        class="profile-photo"
        >mdi-account-circle</v-icon
      >
    </div>
    <div class="body" v-if="profileDropdown">
      <router-link
        to="/client/dashboard"
        class="menu-item email pa-3"
        v-if="profileDropdown"
        >{{ clientInfo.email }}</router-link
      >
      <v-list class="pt-0 main-menu-items">
        <v-list-item to="/client/dashboard" class="menu-item">
          <v-list-item-content>
            <v-list-item-title> Dashboard </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/logout" class="menu-item">
          <v-list-item-content>
            <v-list-item-title> Log Out </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import { TOGGLE_PROFILE_DROPDOWN, CLOSE_PROFILE_DROPDOWN } from "@/store/types";
import NotificationDropDown from "shared-components/src/components/Notification/NotificationDropDown.vue";
import HeaderNavigationMenu from "./HeaderNavigationMenu.vue";
import store from "@/store";

export default defineComponent({
  data() {
    return {
      showDetailPopUp: false,
      selectedNotification: {},
      isthereNewNotif: false,
      notSeenNotfCount: 0,
    };
  },
  computed: {
    ...mapGetters(["clientInfo", "profileDropdown"]),
    hideName() {
      return !this.profileDropdown && this.$vuetify.display.name === "xs";
    },
    userIsClient() {
      return store.getters.userIsClient;
    },
    userIsPortfolioManager() {
      return store.getters.userIsPortfolioManager;
    },
  },
  components: { NotificationDropDown, HeaderNavigationMenu },
  methods: {
    clickedOutside() {
      this.showDetailPopUp = false;
    },
    ...mapMutations([TOGGLE_PROFILE_DROPDOWN, CLOSE_PROFILE_DROPDOWN]),
  },
  directives: {
    closeOnClick: {
      beforeMount(el, binding) {
        document.addEventListener("click", (event) => onClick(event, el));
        function onClick(event: any, element: any) {
          const clickedELement = event.target;
          const container = element;
          let clickedItemSelector;
          if (clickedELement.classList.value !== "") {
            clickedItemSelector =
              "." + clickedELement.classList.value.split(" ").join(".");
          } else if (clickedELement.id) {
            clickedItemSelector = "#" + clickedELement.id;
          } else if (
            clickedELement.parentElement &&
            clickedELement.parentElement.classList
          ) {
            clickedItemSelector =
              "." +
              clickedELement.parentElement.classList.value
                .split(" ")
                .join(".") +
              " " +
              clickedELement.tagName;
          }
          try {
            const isInContainer =
              container.querySelector(clickedItemSelector) != null;
            if (!isInContainer) {
              //binding.instance.CLOSE_PROFILE_DROPDOWN();
            }
          } catch (e) {
            //this.CLOSE_PROFILE_DROPDOWN();
            //binding.instance.CLOSE_PROFILE_DROPDOWN();
          }
        }
      },
    },
  },
});
</script>
<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
.profile-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: fixed;
  right: 0;
  top: 0;
  color: $pit_crew_uniform;
  transition: all 0.2s;
  height: 68px;
  max-height: 68px;
  width: auto;
  .navigation-drp {
    top: 7.5px;
    left: -100px;
  }
  .notification-drp {
    top: 7.5px;
    left: -40px;
  }
  &.is-open {
    background: $dark_gray;
    height: 248px;
    max-height: 248px;
    z-index: 2;
    .header {
      margin: 10px 5px 0 0;
      .profile-photo {
        width: 60px;
        height: 60px;
        font-size: $size-xl;
      }
      .user-name {
        font-size: $size-s;
        color: $primary;
      }
    }
  }
  &:not(.is-open) {
    .header {
      &:hover {
        background-color: rgba($dark_gray, 0.1);
      }
    }
  }

  .header {
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.2s;
    .header--title {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
    .profile-photo {
      width: 30px;
      height: 30px;
      font-size: $size-l;
      border-radius: 50%;
      transition: all 0.2s;
    }
    .user-name {
      font-weight: $bold-l;
      line-height: 20px;
      color: $text_color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    .menu-item {
      width: 100%;
      text-align: center;
      font-weight: $bold-l;
      color: $primary;
      cursor: pointer;
      transition: all 0.2s;
      text-decoration: none;
    }
    .email {
      background-color: $racecar;
      color: $primary;
      &:hover {
        background-color: $racecar;
      }
    }
  }
}
</style>
