<template>
  <div 
    :class="[type == 'Risk' ? 'bg-error' : type == 'Warning' ? 'bg-warning' : 'bg-info']"
    class="alert-item"
    v-tooltip:top="tooltip"> 
    <v-icon :icon="icon"/>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
   props: {
    tooltip: {
      type: String,
    },
    icon: {
      type: String,
    },
    type: {
      type: String as PropType<"Info" | "Warning" | "Risk">,
    },
  },
  data() {
    return {
    }
  },
  computed: {

  },
  async mounted() {
    
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/style/color.scss";
@import "node_modules/shared-components/assets/style/style.scss";

.alert-item{
  font-size: 14px;
  padding: 5px;
  @extend .b-r-10
}
</style>
