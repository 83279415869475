<template>
  <v-card>
    <v-row class="pa-5">
      <v-col
        md="1"
        class="pa-1"
        v-for="date in dates"
        :key="`date-${date.name}`"
      >
        <v-col
          class="dateItem"
          :class="date.isClicked ? 'selectedDate' : ''"
          @click="selectMonth(date)"
        >
          <span>{{ date.name }}</span>
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Utils from "@/Helpers/Utils";
import { defineComponent } from "vue";

interface Month {
  name: string;
  startDate: Date;
  endDate: Date;
  isClicked: boolean;
}
export default defineComponent({
  data() {
    return {
      dates: [] as Month[],
    };
  },
  async mounted() {
    var dates = this.lasttwelvemonth();
    dates.forEach((c, index) => {
      if (index === 0) {
        var strDate = Utils.toVsDateFormat(c);
        var startDate = this.subtractMonths(Utils.vsDateToDatetime(strDate), 1);
        var dateModel = {
          endDate: c,
          startDate: startDate,
          name: startDate.toLocaleString("en-us", { month: "short" }),
          isClicked: false,
        };
        this.dates.push(dateModel);
      } else {
        this.dates.push({
          endDate: c,
          startDate: dates[index - 1],
          name: dates[index - 1].toLocaleString("en-us", { month: "short" }),
          isClicked: false,
        });
      }
    });
    await this.$emit("filterDataByDate", {
      startDate: this.dates[0].startDate,
      endDate: this.dates[11].endDate,
    });
  },
  methods: {
    selectMonth(date: Month) {
      var dateItem = this.dates.find((c) => c.startDate === date.startDate);
      if (dateItem) {
        dateItem.isClicked = !dateItem.isClicked;
      }
      var selectedDates = this.dates.filter((c) => c.isClicked);
      this.$emit("filterDataByDate", selectedDates);
    },
    subtractMonths(date: Date, months: number) {
      date.setMonth(date.getMonth() - months);
      return date;
    },
    lasttwelvemonth() {
      var dates = [];
      var d = new Date();
      var y = d.getFullYear();
      var m = d.getMonth() + 1;
      if (m === 11) {
        for (var i = 1; i < 13; i++) {
          dates.push(
            Utils.vsDateToDatetime(y + "-" + this.padMonth(i) + "-01")
          );
        }
      } else {
        for (var i = m + 1; i < m + 13; i++) {
          if (i % 12 > m) {
            dates.push(
              Utils.vsDateToDatetime(y - 1 + "-" + this.padMonth(i + 1) + "-01")
            );
          } else {
            dates.push(
              Utils.vsDateToDatetime(
                y + "-" + this.padMonth((i % 12) + 1) + "-01"
              )
            );
          }
        }
      }
      return dates;
    },
    padMonth(month: any) {
      if (month < 10) {
        return "0" + month;
      } else {
        return month;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
.dateItem {
  text-align: center;
  border-radius: 10px;
  border: 1px solid $track_lane;
  cursor: pointer;
}
.selectedDate {
  box-shadow: 0px 0px 10px 3px $racecar;
  color: $racecar;
}
</style>
