<template>
  <div class="h-100 w-100">
    <div ref="scene" class="h-100 w-100" id="sceneContainer"></div>
    <v-dialog
      v-model="showNewProject"
      persistent
      max-width="600px"
      @keydown.esc="UpdateShowNewProjectStatus(false)"
    >
      <AddProject
        :addLoading="addLoading"
        @cancel="UpdateShowNewProjectStatus(false)"
        @success="AddNewProject($event)"
      />
    </v-dialog>
    <v-dialog
      v-model="showNewPosition"
      persistent
      max-width="600px"
      @keydown.esc="UpdateShowNewPositionStatus(false)"
    >
      <AddOrEditPositionDetails
        :isNew="true"
        :addLoading="addLoading"
        @cancel="UpdateShowNewPositionStatus(false)"
        @success="AddNewProjectPosition($event)"
        :details="details"
        :skills="skills"
        v-if="showNewPosition"
      />
    </v-dialog>
    <v-dialog
      v-model="showUpdatePosition"
      persistent
      max-width="600px"
      @keydown.esc="UpdateShowUpdatePositionStatus(false)"
    >
      <AddOrEditPositionDetails
        :isNew="false"
        :addLoading="addLoading"
        @cancel="UpdateShowUpdatePositionStatus(false)"
        @success="UpdateProjectPosition($event)"
        :details="details"
        :positionId="selectedPositionId"
        :projectModel="selectedProjectModel"
        :skills="skills"
        v-if="showUpdatePosition"
      />
    </v-dialog>
    <ProjectSearchWizard
      @AssignTmToPosition="AssignTmToPosition"
      @UpdatePositionDetail="UpdatePositionDetail"
      :skills="skills"
      :details="details"
      @OnClose="UpdateShowSearchTeammemberStatus(false)"
      :project="selectedProjectModel"
      :positionId="selectedPositionId"
      :projectLeads="projectLeads"
      :publicHolidayZones="publicHolidayZones"
      :projects="projects"
      :officeLocations="officeLocations"
      @saveLocation="saveOfficeLocation"
      :dialogLoading="dialogLoading"
      :lineManagers="lineManagers"
      :officeSpaces="officeSpaces"
      v-if="showSearchTeammember"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AddProject from "./AddProject.vue";
import AddOrEditPositionDetails from "./ProjectPosition/AddOrEditPositionDetails.vue";
import ProjectService from "@/services/ProjectService";
import PositionService from "@/services/PositionService";
import Project from "shared-components/src/models/Project";
import DetailsService from "@/services/DetailsService";
import Details from "shared-components/src/models/Details";
import CoreSkillService from "@/services/CoreSkillService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import Search from "../Search/Search.vue";
import ProjectSearchWizard from "./AddTeammember/ProjectSearchWizard.vue";
import {
  OfficeLocationModel,
  OfficeSpaceModel,
} from "shared-components/src/services/openApi/api";
import CustomerService from "@/services/CustomerService";
import LineManagerService from "@/services/LineManagerService";
import OfficeSpaceService from "@/services/OfficeSpaceService";
import ScenHelper from "./ScenHelper";
import Commitment, {
  CommitmentMin,
} from "shared-components/src/models/Commitment";

export default defineComponent({
  data() {
    return {
      loading: false,
      showNewProject: false,
      addLoading: false,
      tableLoading: false,
      projects: [] as Project[],
      message: "",
      selectedProjectId: "",
      showNewPosition: false,
      showUpdatePosition: false,
      details: [] as Details[],
      selectedPositionId: "",
      selectedProjectModel: null as Project | null,
      skills: [] as CoreSkill[],
      showSearchTeammember: false,
      projectLeads: [] as Array<{ id: string; Name: string }>,
      publicHolidayZones: [] as Array<{ id: string; Name: string }>,
      officeLocations: [
        {
          Address: "Choose Office Space From CodeClan",
          Id: "OfficeSpace",
          Name: "Choose Office Space From CodeClan",
        },
      ] as OfficeLocationModel[],
      dialogLoading: false,
      lineManagers: [] as Array<{ id: string; Name: string }>,
      officeSpaces: [] as OfficeSpaceModel[],
    };
  },
  async mounted() {
    await this.fetchProjects();
    await this.loadSkillsList();
    await this.loadDetailsList();
    await this.loadProjectLead();
    await this.loadPublicHolidayZone();
    await this.loadOfficeLocations();
    await this.loadOfficeSpaces();
    await this.loadLineManager();
    ScenHelper.InitScene(
      this.$refs.scene,
      this.projects,
      this.UpdateShowNewPositionStatus,
      this.UpdateShowUpdatePositionStatus,
      this.UpdateShowNewProjectStatus,
      this.UpdateSelectedProjectId,
      this.UpdateSelectedPositionId,
      this.UpdateSelectedProjectModel,
      this.UpdateShowSearchTeammemberStatus,
      this.details,
      this.skills
    );
    //window.addEventListener('beforeunload', )
  },
  beforeUnmount() {
    ScenHelper.DisposeScene();
  },
  components: {
    AddProject,
    AddOrEditPositionDetails,
    Search,
    ProjectSearchWizard,
  },
  methods: {
    async saveOfficeLocation(event: any) {
      this.officeLocations.push(event);
    },
    async AssignTmToPosition(commitment: Commitment) {
      if (this.selectedProjectModel) {
        this.dialogLoading = true;

        let positionIndex = this.selectedProjectModel.Positions.findIndex(
          (item) => item.Id == this.selectedPositionId
        );
        if (!this.selectedProjectModel.Positions[positionIndex].Commitments) {
          this.selectedProjectModel.Positions[positionIndex].Commitments = [];
        }
        const commitmentModel = {
          HoursPerWeekMax: commitment.HoursPerWeekMax,
          id: commitment.id,
          Name: commitment.Name,
        } as CommitmentMin;
        this.selectedProjectModel.Positions[positionIndex].Commitments?.push(
          commitmentModel
        );
        setTimeout(() => {
          this.addLoading = false;
          //this.showTMDetails = false;
          this.UpdateShowSearchTeammemberStatus(false);
          ScenHelper.AddCommitmentTableToProject(
            this.selectedProjectModel?.Positions[positionIndex],
            this.selectedProjectModel?.id ?? "",
            commitmentModel
          );
          this.selectedProjectModel = null;
          this.selectedPositionId = "";
          this.dialogLoading = false;
        }, 100);
      }
    },
    async UpdatePositionDetail(jobDescription: any) {
      if (this.selectedProjectModel) {
        this.dialogLoading = true;

        let positionIndex = this.selectedProjectModel.Positions.findIndex(
          (item) => item.Id == this.selectedPositionId
        );
        this.selectedProjectModel.Positions[positionIndex].JobDescription =
          jobDescription;
        setTimeout(() => {
          this.addLoading = false;
          //this.showTMDetails = false;
          this.UpdateShowSearchTeammemberStatus(false);
          this.selectedProjectModel = null;
          this.selectedPositionId = "";
          this.dialogLoading = false;
        }, 100);
      }
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails();
    },
    async loadProjectLead() {
      this.projectLeads = (await ProjectService.getProjectLeadList())
        .filter((c) => c.FirstName || c.LastName)
        .map((item) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },
    async loadLineManager(): Promise<void> {
      this.lineManagers = (await LineManagerService.getList())
        .filter((c) => c.FirstName || c.LastName)
        .map((item) => {
          return {
            id: item.id ?? "",
            Name: item.FirstName + " " + item.LastName,
          };
        });
    },
    async loadPublicHolidayZone() {
      this.publicHolidayZones = (
        await ProjectService.getPublicHolidayZoneList()
      ).map((item: any) => {
        return { id: item.Id, Name: item.Title };
      });
    },
    async loadOfficeLocations() {
      const response = await CustomerService.GetOfficeLocations();
      this.officeLocations = this.officeLocations.concat(response);
    },
    async loadOfficeSpaces() {
      this.officeSpaces = await OfficeSpaceService.GetOfficeSpaces();
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    async fetchProjects() {
      this.projects = await ProjectService.getList();
    },
    async AddNewProject(event: any) {
      this.addLoading = true;
      const project: Project = {
        Name: event.name,
        ProjectDesc: event.description,
        ProjectName: event.name,
      } as Project;
      const result = await ProjectService.Create(project);
      const createdProject = {
        Name: result.Name,
        ProjectName: result.ProjectName,
        id: result.id,
      } as Project;
      this.projects.push(createdProject);
      ScenHelper.AddProjectToScene(
        createdProject,
        this.projects.findIndex((item) => item.id == createdProject.id)
      );
      this.selectedProjectId = "";
      setTimeout(() => {
        this.addLoading = false;
        this.UpdateShowNewProjectStatus(false);
      }, 100);
    },
    async AddNewProjectPosition(event: any) {
      this.addLoading = true;
      event.ProjectId = this.selectedProjectId;
      const result = await PositionService.SavePosition(event);
      const projectData = this.projects.find(
        (item) => item.id == this.selectedProjectId
      );
      if (projectData && !projectData?.Positions) {
        projectData.Positions = [];
      }
      // TODO: it should be update
      //projectData?.Positions.push(result);
      ScenHelper.AddPostionTableToProject(result, this.selectedProjectId);

      setTimeout(() => {
        this.addLoading = false;
        this.UpdateShowNewPositionStatus(false);
        this.selectedProjectId = "";
      }, 100);
    },
    async UpdateProjectPosition(event: any) {
      this.addLoading = true;
      if (this.selectedProjectModel) {
        event.ProjectId = this.selectedProjectModel?.id;
        event.Id = this.selectedPositionId;
        const result = await PositionService.SavePosition(event);

        let positionIndex = this.selectedProjectModel.Positions.findIndex(
          (item) => item.Id == this.selectedPositionId
        );
        const NoOfSeatChanged =
          this.selectedProjectModel.Positions[positionIndex].NoOfPositions !=
          result.NoOfPositions;
        result.Commitments =
          this.selectedProjectModel.Positions[positionIndex].Commitments;
        // TODO: it should be update
        // this.selectedProjectModel.Positions[positionIndex] = result;
        // setTimeout(() => {
        //   this.addLoading = false;
        //   this.UpdateShowUpdatePositionStatus(false);
        //   ScenHelper.UpdatePositionMonitor(result);
        //   if (NoOfSeatChanged) {
        //     ScenHelper.UpdateProjectTablesPosition(result);
        //   }
        //   this.selectedProjectModel = null;
        //   this.selectedPositionId = "";
        // }, 100);
      }
    },
    UpdateShowNewPositionStatus(value: boolean) {
      this.showNewPosition = value;
      if (value == false) {
        ScenHelper.animate();
      }
    },
    UpdateShowUpdatePositionStatus(value: boolean) {
      this.showUpdatePosition = value;
      if (value == false) {
        ScenHelper.animate();
      }
    },
    UpdateShowNewProjectStatus(value: boolean) {
      this.showNewProject = value;
      if (value == false) {
        ScenHelper.animate();
      }
    },
    UpdateShowSearchTeammemberStatus(value: boolean) {
      this.showSearchTeammember = value;
      if (value == false) {
        ScenHelper.animate();
      }
    },
    UpdateSelectedProjectId(projectId: string) {
      this.selectedProjectId = projectId;
    },
    UpdateSelectedPositionId(positionId: string) {
      this.selectedPositionId = positionId;
    },
    UpdateSelectedProjectModel(projectModel: Project | null) {
      this.selectedProjectModel = projectModel;
    },
  },
});
</script>
