import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "chartBody" }
const _hoisted_2 = { class: "chartBody" }
const _hoisted_3 = { class: "chartBody" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Pie = _resolveComponent("Pie")!
  const _component_v_card_text = _resolveComponent("v-card-text")!

  return (_openBlock(), _createBlock(_component_v_card_text, null, {
    default: _withCtx(() => [
      (_ctx.activityReport && _ctx.activityReport.length > 0)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 0,
            class: "details",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "5",
                sm: "6",
                xs: "12"
              }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Activity", -1)),
                  _createVNode(_component_v_col, { class: "mt-4" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activityReport, (activity) => {
                        return (_openBlock(), _createBlock(_component_v_row, {
                          class: "itemDetail",
                          key: `activity-${activity.id}`
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { class: "elementName" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(activity.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { class: "effortBox" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.removeDecimals(activity.netEffort)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _createVNode(_component_v_row, { class: "itemDetail sumSection" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("Sum")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { class: "effortBox" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.activitySum), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "7",
                sm: "6",
                xs: "12"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_Pie, {
                      data: _ctx.acChartData,
                      options: _ctx.options
                    }, null, 8, ["data", "options"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.technologyReport && _ctx.technologyReport.length > 0)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 1,
            class: "mt-5 details",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "5",
                sm: "6",
                xs: "12"
              }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Technology", -1)),
                  _createVNode(_component_v_col, { class: "mt-4" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.technologyReport, (tech) => {
                        return (_openBlock(), _createBlock(_component_v_row, {
                          class: "itemDetail",
                          key: `tech-${tech.id}`
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { class: "elementName" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(tech.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { class: "effortBox" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.removeDecimals(tech.netEffort)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _createVNode(_component_v_row, { class: "itemDetail sumSection" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createTextVNode("Sum")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { class: "effortBox" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.technologySum), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "7",
                sm: "6",
                xs: "12"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_Pie, {
                      data: _ctx.techChartDate,
                      options: _ctx.options
                    }, null, 8, ["data", "options"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.epicReport && _ctx.epicReport.length > 0)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 2,
            class: "mt-5 details",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "5",
                sm: "6",
                xs: "12"
              }, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Epic", -1)),
                  _createVNode(_component_v_col, { class: "mt-4" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.epicReport, (epic) => {
                        return (_openBlock(), _createBlock(_component_v_row, {
                          class: "itemDetail",
                          key: `tech-${epic.name}`
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { class: "elementName" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(epic.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { class: "effortBox" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.removeDecimals(epic.netEffort)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _createVNode(_component_v_row, { class: "itemDetail sumSection" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, null, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode("Sum")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { class: "effortBox" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.epicSum), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "7",
                sm: "6",
                xs: "12",
                justify: "right"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_Pie, {
                      data: _ctx.epicChartData,
                      options: _ctx.options
                    }, null, 8, ["data", "options"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}