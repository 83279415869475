import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "filter-container" }
const _hoisted_2 = { class: "part" }
const _hoisted_3 = { class: "flex-1-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "sticky-navbar",
      style: _normalizeStyle({ top: _ctx.top }),
      onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createVNode(_component_v_row, { class: "default-section align-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.ShowBackBtn)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      elevation: "24",
                      onClick: _ctx.handelBackBtn,
                      icon: "mdi-arrow-left",
                      density: "compact"
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_btn, {
                  onClick: _ctx.toggleFilter,
                  class: "filter-button"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Filter ")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_TextField, {
                    disableDetails: "",
                    modelValue: _ctx.searchCategory,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCategory) = $event)),
                    onKeyup: _withKeys(_ctx.searchHandler, ["enter"]),
                    placeholder: "Category",
                    "append-inner-icon": "mdi-magnify",
                    "onClick:appendInner": _ctx.searchHandler
                  }, null, 8, ["modelValue", "onKeyup", "onClick:appendInner"])
                ]),
                (_ctx.showAssessmentBtn)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      onClick: _ctx.toggleNewAssessment,
                      class: "filter-button",
                      stacked: ""
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("new Assessment")
                      ])),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          (
            _ctx.criteria.selectedSkill.length > 0 ||
            _ctx.criteria.selectedTime ||
            _ctx.criteria.selectedLevel.length > 0
          )
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                cols: "12"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criteria.selectedSkill, (skill, index) => {
                    return (_openBlock(), _createBlock(_component_v_chip, {
                      "onClick:close": ($event: any) => (_ctx.closeSkillChip(index)),
                      closable: "",
                      key: index,
                      class: "mr-2",
                      color: _ctx.backgrounds.skill
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Technology: " + _toDisplayString(_ctx.getSkillName(skill)), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick:close", "color"]))
                  }), 128)),
                  (_ctx.criteria.selectedTime)
                    ? (_openBlock(), _createBlock(_component_v_chip, {
                        key: 0,
                        "onClick:close": _ctx.closeTimeChip,
                        closable: "",
                        class: "mr-2",
                        color: _ctx.backgrounds.duration
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Duration: Between " + _toDisplayString((_ctx.criteria.selectedTime as any).split(",")[0]) + "h - " + _toDisplayString((_ctx.criteria.selectedTime as any).split(",")[1]) + "h ", 1)
                        ]),
                        _: 1
                      }, 8, ["onClick:close", "color"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criteria.selectedLevel, (skill, index) => {
                    return (_openBlock(), _createBlock(_component_v_chip, {
                      "onClick:close": ($event: any) => (_ctx.closeLevelChip(index)),
                      key: index,
                      closable: "",
                      class: "mr-2",
                      color: _ctx.backgrounds.level
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Level: " + _toDisplayString(skill), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick:close", "color"]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.showFilter)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 0,
            class: "filter"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, { class: "w-100" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AutoCompleteField, {
                            clearable: "",
                            chips: "",
                            label: "Select your Technology:",
                            items: _ctx.skills,
                            "item-title": "Text",
                            "item-value": "id",
                            multiple: "",
                            modelValue: _ctx.criteria.selectedSkill,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.criteria.selectedSkill) = $event)),
                            "hide-details": "",
                            "closable-chips": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            label: "Select the time:",
                            items: _ctx.filterData.duration,
                            modelValue: _ctx.criteria.selectedTime,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.criteria.selectedTime) = $event)),
                            "item-title": "Text",
                            "item-value": "Value",
                            "hide-details": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectField, {
                            label: "Select your Level:",
                            items: _ctx.filterData.level,
                            modelValue: _ctx.criteria.selectedLevel,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.criteria.selectedLevel) = $event)),
                            "item-title": "Text",
                            "item-value": "Value",
                            clearable: "",
                            chips: "",
                            multiple: "",
                            "hide-details": "",
                            "closable-chips": ""
                          }, null, 8, ["items", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 4)
  ]))
}