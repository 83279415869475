import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/code-clan-logo.svg'


const _hoisted_1 = { class: "common-container" }
const _hoisted_2 = {
  key: 0,
  id: "signin-description",
  class: "signin__card card--secondary"
}
const _hoisted_3 = { class: "tempale-container" }
const _hoisted_4 = { class: "signin__header" }
const _hoisted_5 = { class: "signin__title" }
const _hoisted_6 = {
  key: 0,
  class: "headline"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  class: "logo",
  src: _imports_0
}
const _hoisted_9 = { class: "forms pt-0 pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["signin", { 'secondary-is-open': _ctx.openSecondaryPanel }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.openSecondaryPanel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("img", {
              class: "logo",
              src: _imports_0
            }, null, -1)),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.template)))
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_card, { class: "signin__card card--background signin-box" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.loginTitle)
                ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.loginTitle), 1))
                : _createCommentVNode("", true),
              (_ctx.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.description), 1))
                : _createCommentVNode("", true)
            ]),
            _withDirectives(_createElementVNode("img", _hoisted_8, null, 512), [
              [_vShow, !_ctx.openSecondaryPanel]
            ])
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "secondary-container" }, null, -1)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_router_view)
          ])
        ]),
        _: 1
      })
    ])
  ], 2))
}