import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chart-line-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Line = _resolveComponent("Line")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Line, {
      data: _ctx.chartData,
      options: _ctx.lineOptions
    }, null, 8, ["data", "options"]),
    (_ctx.chartData.datasets.length == 0)
      ? (_openBlock(), _createBlock(_component_v_progress_circular, {
          key: 0,
          size: 50,
          indeterminate: "",
          class: "chart-loading"
        }))
      : _createCommentVNode("", true)
  ]))
}