<template>
  <div>
    <div class="card-title">
      <div class="card-title-left"></div>
      <div class="card-title-right">
        <v-btn
          class="primary_btn"
          prepend-icon="mdi-plus"
          @click="showNewProject"
          :disabled="loading"
          >New Project</v-btn
        >
      </div>
    </div>
    <LoadingV2 v-if="loading" :darkMode="true" />
    <v-row class="mt-3">
      <v-col cols="12" v-for="project in projects" :key="project.Id">
        <ProjectItem 
          :project="project" 
          :projectList="getProjectList" 
          :ref="project.Id" 
          :details="details" 
          :skills="skills"
          :projectLeads="projectLeads"
          :publicHolidayZones="publicHolidayZones"
          :officeLocations="officeLocations"
          :lineManagers="lineManagers"
          :portfolioManagers="portfolioManagers"
          :officeSpaces="officeSpaces"
          :commitmentActions="commitmentActions"
          />
      </v-col>
    </v-row>
    <v-dialog v-model="showProjectModal" max-width="900">
      <ProjectDetail
        @close="closeProjectPopup"
        @savedProject="savedProject"
        :project="selectedProject"
      />
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import VdSupport from "shared-components/src/components/Vm/VdSupport.vue";
import Project from "shared-components/src/models/Project";
import ProjectDetail from "shared-components/src/components/Project/ProjectDetails.vue";
import EpicList from "shared-components/src/components/Project/EpicList.vue";
import store from "@/store";
import {
  ClientCommitmentApi,
  ClientProjectApi,
  ClientProjectWithDetailsResponseModel,
  CommitmentActionWithAltItem,
  DateRangeRequestModel,
  OfficeLocationModel,
  OfficeSpaceModel,
} from "shared-components/src/services/openApi/api";
import Avatar from "@/components/Avatar.vue";
import moment from "moment";
import ActivityWatchReport from "shared-components/src/components/Reports/ActivityWatch/ActivityWatchReport.vue";
import LoadingV2 from "shared-components/src/components/LoadingV2.vue";
import ProjectItem from "./ProjectItem.vue";
import CoreSkill from "shared-components/src/models/CoreSkill";
import Details from "shared-components/src/models/Details";
import DetailsService from "@/services/DetailsService";
import CoreSkillService from "@/services/CoreSkillService";
import ProjectService from "@/services/ProjectService";
import PortfolioManagerService from "@/services/PortfolioManagerService";
import LineManagerService from "@/services/LineManagerService";
import CustomerService from "@/services/CustomerService";
import OfficeSpaceService from "@/services/OfficeSpaceService";

export default defineComponent({
  components: {
    ProjectDetail,
    EpicList,
    Avatar,
    ActivityWatchReport,
    VdSupport,
    LoadingV2,
    ProjectItem,
  },
  data() {
    return {
      loading: false,
      showProjectModal: false,
      showActivityWatchModal: false,
      showWazuhModal: false,
      showVdSupportModal: false,
      selectedVmId: null as string | null,
      projectLeads: [] as Array<{ id: string; Name: string }>,
      portfolioManagers: [] as Array<{ id: string; Name: string }>,
      publicHolidayZones: [] as Array<{ id: string; Name: string }>,
      lineManagers: [] as Array<{ id: string; Name: string }>,
      officeLocations: [
        {
          Address: "Choose Office Space From CodeClan",
          Id: "OfficeSpace",
          Name: "Choose Office Space From CodeClan",
        },
      ] as OfficeLocationModel[],
      officeSpaces: [] as OfficeSpaceModel[],
      projects: [] as ClientProjectWithDetailsResponseModel[],
      selectedProject: null as Project | null,
      showEpicList: false,
      showExpiredTms: false,
      details: [] as Details[],
      skills: [] as CoreSkill[],
      commitmentActions: [] as CommitmentActionWithAltItem[]
    };
  },
  async mounted() {
    await this.fetchProjects();
    await this.loadDetailsList();
    await this.loadSkillsList();
    await this.loadProjectLead();
    await this.loadPublicHolidayZone();
    await this.loadOfficeLocations();
    await this.loadOfficeSpaces();
    await this.loadLineManager();
    await this.loadPortfolioManagers();
    await this.loadCommitmentActions();
    this.checkQueryIds();
  },
  watch: {
    "$store.state.newProjectPopup": {
      handler(value) {
        if (value == 1) {
          this.showNewProject();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ProjectFilteredTms(project: any) {
      if (project.ShowExpiredCommitments) {
        return project.Teammembers;
      } else {
        return project.Teammembers.filter(
          (item: any) => !item.IsCommitmentExpired
        );
      }
    },
    getProjectList(){
      return this.projects.map(item => { return {Id: item.Id, Name: item.Name}});
    }
  },
  methods: {
    async loadProjectLead() {
      this.projectLeads = (await ProjectService.getProjectLeadList())
        .filter((c) => c.FirstName || c.LastName)
        .map((item) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },
    async loadPortfolioManagers() {
      this.portfolioManagers = (await PortfolioManagerService.getPortfolioManagerList())
        .filter((c) => c.FirstName || c.LastName)
        .map((item) => {
          return { id: item.id ?? "", Name: item.FirstName ?? "" + " " + item.LastName ?? "" };
        });
    },
    async loadCommitmentActions() {
      this.commitmentActions = (await new ClientCommitmentApi().clientGetCommitmentActionList()).data
    },
    async loadLineManager(): Promise<void> {
      this.lineManagers = (await LineManagerService.getList())
        .filter((c) => c.FirstName || c.LastName)
        .map((item) => {
          return {
            id: item.id ?? "",
            Name: item.FirstName + " " + item.LastName,
          };
        });
    },
    async loadPublicHolidayZone() {
      this.publicHolidayZones = (
        await ProjectService.getPublicHolidayZoneList()
      ).map((item: any) => {
        return { id: item.Id, Name: item.Title };
      });
    },
    async loadOfficeLocations() {
      const response = await CustomerService.GetOfficeLocations();
      this.officeLocations = this.officeLocations.concat(response);
    },
    async loadOfficeSpaces() {
      this.officeSpaces = await OfficeSpaceService.GetOfficeSpaces();
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails();
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    checkQueryIds(){
      if(this.$route.query.positionId && this.$route.query.positionId.length > 0){
        const project = this.projects.find(item => item.Positions?.find(po => po.Id == this.$route.query.positionId))
        if(project && project.Id){
          const el = (this.$refs[project.Id] as any)[0];
          if(el){
            (el as any).$parent.$el.scrollIntoView({behavior: "smooth"});
            setTimeout(() => {
              (el as any).openPositionMenuFromParent(this.$route.query.positionId);
            }, 500);
            
          }
        }else{
          store.dispatch("ShowErrorMessage", `The PositionId ${this.$route.query.positionId} not exist`)
        }
      }
      if(this.$route.query.commitmentId && this.$route.query.commitmentId.length > 0){
        const project = this.projects.find(item => item.Teammembers?.find(tm => tm.CommitmentId == this.$route.query.commitmentId))
        if(project && project.Id){
          const el = (this.$refs[project.Id] as any)[0];
          if(el){
            (el as any).$parent.$el.scrollIntoView({behavior: "smooth"});
            setTimeout(() => {
              (el as any).openCommitmentMenuFromParent(this.$route.query.commitmentId);
            }, 500);
          }
        }else{
          store.dispatch("ShowErrorMessage", `The CommitmentId ${this.$route.query.commitmentId} not exist`)
        }
      }
    },
    closeProjectPopup() {
      this.showProjectModal = false;
      store.dispatch("setNewProjectPopupStatus", false);
    },
    showNewProject() {
      this.selectedProject = null;
      this.showProjectModal = true;
    },
    async fetchProjects() {
      this.loading = true;
      try {
        const mDate = moment(new Date());
        mDate.format("Z");
        this.projects = (
          await new ClientProjectApi().clientGetProjectsWithDetails({
            startDate: mDate.format("YYYY-MM-DDT00:00:00Z"),
          } as DateRangeRequestModel)
        ).data;
      } finally {
        this.loading = false;
      }
    },
    savedProject(project: any) {
      if (this.selectedProject) {
        this.selectedProject.Name = project.Name;
      } else {
        this.projects.push(project);
      }
      this.showProjectModal = false;
    },
  },
});
</script>
