<template>
  <div class="my-4 search-box">
    <div class="input-label">Description</div>
    <v-textarea class="search-box" bg-color="primary" density="comfortable" variant="outlined" hide-details="auto"
      placeholder="Search" hint="Describe your job role" v-model="description" auto-grow></v-textarea>
    <v-btn :ripple="false" class="search-icon" color="primary" variant="plain" @click="search">
      <v-icon color="secondary" icon="mdi-magnify"></v-icon>
    </v-btn>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    jobDescription: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      description: this.jobDescription,
    };
  },
  methods: {
    search() {
      this.$emit("searchByDescription", this.description);
    }
  }
});
</script>
<style scoped lang="scss">
.search-box {
  display: flex;
  flex-direction: column;
  position: relative;

  .search-icon {
    position: absolute;
    top: 25px;
    right: -10px;
  }
}
</style>