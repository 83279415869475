<template>
  <v-card :disabled="isLoading" :loading="isLoading">
    <div v-if="assessmentDetail" class="assessment-container form-inputs">
      <v-row>
        <div class="favorite-btn">
          <v-tooltip
            location="bottom"
            v-if="assessmentDetail.IsFavorite != true"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                style="min-width: 0"
                variant="text"
                @click="addToFavorite"
              >
                <v-icon> mdi-heart </v-icon>
              </v-btn>
            </template>
            Add to Favorites
          </v-tooltip>
          <v-tooltip
            location="bottom"
            v-if="assessmentDetail.IsFavorite == true"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                style="min-width: 0"
                variant="text"
                @click="addToFavorite"
              >
                <v-icon class="heart_icon"> mdi-heart </v-icon>
              </v-btn>
            </template>
            Remove from Favorites
          </v-tooltip>

          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                style="min-width: 0"
                variant="text"
                @click="editCourse"
              >
                <v-icon> mdi-pen </v-icon>
              </v-btn>
            </template>
            Edit
          </v-tooltip>
        </div>

        <v-col cols="12">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="details-section">
                <div>
                  <h2>{{ assessmentDetail.Title }}</h2>
                </div>

                <div class="f-body-1" v-if="assessmentDetail.Description">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span class="description" v-bind="props">
                        {{ assessmentDetail.Description }}
                      </span>
                    </template>
                    Description
                  </v-tooltip>
                </div>

                <div class="f-body-2" v-if="assessmentDetail.Objective">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-human-male-board</v-icon>
                        {{ assessmentDetail.Objective }}
                      </span>
                    </template>
                    Objective
                  </v-tooltip>
                  -
                  <v-tooltip
                    location="bottom"
                    v-if="assessmentDetail.PassScore"
                  >
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-chart-box</v-icon>
                        {{ assessmentDetail.PassScore }}
                      </span>
                    </template>
                    Pass Score
                  </v-tooltip>
                </div>
                <div
                  class="flex-section f-body-2"
                  v-if="assessmentDetail.Tags.length > 0"
                >
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">
                        <v-icon>mdi-tag</v-icon>
                      </span>
                    </template>
                    Tags
                  </v-tooltip>
                  <v-chip
                    v-for="(tag, index) in assessmentDetail.Tags"
                    :key="index"
                  >
                    {{ tag.Title }}
                  </v-chip>
                </div>
                <p>Questions:</p>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(question, index) of assessmentDetail.Questions"
                    :key="index"
                    class="f-body-2"
                  >
                    <v-expansion-panel-title>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <strong>Question:</strong>
                          {{ truncate(question.Question) }}
                        </v-col>
                        <v-col cols="6">
                          <strong>Technologies: </strong>
                          {{
                            question.Technologies.map(
                              (item: any) => item.Text
                            ).join(", ")
                          }}
                        </v-col>
                      </v-row>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <div class="expanded-section">
                        <v-row class="mb-3">
                          <v-col cols="12">
                            <strong>Question:</strong> {{ question.Question }}
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <v-col cols="12">
                            <strong>Answer:</strong>
                            {{ question.Answer ? question.Answer : "-" }}
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <v-col cols="6">
                            <strong>Technologies:</strong>
                            {{
                              question.Technologies.map(
                                (item: any) => item.Text
                              ).join(", ")
                            }}
                          </v-col>
                          <v-col cols="6">
                            <strong>Editors:</strong>
                            {{
                              question.Editors && question.Editors.length > 0
                                ? question.Editors.map(
                                    (item) => item.Value
                                  ).join(", ")
                                : "-"
                            }}
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <v-col cols="6">
                            <strong>Level:</strong>
                            {{ question.Level ? question.Level : "-" }}
                          </v-col>
                          <v-col cols="6">
                            <strong>Duration:</strong>
                            {{
                              question.Duration
                                ? formatDuration(question.Duration)
                                : "-"
                            }}
                          </v-col>
                        </v-row>
                        <v-row
                          class="mb-3"
                          v-if="
                            question.Evaluations &&
                            question.Evaluations.length > 0
                          "
                        >
                          <v-col cols="12"><strong>Evaluations:</strong></v-col>
                        </v-row>
                        <v-row
                          v-for="(evaluation, index) of question.Evaluations"
                          :key="index"
                          class="evaluation-items"
                        >
                          <v-col cols="6">
                            <strong>Evaluation:</strong>
                            {{ evaluation.Criteria }}
                          </v-col>
                          <v-col cols="6">
                            <strong>Point:</strong> {{ evaluation.Point }}
                          </v-col>
                        </v-row>
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AssessmentModel from "../../../../../ProjectPortal-SharedComponents/src/models/AssessmentModel";
import AssessmentService from "@/services/AssessmentService";

export default defineComponent({
  data() {
    return {
      assessmentDetail: null as AssessmentModel | null,
      showErrorAlert: false,
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    try {
      var assessmentId = this.$route.params.assessmentId as string;
      this.assessmentDetail = await AssessmentService.GetById(assessmentId);
    } catch (error) {
      console.error("Error fetching data:", error);
      this.showErrorAlert = true;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    addToFavorite() {
      if (this.assessmentDetail) {
        this.assessmentDetail.IsFavorite = !this.assessmentDetail.IsFavorite;
      }
    },
    editCourse() {
      if (this.assessmentDetail && this.assessmentDetail?.Id != null) {
        this.$router.push({
          name: "edit-assessment",
          params: { courseId: this.assessmentDetail.Id },
        });
      }
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
    truncate(item: any) {
      if (item && item.length > 50) {
        return item.substring(0, 50) + "...";
      } else {
        return item;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/_sizes.scss";

.assessment-container {
  padding: 20px;

  .details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .flex-section {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .favorite-btn {
    position: absolute;
    right: 5px;
  }
  .heart_icon {
    color: $racecar;
  }
  .expanded-section {
    padding: 20px;

    .evaluation-items {
      margin: 10px;
    }
  }
}
</style>
