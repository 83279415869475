import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-100 w-100" }
const _hoisted_2 = {
  ref: "scene",
  class: "h-100 w-100",
  id: "sceneContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddProject = _resolveComponent("AddProject")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_AddOrEditPositionDetails = _resolveComponent("AddOrEditPositionDetails")!
  const _component_ProjectSearchWizard = _resolveComponent("ProjectSearchWizard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showNewProject,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showNewProject) = $event)),
      persistent: "",
      "max-width": "600px",
      onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.UpdateShowNewProjectStatus(false)), ["esc"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AddProject, {
          addLoading: _ctx.addLoading,
          onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.UpdateShowNewProjectStatus(false))),
          onSuccess: _cache[1] || (_cache[1] = ($event: any) => (_ctx.AddNewProject($event)))
        }, null, 8, ["addLoading"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showNewPosition,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showNewPosition) = $event)),
      persistent: "",
      "max-width": "600px",
      onKeydown: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.UpdateShowNewPositionStatus(false)), ["esc"]))
    }, {
      default: _withCtx(() => [
        (_ctx.showNewPosition)
          ? (_openBlock(), _createBlock(_component_AddOrEditPositionDetails, {
              key: 0,
              isNew: true,
              addLoading: _ctx.addLoading,
              onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.UpdateShowNewPositionStatus(false))),
              onSuccess: _cache[5] || (_cache[5] = ($event: any) => (_ctx.AddNewProjectPosition($event))),
              details: _ctx.details,
              skills: _ctx.skills
            }, null, 8, ["addLoading", "details", "skills"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showUpdatePosition,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showUpdatePosition) = $event)),
      persistent: "",
      "max-width": "600px",
      onKeydown: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.UpdateShowUpdatePositionStatus(false)), ["esc"]))
    }, {
      default: _withCtx(() => [
        (_ctx.showUpdatePosition)
          ? (_openBlock(), _createBlock(_component_AddOrEditPositionDetails, {
              key: 0,
              isNew: false,
              addLoading: _ctx.addLoading,
              onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.UpdateShowUpdatePositionStatus(false))),
              onSuccess: _cache[9] || (_cache[9] = ($event: any) => (_ctx.UpdateProjectPosition($event))),
              details: _ctx.details,
              positionId: _ctx.selectedPositionId,
              projectModel: _ctx.selectedProjectModel,
              skills: _ctx.skills
            }, null, 8, ["addLoading", "details", "positionId", "projectModel", "skills"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.showSearchTeammember)
      ? (_openBlock(), _createBlock(_component_ProjectSearchWizard, {
          key: 0,
          onAssignTmToPosition: _ctx.AssignTmToPosition,
          onUpdatePositionDetail: _ctx.UpdatePositionDetail,
          skills: _ctx.skills,
          details: _ctx.details,
          onOnClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.UpdateShowSearchTeammemberStatus(false))),
          project: _ctx.selectedProjectModel,
          positionId: _ctx.selectedPositionId,
          projectLeads: _ctx.projectLeads,
          publicHolidayZones: _ctx.publicHolidayZones,
          projects: _ctx.projects,
          officeLocations: _ctx.officeLocations,
          onSaveLocation: _ctx.saveOfficeLocation,
          dialogLoading: _ctx.dialogLoading,
          lineManagers: _ctx.lineManagers,
          officeSpaces: _ctx.officeSpaces
        }, null, 8, ["onAssignTmToPosition", "onUpdatePositionDetail", "skills", "details", "project", "positionId", "projectLeads", "publicHolidayZones", "projects", "officeLocations", "onSaveLocation", "dialogLoading", "lineManagers", "officeSpaces"]))
      : _createCommentVNode("", true)
  ]))
}