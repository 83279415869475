import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "edit-image-container" }
const _hoisted_2 = { class: "repeater" }
const _hoisted_3 = { class: "order-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_editor = _resolveComponent("editor")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_AddOrEditAssessment = _resolveComponent("AddOrEditAssessment")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "card",
    disabled: _ctx.isLoading,
    loading: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "form-inputs" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmMain",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        clearable: "",
                        label: "Title",
                        "prepend-icon": "mdi-rename",
                        modelValue: _ctx.courseModel.Title,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.courseModel.Title) = $event)),
                        rules: [_ctx.rules.required]
                      }, null, 8, ["modelValue", "rules"]),
                      _createVNode(_component_TextAreaField, {
                        clearable: "",
                        label: "Summary",
                        "prepend-icon": "mdi-pencil",
                        rows: "5",
                        modelValue: _ctx.courseModel.Summary,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.courseModel.Summary) = $event)),
                        rules: [_ctx.rules.required]
                      }, null, 8, ["modelValue", "rules"]),
                      _createVNode(_component_ComboBoxField, {
                        clearable: "",
                        chips: "",
                        multiple: "",
                        label: "Dependecy",
                        items: _ctx.getDependencies,
                        "item-title": "Title",
                        "item-value": "Id",
                        "closable-chips": "",
                        "prepend-icon": "mdi-link",
                        modelValue: _ctx.SelectedDependecy,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.SelectedDependecy) = $event)),
                        "return-object": ""
                      }, null, 8, ["items", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          (
                  (_ctx.selectedImagePreview != '' &&
                    _ctx.selectedImagePreview != null) ||
                  (_ctx.courseModel.ImageDownloadUrl != null &&
                    _ctx.courseModel.ImageDownloadUrl != undefined)
                )
                            ? (_openBlock(), _createBlock(_component_v_col, {
                                key: 0,
                                cols: "12"
                              }, {
                                default: _withCtx(() => [
                                  _cache[16] || (_cache[16] = _createElementVNode("label", {
                                    class: "input-label",
                                    style: { paddingLeft: '42px' }
                                  }, "Image", -1)),
                                  _createElementVNode("div", _hoisted_1, [
                                    _createVNode(_component_v_img, {
                                      src: 
                      _ctx.selectedImagePreview
                        ? _ctx.selectedImagePreview
                        : _ctx.courseModel.ImageDownloadUrl
                    ,
                                      class: "course-image",
                                      "aspect-ratio": 1,
                                      cover: ""
                                    }, null, 8, ["src"]),
                                    _createVNode(_component_v_file_input, {
                                      modelValue: _ctx.selectedImage,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedImage) = $event)),
                                      onChange: _ctx.onImageSelect,
                                      "hide-input": "",
                                      "prepend-icon": "mdi-file-replace",
                                      class: "course-image-replace",
                                      accept: ".jpg,.jpeg"
                                    }, null, 8, ["modelValue", "onChange"])
                                  ])
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_v_col, {
                                key: 1,
                                cols: "12"
                              }, {
                                default: _withCtx(() => [
                                  _cache[17] || (_cache[17] = _createElementVNode("label", {
                                    class: "input-label",
                                    style: { paddingLeft: '42px' }
                                  }, "Image", -1)),
                                  _createVNode(_component_v_file_input, {
                                    modelValue: _ctx.selectedImage,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedImage) = $event)),
                                    onChange: _ctx.onImageSelect,
                                    "prepend-icon": "mdi-camera",
                                    rules: [_ctx.rules.Required],
                                    accept: ".jpg,.jpeg"
                                  }, null, 8, ["modelValue", "onChange", "rules"])
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_TextField, {
                                clearable: "",
                                label: "Author",
                                "prepend-icon": "mdi-clock-edit-outline",
                                modelValue: _ctx.courseModel.Author,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.courseModel.Author) = $event)),
                                rules: [_ctx.rules.required]
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_TextField, {
                                label: "Duration/mins",
                                "prepend-icon": "mdi-clock-edit-outline",
                                modelValue: _ctx.courseModel.Duration,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.courseModel.Duration) = $event)),
                                type: "number",
                                rules: [_ctx.rules.required]
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_SelectField, {
                                label: "Level",
                                items: _ctx.levels,
                                modelValue: _ctx.courseModel.Level,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.courseModel.Level) = $event)),
                                "prepend-icon": "mdi-clock-edit-outline",
                                rules: [_ctx.rules.required]
                              }, null, 8, ["items", "modelValue", "rules"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "mt-0 mb-0" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AutoCompleteField, {
                                clearable: "",
                                chips: "",
                                label: "Skill",
                                items: _ctx.skills,
                                "item-title": "Text",
                                "item-value": "id",
                                multiple: "",
                                "closable-chips": "",
                                "prepend-icon": "mdi-clock-edit-outline",
                                modelValue: _ctx.courseModel.Skills,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.courseModel.Skills) = $event)),
                                rules: [_ctx.rules.required, _ctx.rules.arrayMinLength(1)]
                              }, null, 8, ["items", "modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ComboBoxField, {
                                clearable: "",
                                chips: "",
                                multiple: "",
                                label: "Tag",
                                items: _ctx.tags,
                                "item-title": "Title",
                                "item-value": "Title",
                                "closable-chips": "",
                                "prepend-icon": "mdi-clock-edit-outline",
                                modelValue: _ctx.SelectedTags,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.SelectedTags) = $event)),
                                "return-object": false
                              }, null, 8, ["items", "modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextAreaField, {
                        clearable: "",
                        label: "Description",
                        "prepend-icon": "mdi-pencil",
                        modelValue: _ctx.courseModel.Description,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.courseModel.Description) = $event)),
                        rules: [_ctx.rules.required]
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { class: "divider" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_v_btn, {
                      class: "secondary_btn",
                      "prepend-icon": "mdi-plus",
                      onClick: _ctx.AddLesson
                    }, {
                      default: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode("Add Lesson")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_btn, {
                      class: "secondary_btn ml-3",
                      "prepend-icon": "mdi-plus",
                      onClick: _ctx.AddAssessment
                    }, {
                      default: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode("Add Assessment")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _createVNode(_component_v_divider, {
                    class: "divider-line",
                    thickness: 3
                  })
                ]),
                _: 1
              }),
              (_ctx.steps.length > 0)
                ? (_openBlock(), _createBlock(_component_draggable, {
                    key: 0,
                    list: _ctx.steps,
                    "item-key": "Id",
                    handle: ".handle-btn",
                    onStart: _cache[11] || (_cache[11] = ($event: any) => (_ctx.dragging = true)),
                    onEnd: _cache[12] || (_cache[12] = ($event: any) => (_ctx.dragging = false))
                  }, {
                    item: _withCtx(({ element, index }) => [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                          (index != 0 && !_ctx.dragging)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 0,
                                onClick: ($event: any) => (_ctx.RepeaterMoveUp(index))
                              }, {
                                default: _withCtx(() => _cache[20] || (_cache[20] = [
                                  _createTextVNode("mdi-arrow-up")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true),
                          (_ctx.steps.length > 1 && !_ctx.dragging)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 1,
                                onClick: ($event: any) => (_ctx.RepeaterDelete(index))
                              }, {
                                default: _withCtx(() => _cache[21] || (_cache[21] = [
                                  _createTextVNode("mdi-delete")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true),
                          (_ctx.steps.length > 1 && !_ctx.dragging)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 2,
                                class: "handle-btn"
                              }, {
                                default: _withCtx(() => _cache[22] || (_cache[22] = [
                                  _createTextVNode("mdi-drag")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (index != _ctx.steps.length - 1 && !_ctx.dragging)
                            ? (_openBlock(), _createBlock(_component_v_icon, {
                                key: 3,
                                onClick: ($event: any) => (_ctx.RepeaterMoveDown(index))
                              }, {
                                default: _withCtx(() => _cache[23] || (_cache[23] = [
                                  _createTextVNode("mdi-arrow-down")
                                ])),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _createVNode(_component_v_card, {
                          disabled: _ctx.dragging,
                          variant: "tonal",
                          class: "lesson-card"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                (element.Type == 'Lesson')
                                  ? (_openBlock(), _createBlock(_component_v_row, {
                                      key: 0,
                                      class: "align-center"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "6" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_TextField, {
                                              label: "Title",
                                              "prepend-icon": "mdi-book-open-variant",
                                              modelValue: element.Title,
                                              "onUpdate:modelValue": ($event: any) => ((element.Title) = $event),
                                              rules: [_ctx.rules.required]
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_col, { cols: "4" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_TextField, {
                                              label: "Duraton/mins",
                                              "prepend-icon": "mdi-clock-edit-outline",
                                              type: "number",
                                              "single-line": "",
                                              modelValue: element.Duration,
                                              "onUpdate:modelValue": ($event: any) => ((element.Duration) = $event),
                                              rules: [_ctx.rules.required]
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_col, { cols: "2" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_checkbox, {
                                              label: "Show Editor",
                                              modelValue: element.ShowEditor,
                                              "onUpdate:modelValue": ($event: any) => ((element.ShowEditor) = $event),
                                              "hide-details": ""
                                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_editor, {
                                              modelValue: element.Content,
                                              "onUpdate:modelValue": ($event: any) => ((element.Content) = $event)
                                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                            _createVNode(_component_v_divider, { thickness: 2 })
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                (element.Type == 'Assessment')
                                  ? (_openBlock(), _createBlock(_component_v_row, {
                                      key: 1,
                                      class: "align-center"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "6" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_AutoCompleteField, {
                                              modelValue: element.AssessmentDetails.Id,
                                              "onUpdate:modelValue": ($event: any) => ((element.AssessmentDetails.Id) = $event),
                                              items: _ctx.assessments,
                                              loading: _ctx.isLoading,
                                              "item-title": "Title",
                                              "item-value": "Id",
                                              label: "Select an Assessment",
                                              placeholder: "Start typing to Search",
                                              rules: [_ctx.rules.required]
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "loading", "rules"])
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_col, { cols: "6" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_btn, { onClick: _ctx.AddNewAssessment }, {
                                              default: _withCtx(() => _cache[24] || (_cache[24] = [
                                                _createTextVNode("Add New Assessment")
                                              ])),
                                              _: 1
                                            }, 8, ["onClick"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["disabled"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["list"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                class: "action-btns"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "secondary_btn",
                    onClick: _ctx.cancel
                  }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode("Cancel")
                    ])),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    class: "primary_btn_v2",
                    onClick: _ctx.saveCourse
                  }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode("Save")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showDeleteDialog,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.showDeleteDialog) = $event)),
        "max-width": "500px",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "text-h5" }, {
                default: _withCtx(() => _cache[27] || (_cache[27] = [
                  _createTextVNode("Are you sure you want to delete this item?")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "action-btns"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            class: "secondary_btn",
                            onClick: _ctx.cancelDeletePopup
                          }, {
                            default: _withCtx(() => _cache[28] || (_cache[28] = [
                              _createTextVNode("Cancel")
                            ])),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_v_btn, {
                            class: "primary_btn_v2",
                            onClick: _ctx.deleteStepItem
                          }, {
                            default: _withCtx(() => _cache[29] || (_cache[29] = [
                              _createTextVNode("Delete")
                            ])),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showAddAssessmentDialog,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showAddAssessmentDialog) = $event)),
        persistent: "",
        fullscreen: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AddOrEditAssessment, {
            onAssessmentSaved: _ctx.assessmentSaved,
            onAssessmentClosed: _ctx.assessmentClosed,
            isPopup: true
          }, null, 8, ["onAssessmentSaved", "onAssessmentClosed"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["disabled", "loading"]))
}