import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "course-container"
}
const _hoisted_2 = { class: "favorite-btn" }
const _hoisted_3 = { class: "f-body-1" }
const _hoisted_4 = { class: "f-body-2" }
const _hoisted_5 = { class: "f-body-2" }
const _hoisted_6 = {
  key: 0,
  class: "flex-section f-body-2"
}
const _hoisted_7 = {
  key: 1,
  class: "flex-section f-body-2"
}
const _hoisted_8 = { class: "f-body-2" }
const _hoisted_9 = {
  key: 2,
  class: "f-body-2"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "f-body-1" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    disabled: _ctx.isLoading,
    loading: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      (_ctx.courseDetail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.courseDetail.IsFavorite != true)
                    ? (_openBlock(), _createBlock(_component_v_tooltip, {
                        key: 0,
                        location: "bottom"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps(props, {
                            style: {"min-width":"0"},
                            variant: "text",
                            onClick: _ctx.addToFavorite
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                  _createTextVNode(" mdi-heart ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _cache[2] || (_cache[2] = _createTextVNode(" Add to Favorites "))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.courseDetail.IsFavorite == true)
                    ? (_openBlock(), _createBlock(_component_v_tooltip, {
                        key: 1,
                        location: "bottom"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps(props, {
                            style: {"min-width":"0"},
                            variant: "text",
                            onClick: _ctx.addToFavorite
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { class: "heart_icon" }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                  _createTextVNode(" mdi-heart ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _cache[4] || (_cache[4] = _createTextVNode(" Remove from Favorites "))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps(props, {
                        style: {"min-width":"0"},
                        variant: "text",
                        onClick: _ctx.editCourse
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode(" mdi-pen ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040, ["onClick"])
                    ]),
                    default: _withCtx(() => [
                      _cache[6] || (_cache[6] = _createTextVNode(" Edit "))
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              lg: "5",
                              md: "5",
                              sm: "6",
                              class: "d-flex"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_img, {
                                  src: _ctx.courseDetail.ImageDownloadUrl,
                                  width: 300,
                                  cover: "",
                                  "aspect-ratio": "16/9",
                                  class: "course-image"
                                }, null, 8, ["src"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              lg: "7",
                              md: "7",
                              sm: "6",
                              class: "details-section"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("h2", null, _toDisplayString(_ctx.courseDetail.Title), 1)
                                ]),
                                _createElementVNode("div", _hoisted_3, [
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _mergeProps({ class: "description" }, props), _toDisplayString(_ctx.courseDetail.Summary), 17)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[7] || (_cache[7] = _createTextVNode(" Summary "))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _createElementVNode("div", _hoisted_4, [
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                                            _createTextVNode("mdi-human-male-board")
                                          ])),
                                          _: 1
                                        }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.courseDetail.Author), 1)
                                      ], 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[9] || (_cache[9] = _createTextVNode(" Author "))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _createElementVNode("div", _hoisted_5, [
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                                            _createTextVNode("mdi-timer-sand")
                                          ])),
                                          _: 1
                                        }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.formatDuration(_ctx.courseDetail.Duration)), 1)
                                      ], 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[11] || (_cache[11] = _createTextVNode(" Duration "))
                                    ]),
                                    _: 1
                                  }),
                                  _cache[15] || (_cache[15] = _createTextVNode(" - ")),
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                                            _createTextVNode("mdi-bulletin-board")
                                          ])),
                                          _: 1
                                        }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.courseDetail.Steps.length) + " Step ", 1)
                                      ], 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _createTextVNode(" " + _toDisplayString(_ctx.courseDetail.Steps.filter(
                        (item) => item.Type == "Lesson"
                      ).length) + " Lesson and " + _toDisplayString(_ctx.courseDetail.Steps.filter(
                        (item) => item.Type == "Assessment"
                      ).length) + " Assessment ", 1)
                                    ]),
                                    _: 1
                                  }),
                                  _cache[16] || (_cache[16] = _createTextVNode(" - ")),
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLevelStarsCount(_ctx.courseDetail.Level), (i) => {
                                          return (_openBlock(), _createBlock(_component_v_icon, null, {
                                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                                              _createTextVNode("mdi-star")
                                            ])),
                                            _: 1
                                          }))
                                        }), 256)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.courseDetail.Level), 1)
                                      ], 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[14] || (_cache[14] = _createTextVNode(" Level "))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                (_ctx.courseDetail.Tags.length > 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                      _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                        activator: _withCtx(({ props }) => [
                                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[17] || (_cache[17] = [
                                                _createTextVNode("mdi-tag")
                                              ])),
                                              _: 1
                                            })
                                          ], 16)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[18] || (_cache[18] = _createTextVNode(" Tags "))
                                        ]),
                                        _: 1
                                      }),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseDetail.Tags, (tag, index) => {
                                        return (_openBlock(), _createBlock(_component_v_chip, { key: index }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(tag.Title), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.courseDetail.Skills.length > 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                      _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                        activator: _withCtx(({ props }) => [
                                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[19] || (_cache[19] = [
                                                _createTextVNode("mdi-shape")
                                              ])),
                                              _: 1
                                            })
                                          ], 16)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[20] || (_cache[20] = _createTextVNode(" Skill "))
                                        ]),
                                        _: 1
                                      }),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseDetail.Skills, (skill, index) => {
                                        return (_openBlock(), _createBlock(_component_v_chip, { key: index }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getSkillName(skill)), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_8, [
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                                            _createTextVNode("mdi-update")
                                          ])),
                                          _: 1
                                        }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.courseDetail.UpdatedAt), 1)
                                      ], 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[22] || (_cache[22] = _createTextVNode(" Last Update "))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                (_ctx.courseDetail.Dependencies.length > 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                      _createVNode(_component_v_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_col, { cols: "12" }, {
                                            default: _withCtx(() => _cache[23] || (_cache[23] = [
                                              _createTextVNode(" Dependencies: ")
                                            ])),
                                            _: 1
                                          }),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseDetail.Dependencies, (dependency, index) => {
                                            return (_openBlock(), _createBlock(_component_v_col, {
                                              cols: "12",
                                              key: index
                                            }, {
                                              default: _withCtx(() => [
                                                (dependency.DependencyId)
                                                  ? (_openBlock(), _createElementBlock("a", {
                                                      key: 0,
                                                      href: dependency.DependencyId,
                                                      target: "_blank",
                                                      class: "dependency-link"
                                                    }, _toDisplayString(dependency.Text), 9, _hoisted_10))
                                                  : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(dependency.Text), 1))
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 1
                                      })
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_12, [
                                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                    activator: _withCtx(({ props }) => [
                                      _createElementVNode("span", _mergeProps({ class: "description" }, props), _toDisplayString(_ctx.courseDetail.Description), 17)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[24] || (_cache[24] = _createTextVNode(" Description "))
                                    ]),
                                    _: 1
                                  })
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => _cache[25] || (_cache[25] = [
                                _createElementVNode("strong", null, "Steps: ", -1)
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_expansion_panels, { variant: "accordion" }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courseDetail.Steps, (step, index) => {
                                      return (_openBlock(), _createBlock(_component_v_expansion_panel, { key: index }, {
                                        default: _withCtx(() => [
                                          (step.Type == 'Lesson')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                                _createVNode(_component_v_expansion_panel_title, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_row, { "no-gutters": "" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_col, { cols: "6" }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(step.Title), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        _createVNode(_component_v_col, { cols: "6" }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(" Duration: " + _toDisplayString(_ctx.formatDuration(step.Duration)), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_expansion_panel_text, null, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", {
                                                      innerHTML: step.Content
                                                    }, null, 8, _hoisted_14)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]))
                                            : _createCommentVNode("", true),
                                          (step.Type == 'Assessment')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                                _createVNode(_component_v_expansion_panel_title, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_row, {
                                                      "no-gutters": "",
                                                      class: "align-center"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_col, { cols: "6" }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(step.AssessmentDetails?.Title), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        _createVNode(_component_v_col, { cols: "4" }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(" Duration: " + _toDisplayString(_ctx.formatDuration(
                                step.AssessmentDetails?.Questions.reduce(
                                  (total, item) => {
                                    return total + item.Duration;
                                  },
                                  0
                                )
                              )), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        _createVNode(_component_v_col, { cols: "2" }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_btn, {
                                                              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                                                              class: "secondary_btn"
                                                            }, {
                                                              default: _withCtx(() => _cache[26] || (_cache[26] = [
                                                                _createTextVNode("Preview Assessment")
                                                              ])),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_expansion_panel_text, null, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", null, [
                                                      _createVNode(_component_v_row, null, {
                                                        default: _withCtx(() => [
                                                          (step.AssessmentDetails?.Description)
                                                            ? (_openBlock(), _createBlock(_component_v_col, {
                                                                key: 0,
                                                                cols: "12"
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _cache[27] || (_cache[27] = _createElementVNode("strong", null, "Description: ", -1)),
                                                                  _createTextVNode(" " + _toDisplayString(step.AssessmentDetails?.Description), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1024))
                                                            : _createCommentVNode("", true),
                                                          (step.AssessmentDetails?.Questions)
                                                            ? (_openBlock(), _createBlock(_component_v_col, {
                                                                key: 1,
                                                                cols: "12"
                                                              }, {
                                                                default: _withCtx(() => _cache[28] || (_cache[28] = [
                                                                  _createElementVNode("strong", null, "Questions: ", -1)
                                                                ])),
                                                                _: 1
                                                              }))
                                                            : _createCommentVNode("", true),
                                                          (step.AssessmentDetails?.Questions)
                                                            ? (_openBlock(), _createBlock(_component_v_col, {
                                                                key: 2,
                                                                class: "ml-5",
                                                                cols: "12"
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(step
                                  .AssessmentDetails?.Questions, (question, index) => {
                                                                    return (_openBlock(), _createBlock(_component_v_row, { key: index }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_v_col, { cols: "6" }, {
                                                                          default: _withCtx(() => [
                                                                            _createTextVNode(_toDisplayString(_ctx.truncate(question.Question)), 1)
                                                                          ]),
                                                                          _: 2
                                                                        }, 1024),
                                                                        _createVNode(_component_v_col, { cols: "4" }, {
                                                                          default: _withCtx(() => [
                                                                            _cache[29] || (_cache[29] = _createElementVNode("strong", null, "Technologies: ", -1)),
                                                                            _createTextVNode(" " + _toDisplayString(_ctx.getAssessmentTechnologies(question)), 1)
                                                                          ]),
                                                                          _: 2
                                                                        }, 1024),
                                                                        (step.AssessmentDetails?.Questions)
                                                                          ? (_openBlock(), _createBlock(_component_v_col, {
                                                                              key: 0,
                                                                              cols: "2"
                                                                            }, {
                                                                              default: _withCtx(() => [
                                                                                _cache[30] || (_cache[30] = _createElementVNode("strong", null, "Level: ", -1)),
                                                                                _createTextVNode(" " + _toDisplayString(question.Level), 1)
                                                                              ]),
                                                                              _: 2
                                                                            }, 1024))
                                                                          : _createCommentVNode("", true)
                                                                      ]),
                                                                      _: 2
                                                                    }, 1024))
                                                                  }), 128))
                                                                ]),
                                                                _: 2
                                                              }, 1024))
                                                            : _createCommentVNode("", true)
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["disabled", "loading"]))
}