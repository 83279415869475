<template>
  <v-card class="course-card">
    <v-row>
      <v-col cols="12" sm="5" lg="4">
        <v-img
          @click="showCourseDetail(course.Id)"
          :src="course.ImageDownloadUrl"
          :aspect-ratio="1"
          cover
          class="course_image"
        ></v-img>
      </v-col>
      <v-col cols="12" sm="7" lg="8">
        <div class="course-details">
          <span class="title" @click="showCourseDetail(course.Id)">
            {{ course.Title }}</span
          >
          <div>
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span class="description" v-bind="props">
                  {{ course.Summary }}
                </span>
              </template>
              Summary
            </v-tooltip>
          </div>

          <div>
            <v-tooltip location="right">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-human-male-board</v-icon>
                  {{ course.Author }}
                </span>
              </template>
              Author
            </v-tooltip>
          </div>
          <div class="tag-section" v-if="course.Skills.length > 0">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-shape</v-icon>
                </span>
              </template>
              Skill
            </v-tooltip>
            <span v-for="(skill, index) in course.Skills" :key="index">
              {{ getSkillName(skill)
              }}{{ index != course.Skills.length - 1 ? "," : "" }}
            </span>
          </div>
          <div>
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-timer-sand</v-icon>
                  {{ formatDuration(course.Duration) }}
                </span>
              </template>
              Duration
            </v-tooltip>
            -
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-bulletin-board</v-icon>
                  {{ course.Steps.length }} Step
                </span>
              </template>
              {{
                course.Steps.filter((item: any) => item.Type == "Lesson").length
              }}
              Lesson and
              {{
                course.Steps.filter((item: any) => item.Type == "Assessment")
                  .length
              }}
              Assessment
            </v-tooltip>
          </div>

          <div>
            <v-tooltip location="right">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon v-for="i in getLevelStarsCount(course.Level)"
                    >mdi-star</v-icon
                  >
                  {{ course.Level }}
                </span>
              </template>
              Level
            </v-tooltip>
          </div>

          <div class="tag-section" v-if="course.Tags.length > 0">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-tag</v-icon>
                </span>
              </template>
              Tags
            </v-tooltip>
            <v-chip link v-for="(tag, index) in course.Tags" :key="index">
              {{ tag.Title }}
            </v-chip>
          </div>

          <div>
            <v-btn
              v-if="showAddToRoadMap"
              @click="addToRoadMap(course)"
              :disabled="stepIds.includes(course.Id)"
            >
              Add to roadmap
            </v-btn>
          </div>
          <div class="favorite-btn">
            <v-tooltip location="bottom" v-if="course.IsFavorite != true">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  style="min-width: 0"
                  variant="text"
                  @click="addToFavorite"
                >
                  <v-icon> mdi-heart </v-icon>
                </v-btn>
              </template>
              Add to Favorites
            </v-tooltip>
            <v-tooltip location="bottom" v-if="course.IsFavorite == true">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  style="min-width: 0"
                  variant="text"
                  @click="addToFavorite"
                >
                  <v-icon class="heart_icon"> mdi-heart </v-icon>
                </v-btn>
              </template>
              Remove from Favorites
            </v-tooltip>

            <v-tooltip location="bottom" v-if="!showAddToRoadMap">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  style="min-width: 0"
                  variant="text"
                  @click="editCourse(course.Id)"
                >
                  <v-icon> mdi-pen </v-icon>
                </v-btn>
              </template>
              Edit
            </v-tooltip>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CourseModel from "../../../../../ProjectPortal-SharedComponents/src/models/CourseModel";
import CoreSkill from "shared-components/src/models/CoreSkill";

export default defineComponent({
  props: {
    course: {
      type: Object as () => CourseModel,
      required: true,
    },
    showAddToRoadMap: {
      type: Boolean,
      default: false,
    },
    skills: {
      type: Array as () => CoreSkill[],
      default: [],
    },
    stepIds: {
      type: Array as () => String[],
      default: [],
    },
  },
  methods: {
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
    showCourseDetail(courseId: any) {
      this.$router.push({
        name: "course-detail",
        params: { courseId: courseId },
      });
    },
    addToRoadMap(course: CourseModel) {
      this.$emit("AddCourseToRoadMap", {
        Id: course.Id,
        Title: course.Title,
        Type: "Course",
      });
    },
    addToFavorite() {
      this.course.IsFavorite = !this.course.IsFavorite;
    },
    editCourse(courseId: string | undefined) {
      if (courseId) {
        this.$router.push({ name: "edit-course", params: { courseId } });
      }
    },
    getLevelStarsCount(level: string) {
      switch (level) {
        case "Junior":
          return 1;
        case "MidLevel":
          return 2;
        case "Senior":
          return 3;
      }
    },
    getSkillName(id: string) {
      return this.skills.filter((item: any) => item.id == id)[0].Text;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/_sizes.scss";

.course-card {
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid $light_gray;

  .course_image {
    max-height: 265px;
    cursor: pointer;
    border-radius: 5px;
  }

  .course-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    position: relative;

    .heart_icon {
      color: $racecar;
    }

    .title {
      font-size: $size-m;
      font-weight: 500;
      cursor: pointer;
    }

    .description {
      text-justify: auto;
      font-size: $size-s;
    }

    .favorite-btn {
      position: absolute;
      right: 0;
    }

    .tag-section {
      display: flex;
      gap: 5px;
    }
  }
}
</style>
