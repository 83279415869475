import Commitment from "shared-components/src/models/Commitment";
import Utils from "../utils/Utils";
import ApiService from "./ApiService";
import {
  ClientCreateVMRequest,
  ClientGetCommitmentByIdResponseModel,
  CommitmentModel,
} from "shared-components/src/services/openApi/api";

export default class CommitmentService {
  public static async createCommitment(model: Commitment): Promise<Commitment> {
    model.StartDate = Utils.addTimezoneOffsetToStartDate(model.StartDate);
    model.EndDate = Utils.addTimezoneOffsetToEndDate(model.EndDate);
    return new Promise((resolve, reject) => {
      ApiService.post("/client/commitment", model)
        .then((result) => {
          if (result.data) {
            model.id = result.data;
            resolve(model);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async createVmAndCommitment(
    model: ClientCreateVMRequest
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post("/client/vm", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }
}
