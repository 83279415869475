import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "login-form mt-5 pb-5" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "actions text-center" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "headline text-center mb-5" }
const _hoisted_6 = {
  key: 0,
  class: "error-text"
}
const _hoisted_7 = {
  key: 1,
  class: "info-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_flex = _resolveComponent("v-flex")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.emailStatus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_v_flex, {
                xs12: "",
                class: "text-left"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, {
                    ref: "frmMain",
                    modelValue: _ctx.valid,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valid) = $event)),
                    "lazy-validation": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextField, {
                        rules: [_ctx.rules.required, _ctx.rules.EmailCheck],
                        "prepend-icon": "mdi-account",
                        modelValue: _ctx.email,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                        label: "Email"
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_v_btn, {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendLink())),
                  loading: _ctx.isLoading,
                  color: "accent"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Send me a magic link")
                  ])),
                  _: 1
                }, 8, ["loading"])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, [
                _cache[5] || (_cache[5] = _createTextVNode("We have sent your login link to ")),
                _createElementVNode("strong", null, _toDisplayString(_ctx.email), 1),
                _cache[6] || (_cache[6] = _createTextVNode(". Please follow the link in the email to login to your account"))
              ]),
              (!_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    _cache[7] || (_cache[7] = _createTextVNode("Having trouble receiving this email? ")),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.reportLoginIssue()), ["prevent"]))
                    }, "click here to contact admin")
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Sending login issue report ..."))
                : _createCommentVNode("", true)
            ]))
      ])
    ]),
    _: 1
  }))
}