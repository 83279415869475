import ApiService from "./ApiService";
import ListResult from "shared-components/src/viewModels/response/ListResult";
import NotificationItem from "shared-components/src/models/NotificationItem";
import Notification, {
  ClientTimesheetReactionRequest,
} from "shared-components/src/models/Notification";
import Utils from "@/Helpers/Utils";

export default class NotificationService {
  public static async ClientTimesheetReaction(
    model: ClientTimesheetReactionRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/notification/client/TimesheetReaction`, model)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  }

  public static async getNotificationItems(
    pageNumber: number = 0
  ): Promise<ListResult<NotificationItem>> {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `/notification/client/getItemsByUserEmail?pageNumber=${pageNumber}`,
        ""
      )
        .then((result) => {
          if (!result.data && result.data.data) {
            resolve({
              data: [],
              currentPage: 0,
              pageSize: 0,
              totalCount: 0,
              totalPages: 0,
            } as ListResult<NotificationItem>);
          } else {
            const items = result.data.data.map((doc: any) => {
              const item = doc;
              if (doc.CreationDate) {
                item.CreationDate = Utils.vsDateToDatetime(doc.CreationDate);
              }
              if (doc.SeenDate) {
                item.SeenDate = Utils.vsDateToDatetime(doc.SeenDate);
              }
              const retVal = { ...item } as NotificationItem;
              return retVal;
            });
            result.data.data = items;
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async getNotSeenNotificationItemsCount(): Promise<number> {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `/notification/client/getNoneSeenNotificationItemsByUserEmail`,
        ""
      )
        .then((result) => {
          resolve(result.data);
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async set(model: any): Promise<Notification> {
    return new Promise((resolve, reject) => {
      ApiService.post("/notification/client", model)
        .then((result) => {
          if (result.data) {
            model.id = result.data;
            resolve(model);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async update(
    id: string,
    status: string,
    readDate: Date
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post("/notification/client/seen", { id, status, readDate })
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async reply(
    notificationItemId: string,
    text: string,
    sentByCreator: boolean
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.post("/notification/client/reply", {
        notificationItemId,
        text,
        sentByCreator,
      })
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async makeSeenReply(
    notificationItemId: string,
    seenByCreator: boolean
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `/notification/client/${notificationItemId}/reply/seen?seenByCreator=${seenByCreator}`,
        ""
      )
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async withdrawNotificationItem(
    notificationItemId: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/notification/client/${notificationItemId}/withdraw`, "")
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }
}
