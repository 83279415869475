import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu-action-btns" }
const _hoisted_2 = { class: "menu-action-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_AddOrEditPositionDetails = _resolveComponent("AddOrEditPositionDetails")!
  const _component_ProjectSearchWizard = _resolveComponent("ProjectSearchWizard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showPositionMenuDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showPositionMenuDialog) = $event)),
      persistent: "",
      "max-width": "1000px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          class: "pa-3",
          loading: _ctx.dialogLoading,
          disabled: _ctx.dialogLoading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "text-center"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getJobRoleName), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_icon, {
                      class: "close-btn",
                      onClick: _ctx.closeDialog
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("mdi-close")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, { variant: "tonal" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "pa-2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "11" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Job Title: " + _toDisplayString(_ctx.getJobTitleNames), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Skills: " + _toDisplayString(_ctx.getSkillNames), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "4" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Number of Seats: " + _toDisplayString(_ctx.getNumberOfSeats), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "4" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Start Date: " + _toDisplayString(_ctx.getStartDate), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "4" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Seats Unfilled: " + _toDisplayString(_ctx.getUnfilledSeats()), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, {
                                  cols: "1",
                                  class: "d-flex justify-center align-center"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, {
                                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showUpdatePosition = true))
                                    }, {
                                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                                        _createTextVNode("mdi-pen")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, { variant: "tonal" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "pa-2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          class: "text-center"
                                        }, {
                                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                                            _createTextVNode(" Actions ")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createElementVNode("div", _hoisted_1, [
                                      _createVNode(_component_v_btn, {
                                        class: "menu-btn",
                                        "prepend-icon": "mdi-content-copy"
                                      }, {
                                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                                          _createTextVNode("Clone")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_btn, {
                                        class: "menu-btn",
                                        "prepend-icon": "mdi-archive"
                                      }, {
                                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                                          _createTextVNode("Archive")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_btn, {
                                        disabled: !_ctx.canAssignTeammember(),
                                        class: "menu-btn",
                                        "prepend-icon": "mdi-magnify",
                                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSearchTeammember = true))
                                      }, {
                                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                                          _createTextVNode("Assign Teammember")
                                        ])),
                                        _: 1
                                      }, 8, ["disabled"])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, { variant: "tonal" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { class: "pa-2" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          class: "text-center"
                                        }, {
                                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                                            _createTextVNode(" Status ")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createElementVNode("div", _hoisted_2, [
                                      _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                                          _createTextVNode("174 Impressions")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                                          _createTextVNode("57 Assessments")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                                          _createTextVNode("23 Interviews")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_btn, { class: "menu-btn" }, {
                                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                                          _createTextVNode("5 Shortlisted")
                                        ])),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading", "disabled"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showUpdatePosition,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showUpdatePosition) = $event)),
      persistent: "",
      "max-width": "600px",
      onKeydown: _cache[6] || (_cache[6] = _withKeys(($event: any) => (_ctx.showUpdatePosition = false), ["esc"]))
    }, {
      default: _withCtx(() => [
        (_ctx.showUpdatePosition)
          ? (_openBlock(), _createBlock(_component_AddOrEditPositionDetails, {
              key: 0,
              isNew: false,
              addLoading: _ctx.addLoading,
              onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showUpdatePosition = false)),
              onSuccess: _cache[4] || (_cache[4] = ($event: any) => (_ctx.UpdateProjectPosition($event))),
              details: _ctx.details,
              position: _ctx.selectedPosition,
              skills: _ctx.skills
            }, null, 8, ["addLoading", "details", "position", "skills"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.showSearchTeammember)
      ? (_openBlock(), _createBlock(_component_ProjectSearchWizard, {
          key: 0,
          onAssignTmToPosition: _ctx.AssignTmToPosition,
          onUpdatePositionDetail: _ctx.UpdatePositionDetail,
          skills: _ctx.skills,
          details: _ctx.details,
          onOnClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showSearchTeammember = false)),
          project: _ctx.project,
          position: _ctx.selectedPosition,
          projectLeads: _ctx.projectLeads,
          portfolioManagers: _ctx.portfolioManagers,
          publicHolidayZones: _ctx.publicHolidayZones,
          projects: _ctx.projectList,
          officeLocations: _ctx.officeLocations,
          onSaveLocation: _ctx.saveOfficeLocation,
          dialogLoading: _ctx.dialogLoading,
          lineManagers: _ctx.lineManagers,
          officeSpaces: _ctx.officeSpaces
        }, null, 8, ["onAssignTmToPosition", "onUpdatePositionDetail", "skills", "details", "project", "position", "projectLeads", "portfolioManagers", "publicHolidayZones", "projects", "officeLocations", "onSaveLocation", "dialogLoading", "lineManagers", "officeSpaces"]))
      : _createCommentVNode("", true)
  ], 64))
}