<template>
  <div>
    <v-form ref="mainForm">
      <v-card flat :loading="loading" :disabled="loading">
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <TextField
                :disabled="loading"
                :rules="[rules.required]"
                label="First Name"
                v-model="model.firstName"
              />
            </v-col>
            <v-col cols="4">
              <TextField
                :disabled="loading"
                :rules="[rules.required]"
                label="Last Name"
                v-model="model.lastName"
              />
            </v-col>
            <v-col cols="4">
              <TextField
                :disabled="loading"
                :rules="[rules.EmailCheck]"
                label="Email"
                v-model="model.email"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <div class="actionBtns mt-3">
      <v-btn class="primary_btn_v2" @click="invite" :loading="loading">Invite</v-btn>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import rules from "shared-components/src/utils/validations";
import {ClientInviteTeammemberRequest } from "shared-components/src/services/openApi/api";
import TeammemberService from "@/services/TeammemberService";
import store from "@/store";
export default defineComponent({
  props: [],
  data() {
    return {
      loading: false,
      rules,
      model: {} as ClientInviteTeammemberRequest,
    };
  },
  mounted() {
  },
  methods: {
    async invite(){
      const isValid = await (this.$refs.mainForm as any).validate();
      if (isValid.valid) {
        this.loading = true;
        try{
          const response = await TeammemberService.inviteTeammember(this.model);
          this.$emit("InviteTM", response);
        }catch(error: any){
          store.dispatch("showErrorMessage", error);
        }
        finally{
          this.loading = false;
        }  
      }
    }
  },
});
</script>
<style lang="scss" scoped>
.actionBtns {
  display: flex;
  justify-content: right;
}
</style>
