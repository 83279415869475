<template>
  <div>
    <v-form ref="mainForm">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-label>Location Type</v-label>
              <v-radio-group
                inline
                v-model="model.LocationType"
                :rules="[isJdSearch || rules.required]"
              >
                <v-radio label="Remote" value="Remote"></v-radio>
                <v-radio label="On-Site" value="OnSite"></v-radio>
                <v-radio label="Hybrid" value="Hybrid"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <AutoCompleteField
                :rules="[isJdSearch || rules.required]"
                label="Timezone"
                v-model="model.TimeZone"
                :items="timeZones"
                outlined
                :return-object="false"
              />
            </v-col>
            <v-col cols="4" v-if="model.LocationType != 'Hybrid'">
              <AutoCompleteField
                :rules="[isJdSearch || rules.required]"
                label="Days of Week"
                v-model="model.WorkingDays"
                :items="workingDays"
                multiple
                outlined
                @update:modelValue="DayOfWeekChanged"
                :return-object="false"
              />
            </v-col>
            <v-col cols="2">
              <AutoCompleteField
                :rules="[isJdSearch || rules.required]"
                label="From"
                v-model="model.From"
                :items="hoursInDay"
              />
            </v-col>
            <v-col cols="2">
              <AutoCompleteField
                :rules="[isJdSearch || rules.required]"
                label="To"
                v-model="model.To"
                :items="hoursInDay"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" v-if="model.LocationType != 'Remote'">
              <AutoCompleteField
                outlined
                :rules="[isJdSearch || rules.required]"
                :items="officeLocations"
                item-title="Name"
                item-value="Id"
                label="Office Location"
                showRequired
                v-model="model.OfficeLocationId"
                :return-object="false"
                append-icon="mdi-plus"
                @click:append="showAddOfficeLocation = true"
              />
            </v-col>
            <v-col cols="4" v-if="model.LocationType == 'Hybrid'">
              <AutoCompleteField
                v-model="model.NoOfficeDaysPerWeek"
                :rules="[isJdSearch || rules.required]"
                label="No. Of Office Days Per Week"
                :items="NoOfficeDaysPerWeek"
                outlined
              />
            </v-col>
            <v-col cols="4" v-if="model.LocationType == 'Hybrid'">
              <AutoCompleteField
                v-model="model.WorkingDaysInOffice"
                :rules="[isJdSearch || rules.required]"
                label="Days Of Week In The Office"
                :items="dayInOffice"
                multiple
                outlined
                chips
                @update:modelValue="DaysInOfficeChanged"
                :return-object="false"
              />
            </v-col>
            <v-col cols="2"> </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <div class="actionBtns mt-3">
      <v-btn class="secondary_btn" @click="backStep">Previous</v-btn>
      <v-btn class="primary_btn_v2" @click="nextStep">Next</v-btn>
    </div>

    <v-dialog v-model="showAddOfficeLocation" persistent max-width="400px">
      <AddOfficeLocation
        @cancel="showAddOfficeLocation = false"
        @saveLocation="saveOfficeLocation"
      />
    </v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import moment from "moment";
import AddOfficeLocation from "./AddOfficeLocation.vue";

var momentTz = require("moment-timezone");

export default defineComponent({
  components: {
    AddOfficeLocation,
  },
  props: ["officeLocations", "isJdSearch"],
  data() {
    return {
      endDatePickerMenu: false,
      startDatePickerMenu: false,
      daysTemplate: [
        { title: "Flexible", value: "Flexible", props: { disabled: false } },
        { title: "SUN", value: "SUN", props: { disabled: false } },
        { title: "MON", value: "MON", props: { disabled: false } },
        { title: "TUE", value: "TUE", props: { disabled: false } },
        { title: "WED", value: "WED", props: { disabled: false } },
        { title: "THR", value: "THR", props: { disabled: false } },
        { title: "FRI", value: "FRI", props: { disabled: false } },
        { title: "SAT", value: "SAT", props: { disabled: false } },
      ],
      workingDays: [
        { title: "Flexible", value: "Flexible", props: { disabled: false } },
      ],
      dayInOffice: [
        { title: "Flexible", value: "Flexible", props: { disabled: false } },
      ],
      hoursInDay: ["Flexible"],
      NoOfficeDaysPerWeek: ["Flexible", 1, 2, 3, 4, 5, 6, 7],
      model: {
        TimeZone: null as string | null,
        From: null as string | null,
        To: null as string | null,
        WorkingDays: [] as string[],
        WorkingDaysInOffice: [] as string[],
        NoOfficeDaysPerWeek: null as string | null,
        OfficeLocationId: null as string | null,
        LocationType: "Remote",
      },
      rules: {
        required: (value: any) =>
          (!(value instanceof Array) && !!value) ||
          (value instanceof Array && value.length > 0) ||
          "Required.",
      },
      timeZones: [] as Array<{ title: string; value: string }>,
      showAddOfficeLocation: false,
    };
  },
  mounted() {
    this.workingDays = JSON.parse(JSON.stringify(this.daysTemplate));
    this.dayInOffice = JSON.parse(JSON.stringify(this.daysTemplate));
    this.fillHoursInDay();
    this.loadTimeZones();
  },
  methods: {
    loadTimeZones() {
      var timeZones = momentTz.tz.names();

      for (var i in timeZones) {
        this.timeZones.push({
          title:
            timeZones[i] +
            " (GMT" +
            momentTz.tz(timeZones[i]).format("Z") +
            ")",
          value: timeZones[i],
        });
      }
    },
    saveOfficeLocation(event: any) {
      this.showAddOfficeLocation = false;
      this.$emit("saveLocation", event);
    },
    DaysInOfficeChanged() {
      if (this.model.WorkingDaysInOffice.includes("Flexible")) {
        this.model.WorkingDaysInOffice = ["Flexible"];
        this.dayInOffice.forEach((item: any) =>
          item.title != "Flexible"
            ? (item.props.disabled = true)
            : (item.props.disabled = false)
        );
      } else {
        this.dayInOffice.forEach((item: any) => (item.props.disabled = false));
      }
    },
    DayOfWeekChanged() {
      if (this.model.WorkingDays.includes("Flexible")) {
        this.model.WorkingDays = ["Flexible"];
        this.workingDays.forEach((item: any) =>
          item.title != "Flexible"
            ? (item.props.disabled = true)
            : (item.props.disabled = false)
        );
      } else {
        this.workingDays.forEach((item: any) => (item.props.disabled = false));
      }
    },
    fillHoursInDay() {
      this.hoursInDay = ["Flexible"];
      this.hoursInDay = this.hoursInDay.concat(
        ...Array.from(Array(24), (_, hour) => [
          moment({ hour }).format("h:mm A"),
          moment({ hour, minute: 30 }).format("h:mm A"),
        ])
      );
    },
    backStep() {
      this.$emit("BackStep");
    },
    async nextStep() {
      const isValid = await (this.$refs.mainForm as any).validate();
      if (isValid.valid) {
        this.$emit("NextStep", this.model);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.actionBtns {
  display: flex;
  justify-content: space-between;
}
</style>
