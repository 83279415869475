import {defineComponent} from "vue";
import moment from "moment";
moment.updateLocale("en", {
  // ISO-8601
  week: {
    dow: 1, // First day of week is Monday
    doy: 4, // First week of year must contain 4 January (7 + 1 - 4)
  },
});
defineComponent.prototype.moment = moment;
window.moment = moment;
export default moment;
