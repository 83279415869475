import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Transition as _Transition } from "vue"
import _imports_0 from '@/assets/images/code-clan-logo.svg'


const _hoisted_1 = { class: "h-100" }
const _hoisted_2 = {
  key: 0,
  class: "logo",
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationMenu = _resolveComponent("NavigationMenu")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_HeaderTitle = _resolveComponent("HeaderTitle")!
  const _component_ProfileDropdown = _resolveComponent("ProfileDropdown")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_navigation_drawer, {
      dark: "",
      fixed: "",
      temporary: _ctx.drawerPermanent,
      permanent: _ctx.drawerPermanent,
      "mini-variant": _ctx.drawerMini,
      modelValue: _ctx.drawerIsOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawerIsOpen) = $event)),
      app: "",
      "clipped-top": "",
      class: "app-drawer",
      width: "220"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["logo-container", { 'logo-container--mini': _ctx.mini }])
        }, [
          (!_ctx.mini)
            ? (_openBlock(), _createElementBlock("img", _hoisted_2))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode(_component_NavigationMenu, {
          isAuthenticated: _ctx.isAuthenticated,
          isProjectLead: _ctx.isProjectLead,
          isPortfolioManager: _ctx.isPortfolioManager,
          isClient: _ctx.isClient,
          isTDM: _ctx.isTDM
        }, null, 8, ["isAuthenticated", "isProjectLead", "isPortfolioManager", "isClient", "isTDM"])
      ]),
      _: 1
    }, 8, ["temporary", "permanent", "mini-variant", "modelValue"]),
    _createVNode(_component_v_app_bar, {
      app: "",
      color: "primary",
      class: "appBarComponent"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, {
          class: "hamburgur-toggler ml-3",
          onClick: _ctx.toggleDrawerOpen
        }, null, 8, ["onClick"]),
        _createVNode(_component_HeaderTitle),
        _createVNode(_component_ProfileDropdown)
      ]),
      _: 1
    }),
    _createVNode(_component_v_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_container, {
          fluid: "",
          class: "pages-container"
        }, {
          default: _withCtx(() => [
            _createVNode(_Transition, {
              name: "slide-y-transition",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}