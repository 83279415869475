import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 1,
  class: "text_white"
}
const _hoisted_3 = { class: "text_white" }
const _hoisted_4 = {
  key: 0,
  class: "avatars-group__item"
}
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = {
  key: 1,
  class: "text_white"
}
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 1,
  class: "text_white"
}
const _hoisted_9 = { class: "text_white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_avatar = _resolveComponent("v-list-item-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_AddOrEditPositionDetails = _resolveComponent("AddOrEditPositionDetails")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_ctx.avatars && _ctx.avatars.length > 0)
    ? (_openBlock(), _createBlock(_component_v_row, {
        key: 0,
        "no-gutters": "",
        class: "align-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: _ctx.showActionIcon ? 11 : 12
          }, {
            default: _withCtx(() => [
              _createElementVNode("section", {
                class: "avatars-group pa-3 pt-0 stacked",
                style: _normalizeStyle({ 'justify-content': _ctx.justify })
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avatars, (avatar) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass([
            avatar.isClicked ? 'selectedAvatar ' + _ctx.touchClass + '' : _ctx.touchClass
          , "avatars-group__item"]),
                    key: `avatar-id-${avatar.id}`
                  }, [
                    _createVNode(_component_v_tooltip, { location: "top" }, {
                      activator: _withCtx(({ props }) => [
                        (avatar.id)
                          ? (_openBlock(), _createBlock(_component_v_avatar, _mergeProps({
                              key: 0,
                              class: 
                  avatar.isClicked
                    ? 'selectedAvatarIcon ' + _ctx.getStatus(avatar.status) + ''
                    : _ctx.getStatus(avatar.status)
                ,
                              onClick: ($event: any) => (_ctx.getTeammemberEpic(avatar.id)),
                              ref_for: true
                            }, props, {
                              color: !avatar.photoUrl ? _ctx.GetColour(avatar.fullName) : null
                            }), {
                              default: _withCtx(() => [
                                _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                                (avatar.photoUrl)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      class: "pa-1 profile-photo",
                                      src: avatar.photoUrl,
                                      alt: avatar.fullName
                                    }, null, 8, _hoisted_1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.GetInitials(avatar.fullName)), 1))
                              ]),
                              _: 2
                            }, 1040, ["class", "onClick", "color"]))
                          : (_openBlock(), _createBlock(_component_v_avatar, _mergeProps({
                              key: 1,
                              class: avatar.isClicked ? 'selectedAvatarIcon' : '',
                              onClick: ($event: any) => (_ctx.getTeammemberEpic(avatar.id)),
                              ref_for: true
                            }, props, {
                              color: _ctx.GetColour(avatar.fullName)
                            }), {
                              default: _withCtx(() => [
                                _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                                _createElementVNode("span", _hoisted_3, _toDisplayString(avatar.fullName), 1)
                              ]),
                              _: 2
                            }, 1040, ["class", "onClick", "color"]))
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(avatar.fullName), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ], 2))
                }), 128)),
                (_ctx.showMore && _ctx.hasMore && _ctx.avatars && _ctx.avatars.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_v_avatar, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_menu, {
                            modelValue: _ctx.menu,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menu) = $event)),
                            "close-on-content-click": false,
                            location: "end"
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createVNode(_component_v_btn, _mergeProps({ color: "indigo" }, props, { icon: "mdi-dots-horizontal" }), null, 16)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_v_card, { class: "avatarMenu" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list, null, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avatars, (avatar) => {
                                        return (_openBlock(), _createBlock(_component_v_list_item, {
                                          key: `avatar-menu-id-${avatar.id}`
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_row, {
                                              class: _normalizeClass([avatar.isClicked ? 'selectedAvatarListItem' : '', "avatar-moreList"]),
                                              onClick: _withModifiers(($event: any) => (_ctx.getTeammemberEpic(avatar.id)), ["stop"])
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_col, { md: "2" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_list_item_avatar, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_avatar, {
                                                          class: _normalizeClass(avatar.id ? _ctx.getStatus(avatar.status) : ''),
                                                          color: 
                              !avatar.photoUrl
                                ? _ctx.GetColour(avatar.fullName)
                                : null
                            
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                                                            (avatar.photoUrl)
                                                              ? (_openBlock(), _createElementBlock("img", {
                                                                  key: 0,
                                                                  class: "pa-1 profile-photo",
                                                                  src: avatar.photoUrl,
                                                                  alt: avatar.fullName
                                                                }, null, 8, _hoisted_5))
                                                              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.GetInitials(avatar.fullName)), 1))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["class", "color"])
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_col, { class: "moreList ml-0" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_list_item_content, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_list_item_title, { class: "pl-2" }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(avatar.fullName), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_divider)
                                              ]),
                                              _: 2
                                            }, 1032, ["class", "onClick"])
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]),
                                    _: 3
                                  })
                                ]),
                                _: 3
                              })
                            ]),
                            _: 3
                          }, 8, ["modelValue"])
                        ]),
                        _: 3
                      })
                    ]))
                  : _createCommentVNode("", true)
              ], 4)
            ]),
            _: 3
          }, 8, ["cols"]),
          (_ctx.showActionIcon)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                cols: "1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_menu, {
                    modelValue: _ctx.actionMenu,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.actionMenu) = $event)),
                    location: "bottom",
                    "scroll-strategy": "close"
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({ variant: "text" }, props, { icon: "mdi-dots-vertical" }), null, 16)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, { "min-width": "300" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item, {
                                link: "",
                                title: "Add Position",
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showNewPosition = true))
                              }),
                              (_ctx.project.Positions && _ctx.project.Positions.length > 0)
                                ? (_openBlock(), _createBlock(_component_v_list_group, {
                                    key: 0,
                                    value: "Positions"
                                  }, {
                                    activator: _withCtx(({ props }) => [
                                      _createVNode(_component_v_list_item, _mergeProps(props, { title: "Positions" }), null, 16)
                                    ]),
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.Positions, (position) => {
                                        return (_openBlock(), _createBlock(_component_v_list_item, {
                                          link: "",
                                          title: _ctx.getPositionTitle(position),
                                          onClick: ($event: any) => (_ctx.showPositionMenu(position.Id))
                                        }, null, 8, ["title", "onClick"]))
                                      }), 256))
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.users && _ctx.users.length > 0)
                                ? (_openBlock(), _createBlock(_component_v_list_group, {
                                    key: 1,
                                    value: "Teammembers"
                                  }, {
                                    activator: _withCtx(({ props }) => [
                                      _createVNode(_component_v_list_item, _mergeProps(props, { title: "Teammembers" }), null, 16)
                                    ]),
                                    default: _withCtx(() => [
                                      _cache[6] || (_cache[6] = _createElementVNode("div", null, null, -1)),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                                        return (_openBlock(), _createBlock(_component_v_list_item, {
                                          class: "customPrepend",
                                          link: "",
                                          title: user.fullName,
                                          onClick: ($event: any) => (_ctx.showCommitmentMenu(user.commitmentId))
                                        }, {
                                          prepend: _withCtx(() => [
                                            (user.id)
                                              ? (_openBlock(), _createBlock(_component_v_avatar, {
                                                  key: 0,
                                                  color: !user.photoUrl ? _ctx.GetColour(user.fullName) : null
                                                }, {
                                                  default: _withCtx(() => [
                                                    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                                                    (user.photoUrl)
                                                      ? (_openBlock(), _createElementBlock("img", {
                                                          key: 0,
                                                          class: "pa-1 profile-photo",
                                                          src: user.photoUrl,
                                                          alt: user.fullName
                                                        }, null, 8, _hoisted_7))
                                                      : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.GetInitials(user.fullName)), 1))
                                                  ]),
                                                  _: 2
                                                }, 1032, ["color"]))
                                              : (_openBlock(), _createBlock(_component_v_avatar, {
                                                  key: 1,
                                                  color: _ctx.GetColour(user.fullName)
                                                }, {
                                                  default: _withCtx(() => [
                                                    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                                                    _createElementVNode("span", _hoisted_9, _toDisplayString(user.fullName), 1)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["color"]))
                                          ]),
                                          _: 2
                                        }, 1032, ["title", "onClick"]))
                                      }), 256))
                                    ]),
                                    _: 3
                                  }))
                                : _createCommentVNode("", true)
                            ]),
                            _: 3
                          })
                        ]),
                        _: 3
                      })
                    ]),
                    _: 3
                  }, 8, ["modelValue"])
                ]),
                _: 3
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_dialog, {
            modelValue: _ctx.showNewPosition,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showNewPosition) = $event)),
            persistent: "",
            "max-width": "600px",
            onKeydown: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.showNewPosition = false), ["esc"]))
          }, {
            default: _withCtx(() => [
              (_ctx.showNewPosition)
                ? (_openBlock(), _createBlock(_component_AddOrEditPositionDetails, {
                    key: 0,
                    isNew: true,
                    addLoading: _ctx.addLoading,
                    onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showNewPosition = false)),
                    onSuccess: _ctx.AddNewProjectPosition,
                    details: _ctx.details,
                    skills: _ctx.skills
                  }, null, 8, ["addLoading", "onSuccess", "details", "skills"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 3
      }))
    : _createCommentVNode("", true)
}