import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pa-md-16 ma-16" }
const _hoisted_2 = {
  key: 0,
  class: "emptyResult d-flex justify-center mb-6"
}
const _hoisted_3 = { class: "pa-md-16 ma-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LastTwelveMonth = _resolveComponent("LastTwelveMonth")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_ProjectTreeReport = _resolveComponent("ProjectTreeReport")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TimesheetChartReport = _resolveComponent("TimesheetChartReport")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, { disabled: _ctx.loading }, {
      default: _withCtx(() => [
        _createVNode(_component_LastTwelveMonth, { onFilterDataByDate: _ctx.filterDataByDate }, null, 8, ["onFilterDataByDate"])
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_v_card, { loading: _ctx.loading }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "5",
                  sm: "12",
                  xs: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ProjectTreeReport, {
                      projects: _ctx.filteredProjectsToShowInTree,
                      filteredTimesheets: _ctx.filteredTimesheetsByDates,
                      onFilterSummaries: _ctx.filterSummaries
                    }, null, 8, ["projects", "filteredTimesheets", "onFilterSummaries"])
                  ]),
                  _: 1
                }),
                (
              !_ctx.loading &&
              !_ctx.projectMessage &&
              (!_ctx.activityReport || _ctx.activityReport.length === 0) &&
              (!_ctx.technologyReport || _ctx.technologyReport.length === 0)
            )
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      class: "emptyResult d-flex justify-center mb-6",
                      cols: "12",
                      md: "7",
                      sm: "12",
                      xs: "12"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.chartMessage), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "7",
                  sm: "12",
                  xs: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_TimesheetChartReport, {
                      activities: _ctx.activityReport,
                      technologies: _ctx.technologyReport
                    }, null, 8, ["activities", "technologies"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            (
            !_ctx.loading &&
            (!this.filteredProjects || this.filteredProjects.length === 0)
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.projectMessage), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}