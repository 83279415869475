<template>
  <v-card class="assessment-card">
    <v-row>
      <v-col cols="12">
        <div class="assessment-details">
          <v-row>
            <v-col cols="8" md="7">
              <span class="title" @click="showAssessmentDetail(assessment.Id)">
                {{ truncate(assessment.Title) }}</span
              >
            </v-col>

            <v-col cols="4" md="5" class="d-flex justify-end">
              <v-tooltip location="bottom" v-if="assessment.IsFavorite != true">
                <template v-slot:activator="{ props }">
                  <v-btn
                    class="pr-2 pl-2"
                    v-bind="props"
                    style="min-width: 0"
                    variant="text"
                    @click="addToFavorite"
                  >
                    <v-icon> mdi-heart </v-icon>
                  </v-btn>
                </template>
                Add to Favorites
              </v-tooltip>
              <v-tooltip location="bottom" v-if="assessment.IsFavorite == true">
                <template v-slot:activator="{ props }">
                  <v-btn
                    class="pr-2 pl-2"
                    v-bind="props"
                    style="min-width: 0"
                    variant="text"
                    @click="addToFavorite"
                  >
                    <v-icon class="heart_icon"> mdi-heart </v-icon>
                  </v-btn>
                </template>
                Remove from Favorites
              </v-tooltip>

              <v-tooltip location="bottom" v-if="!showAddToRoadMap">
                <template v-slot:activator="{ props }">
                  <v-btn
                    class="pr-2 pl-2"
                    v-bind="props"
                    style="min-width: 0"
                    variant="text"
                    @click="editAssessment(assessment.Id)"
                  >
                    <v-icon> mdi-pen </v-icon>
                  </v-btn>
                </template>
                Edit
              </v-tooltip>
            </v-col>
          </v-row>

          <div v-if="assessment.Questions" class="detail-item">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-human-male-board</v-icon>
                  {{ getTechnologies(assessment.Questions) }}
                </span>
              </template>
              Questions Technologies
            </v-tooltip>
          </div>

          <div v-if="assessment.Questions" class="detail-item">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-human-male-board</v-icon>
                  {{ getLevels(assessment.Questions) }}
                </span>
              </template>
              Questions Levels
            </v-tooltip>
          </div>

          <div v-if="assessment.Questions">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-human-male-board</v-icon>
                  {{ getDuration(assessment.Questions) }}
                </span>
              </template>
              Questions Duration
            </v-tooltip>
          </div>

          <div class="tag-section" v-if="assessment.Tags.length > 0">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  <v-icon>mdi-tag</v-icon>
                </span>
              </template>
              Tags
            </v-tooltip>
            <v-chip link v-for="(tag, index) in assessment.Tags" :key="index">
              {{ tag.Title }}
            </v-chip>
          </div>
          <div>
            <v-btn
              v-if="showAddToRoadMap"
              @click="addToRoadMap(assessment)"
              :disabled="stepIds.includes(assessment.Id)"
            >
              Add to roadmap
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AssessmentModel, {
  Question,
} from "../../../../../ProjectPortal-SharedComponents/src/models/AssessmentModel";

export default defineComponent({
  props: {
    assessment: {
      type: Object as () => AssessmentModel,
      required: true,
    },
    showAddToRoadMap: {
      type: Boolean,
      default: false,
    },
    stepIds: {
      type: Array as () => String[],
      default: [],
    },
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  methods: {
    getTechnologies(questions: Question[]) {
      let technologies = questions
        .map((item: any) => item.Technologies.map((tcItem: any) => tcItem.Text))
        .flat();
      return [...new Set(technologies)].join(", ");
    },
    getLevels(questions: Question[]) {
      let levels = questions.map((item: any) => item.Level);
      return [...new Set(levels)].join(", ");
    },
    getDuration(questions: Question[]) {
      let sum = questions.reduce((total, a) => total + a.Duration, 0);
      return this.formatDuration(sum);
    },
    showAssessmentDetail(assessmentId: any) {
      this.$router.push({
        name: "assessment-detail",
        params: { assessmentId },
      });
    },
    editAssessment(assessmentId: any) {
      if (assessmentId) {
        this.$router.push({
          name: "edit-assessment",
          params: { assessmentId },
        });
      }
    },
    addToFavorite() {
      this.assessment.IsFavorite = !this.assessment.IsFavorite;
    },
    addToRoadMap(assessment: AssessmentModel) {
      this.$emit("AddAssessmentToRoadMap", {
        Id: assessment.Id,
        Title: assessment.Title,
        Type: "Assessment",
      });
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
    truncate(item: any) {
      if (item && item.length > 25) {
        return item.substring(0, 25) + "...";
      } else {
        return item;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/_sizes.scss";
.assessment-card {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid $light_gray;
  height: 100%;
  .assessment-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    position: relative;

    .detail-item {
      text-wrap: pretty;
    }
    .heart_icon {
      color: $racecar;
    }

    .title {
      font-size: $size-m;
      font-weight: 500;
      cursor: pointer;
      flex-wrap: wrap;
    }

    .description {
      text-justify: auto;
      font-size: $size-s;
    }

    .favorite-btn {
      position: absolute;
      right: 0;
    }

    .tag-section {
      display: flex;
      gap: 5px;
    }
  }
}
</style>
