import axios from "axios";

export default class UploadService {
  public static async uploadToContainer(file: File, uploadUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.put(uploadUrl, file, {
        headers: {
          "X-Ms-Blob-Type": "BlockBlob",
          "Content-Type": "image/jpg",
        },
      }).then((result) => {
          resolve(result);
      })
      .catch((error) => reject(error));
    });
  }
}
