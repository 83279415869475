<template>
  <v-card class="project-item">
    <v-card-title>
      <div class="card-title">
        <div class="card-title-left">{{ project.Name }}</div>
        <div class="card-title-right">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="text"
                size="small"
                class="button"
                icon="mdi-dots-vertical"
              />
            </template>
            <v-list>
              <v-list-item
                title="Add Position"
                @click="showNewPosition = true"
              ></v-list-item>
              <v-list-item
                title="Update Project"
                @click="showUpdateProject()"
              />
              <v-list-item title="Epic List" @click="showEpicPopup()" />
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-card-title>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <div class="item-rows">
        <div class="item-header">
          <label>Positions</label>
        </div>
        <div
          v-if="!project.Positions || project.Positions?.length == 0"
          class="no-record"
        >
          There are no Positions for this Project
        </div>
        <v-row class="m-0">
          <v-col
            cols="6"
            v-for="position in project.Positions"
            :key="position.Id"
          >
            <div class="item-object">
              <div class="item-details">
                <div class="item-header">
                  <div v-tooltip:top="'Job Role'">
                    <v-icon icon="mdi-badge-account-horizontal-outline" />
                    {{ position.JobRoleName }}
                  </div>
                  <v-btn
                    @click="openPositionMenu(position)"
                    color="black"
                    variant="text"
                    size="large"
                    icon="mdi-dots-horizontal-circle"
                  />
                </div>
                <div>
                  <v-row>
                    <v-col cols="6">
                      <div v-tooltip:top="'Job Title'">
                        <v-icon icon="mdi-card-bulleted-outline" />
                        {{ position.JobTitles ? position.JobTitles : "-" }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div v-tooltip:top="'Skills'">
                        <v-icon icon="mdi-account-file-text-outline" />
                        {{ position.Skills ? position.Skills : "-" }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div>
                  <v-row>
                    <v-col cols="6">
                      <div v-tooltip:top="'Number Of Positions'">
                        <v-icon icon="mdi-counter" />
                        {{ position.NoOfPositions }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div v-tooltip:top="'Start Date'">
                        <v-icon icon="mdi-timer-play" />
                        {{
                          position.StartDate
                            ? formatDate(position.StartDate)
                            : "ASAP"
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="item-rows mt-3">
        <div class="item-header">
          <label>Teammembers</label>
          <v-switch
            :disabled="project.Teammembers?.length == 0"
            v-if="!notHaveAnyActive(project.Teammembers)"
            color="primary"
            v-model="project.ShowExpiredCommitments"
            hide-details
            label="Show Expired"
          ></v-switch>
        </div>
        <div
          v-if="!project.Teammembers || project.Teammembers?.length == 0"
          class="no-record"
        >
          There are no Teammember for this Project
        </div>
        <v-row class="m-0">
          <v-col cols="6" v-for="tm in ProjectFilteredTms" :key="tm.Id">
            <div class="item-object">
              <div class="item-details">
                <div class="item-header">
                  <div class="item-sub-header">
                    <div>
                      <Avatar
                        :Name="tm.Name"
                        :Photo="tm.PhotoUrl"
                        class="item-avatar"
                      />
                      <span class="item-name">{{ tm.Name }}</span>
                    </div>
                    <div class="item-sub-header">
                      <div
                        v-tooltip:top="'Job Role'"
                        v-if="tm.JobRole && tm.JobRole != ''"
                      >
                        <v-icon icon="mdi-badge-account-horizontal-outline" />
                        {{ tm.JobRole }}
                      </div>
                      <div
                        v-tooltip:top="'Job Title'"
                        v-if="tm.JobTitle && tm.JobTitle != ''"
                      >
                        <v-icon icon="mdi-card-bulleted-outline" />
                        {{ truncate(tm.JobTitle) }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="alert-container"
                    v-if="!isCommitmentExpired(tm.CommitmentEndDate)"
                  >
                    <AlertItem
                      tooltip="This Commitment is near to expire"
                      icon="mdi-timer-off"
                      type="Risk"
                      v-if="tm.NearToExpire"
                    />
                    <AlertItem
                      tooltip="This Commitment already have a upcoming commitment"
                      icon="mdi-file-sign"
                      type="Info"
                      v-if="tm.HaveUpcomingCommitment"
                    />
                  </div>
                  <v-btn
                    @click="openCommitmentMenu(tm)"
                    color="black"
                    variant="text"
                    size="large"
                    icon="mdi-dots-horizontal-circle"
                  />
                </div>
                <div v-if="tm.IsCommitmentExpired" class="item-expired">
                  The Commitment of this teammember is Expired
                </div>
                <div
                  class="chart-container"
                  v-if="tm.VmId && !isCommitmentExpired(tm.CommitmentEndDate)"
                >
                  <LineChart :tm="tm" />
                </div>
                <div
                  class="chart-container"
                  v-if="
                    (tm.VmId || tm.CommitmentTimesheetProcessing) &&
                    !isCommitmentExpired(tm.CommitmentEndDate)
                  "
                >
                  <Bar :data="getBarChartData(tm)" :options="barOptions" />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
  <CommitmentMenu
    v-if="showCommitmentMenu"
    :projectId="selectedProjectId"
    :project="project"
    @OnClose="closeCommitmentMenu"
    :details="details"
    :commitmentActions="commitmentActions"
  />
  <PositionMenu
    v-if="showPositionDetail"
    :details="details"
    :skills="skills"
    :project="project"
    :projectList="projectList"
    :projectLeads="projectLeads"
    :publicHolidayZones="publicHolidayZones"
    :officeLocations="officeLocations"
    :lineManagers="lineManagers"
    :portfolioManagers="portfolioManagers"
    :officeSpaces="officeSpaces"
    @OnClose="closePositionMenu"
  />
  <v-dialog v-model="showNewPosition" max-width="900">
    <AddOrEditPositionDetails
      v-if="showNewPosition"
      :isNew="true"
      :addLoading="addLoading"
      @cancel="showNewPosition = false"
      @success="AddNewProjectPosition"
      :details="details"
      :skills="skills"
    ></AddOrEditPositionDetails>
  </v-dialog>

  <v-dialog v-model="showProjectModal" max-width="900">
    <ProjectDetail
      @close="closeProjectPopup"
      @savedProject="savedProject"
      :project="project"
    />
  </v-dialog>
  <v-dialog v-model="showEpicList" max-width="900">
    <EpicList
      @close="showEpicList = false"
      :project="project"
      :isClient="true"
    />
  </v-dialog>
</template>
<script lang="ts">
  import { defineComponent } from "vue"
  import ProjectDetail from "shared-components/src/components/Project/ProjectDetails.vue"
  import EpicList from "shared-components/src/components/Project/EpicList.vue"
  import store from "@/store"
  import {
    ProjectPositionResponseModel,
    TeammemberWithVmDetails,
  } from "shared-components/src/services/openApi/api"
  import Avatar from "@/components/Avatar.vue"
  import { Bar } from "vue-chartjs"
  import { Line } from "vue-chartjs"
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
  } from "chart.js"
  import moment from "moment"
  import LoadingV2 from "shared-components/src/components/LoadingV2.vue"
  import Utils from "shared-components/src/utils/Utils"
  import CommitmentMenu from "../MyProjects/Menus/CommitmentMenu.vue"
  import AddOrEditPositionDetails from "../MyProjects/ProjectPosition/AddOrEditPositionDetails.vue"
  import PositionService from "@/services/PositionService"
  import PositionMenu from "../MyProjects/Menus/PositionMenu.vue"
  import "chartjs-adapter-date-fns"
  import LineChart from "./LineChart.vue"
  import AlertItem from "./AlertItem.vue"

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale
  )

  export default defineComponent({
    components: {
      ProjectDetail,
      EpicList,
      Avatar,
      Bar,
      Line,
      LoadingV2,
      CommitmentMenu,
      AddOrEditPositionDetails,
      PositionMenu,
      LineChart,
      AlertItem,
    },
    props: [
      "project",
      "details",
      "skills",
      "projectList",
      "projectLeads",
      "publicHolidayZones",
      "officeLocations",
      "lineManagers",
      "portfolioManagers",
      "officeSpaces",
      "commitmentActions",
    ],
    data() {
      return {
        addLoading: false,
        showNewPosition: false,
        selectedPositionId: null as null | string,
        showPositionDetail: false,
        selectedCommitmentId: null as null | string,
        selectedProjectId: "",
        showCommitmentMenu: false,
        loading: false,
        showProjectModal: false,
        showActivityWatchModal: false,
        showWazuhModal: false,
        showVdSupportModal: false,
        selectedVmId: null as string | null,
        showEpicList: false,
        showExpiredTms: false,

        barOptions: {
          plugins: {
            tooltip: {
              callbacks: {
                label: (context: any) => {
                  if (context.raw) {
                    return `${context.dataset.label}: ${Utils.formatTime(
                      parseFloat(context.raw) * 3600
                    )}`
                  }
                  return ""
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              min: 0,
              max: 16,
              ticks: {
                display: false,
                stepSize: 1,
                autoSkip: false,
              },
            },
            x: {
              ticks: {
                font: {
                  size: 10,
                },
                autoSkip: false,
              },
            },
          },
        },
      }
    },
    computed: {
      ProjectFilteredTms() {
        if (this.project.Teammembers) {
          if (this.project.ShowExpiredCommitments) {
            return this.project.Teammembers
          } else {
            return this.project.Teammembers.filter(
              (item: any) => !item.IsCommitmentExpired
            )
          }
        }
        return []
      },
    },
    async mounted() {
      if (
        this.project.Teammembers &&
        this.project.Teammembers.filter(
          (item: any) => !item.IsCommitmentExpired
        ).length == 0
      ) {
        this.project.ShowExpiredCommitments = true
      }
    },
    methods: {
      isCommitmentExpired(date: string) {
        return moment(date) < moment()
      },
      truncate(item: any) {
        if (item && item.length > 30) {
          return item.substring(0, 30) + "..."
        } else {
          return item
        }
      },
      formatDate(dateString: string | null | undefined) {
        if (!dateString) {
          return ""
        }
        return dateString.split("T")[0]
      },
      async AddNewProjectPosition(event: any) {
        this.addLoading = true
        event.ProjectId = this.project.Id
        const result = await PositionService.SavePosition(event)
        if (this.project && !this.project?.Positions) {
          this.project.Positions = []
        }

        this.project?.Positions.push({
          CountryId: result.CountryId,
          Id: result.Id,
          JobRoleName:
            this.details.find((item: any) => item.id == result.JobRole)?.Name ??
            "",
          JobTitles: result.JobTitles?.map((item) => item.Name).join(", "),
          NoOfPositions: result.NoOfPositions,
          ProjectId: result.ProjectId,
          Skills: result.Skills?.map((item) => item.Text).join(", "),
          StartDate: result.StartDate,
        } as ProjectPositionResponseModel)
        setTimeout(() => {
          this.addLoading = false
          this.showNewPosition = false
        }, 100)
      },
      openPositionMenu(position: any) {
        this.$router.replace({
          name: "management",
          query: { positionId: position.Id } as any,
        })
        setTimeout(() => {
          this.showPositionDetail = true
          this.selectedPositionId = position.Id
        }, 100)
      },
      openPositionMenuFromParent(positionId: any) {
        this.showPositionDetail = true
        this.selectedPositionId = positionId
      },
      openCommitmentMenuFromParent(commitmentId: any) {
        this.showCommitmentMenu = true
        this.selectedCommitmentId = commitmentId
      },
      closePositionMenu() {
        this.showPositionDetail = false
        this.selectedPositionId = null
        this.$router.replace({ name: "management" })
      },
      closeCommitmentMenu() {
        this.showCommitmentMenu = false
        this.selectedCommitmentId = null
        this.$router.replace({ name: "management" })
      },
      openCommitmentMenu(tm: any) {
        this.$router.replace({
          name: "management",
          query: { commitmentId: tm?.CommitmentId } as any,
        })
        setTimeout(() => {
          this.showCommitmentMenu = true
          this.selectedCommitmentId = tm?.CommitmentId
        }, 100)
      },
      closeProjectPopup() {
        this.showProjectModal = false
        store.dispatch("setNewProjectPopupStatus", false)
      },
      showUpdateProject() {
        this.showProjectModal = true
      },
      showEpicPopup() {
        this.showEpicList = true
      },
      notHaveAnyActive(tms: any) {
        if (tms) {
          return (
            tms.filter((item: any) => !item.IsCommitmentExpired).length == 0
          )
        }
        return true
      },
      savedProject(project: any) {
        this.project.Name = project.Name
        this.showProjectModal = false
      },
      getBarChartData(tm: TeammemberWithVmDetails) {
        if (tm.ActivityWatchDetails) {
          let labels
          const data = tm.ActivityWatchDetails.map(
            (c: any) => (c.totalSecounds ?? 0) / 3600
          )
          const timesheetData = tm.TimesheetDetails?.map(
            (c: any) => (c.totalSecounds ?? 0) / 3600
          )
          const chartData = {
            labels: [] as any[],
            datasets: [] as any[],
          }
          if (tm.VmId) {
            labels = tm.ActivityWatchDetails.map((c: any) =>
              moment(c.date).format("ddd")
            )
            chartData.labels = labels
            chartData.datasets.push({
              data: data,
              label: `Activity Watch (${tm.TimeZone})`,
              backgroundColor: "#ffe100",
              stack: "stack 1",
            })
          }
          if (tm.CommitmentTimesheetProcessing && tm.TimesheetDetails) {
            labels = tm.TimesheetDetails?.map((c: any) =>
              moment(c.date).format("ddd")
            )
            chartData.labels = labels
            chartData.datasets.push({
              data: timesheetData,
              label: "Timesheet",
              backgroundColor: "#004D99",
              stack: "stack 2",
            })
          }
          return chartData
        }
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "node_modules/shared-components/assets/style/color.scss";
  @import "node_modules/shared-components/assets/style/style.scss";

  .project-item {
    min-height: 200px;
    .alert-container {
      display: flex;
      gap: 5px;
    }
    .no-record {
      height: 64px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: $c_davys_grey;
      @extend .b-r-5;
    }
    .item-rows {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .item-expired {
        background-color: $c_platinum !important;
        color: $c_imperial_red;
        text-align: center;
        font-weight: 600;
        padding: 5px;
        @extend .b-r-5;
      }
      .item-object {
        align-items: center;
        background-color: $c_white;
        @extend .b-r-5;
        position: relative;
        height: 100%;
        width: 100%;
        padding: 10px;

        .item-details {
          display: flex;
          flex-direction: column;
          color: $c_black;
          gap: 15px;

          .item-header {
            display: flex;
            align-items: center;

            .item-sub-header {
              display: flex;
              align-items: center;
              gap: 25px;
            }
          }
        }

        .chart-container {
          padding: 0;
          width: 100% !important;
        }
        .item-avatar {
          box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
        }
        .item-name {
          color: $c_black;
          padding-left: 5px;
        }
      }
    }
  }
</style>
