import TagModel from "shared-components/src/models/Tag";
import ApiService from "./ApiService";

export default class TagService {
    public static async GetTagsByType(type: string): Promise<TagModel[]> {
        return new Promise((resolve, reject) => {
            ApiService.get(`/tag?type=${type}`).then(result => {
                if (!result.data) {
                    resolve([] as TagModel[]);
                } else {
                    resolve(result.data as TagModel[]);
                }
            }).catch(error => reject(error));
            
        });
    }
}
