<template>
  <div class="courses_container">
    <v-overlay
      v-model="overlay"
      scroll-strategy="block"
      location-strategy="connected"
      v-if="showAsPage"
      opacity="0.8"
      persistent
      @click="clickOnOverlay"
    >
    </v-overlay>
    <course-filter
      @showOverlay="filterToggle"
      @backBtnClicked="handelBackToRoadMap"
      @doSearch="handleSearchFilter"
      @time-chip-closed="handelTimeChipClosed"
      :ShowBackBtn="backButtonVisibility"
      :showCoursBtn="courseBtnVisiblity"
      :top="topValue"
      :height="heightValue"
      :padding="paddingValue"
      :overlay="overlay"
      :showFilter="showFilter"
      :skills="skills"
    ></course-filter>
    <v-row class="card-list">
      <v-col cols="12" class="loading" v-if="isDetailsLoading">
        <v-progress-circular
          :width="3"
          :size="70"
          color="red"
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col cols="12" v-for="course in courses" :key="course.Id">
        <CourseListItem
          :course="course"
          :showAddToRoadMap="showAddToRoadMap"
          @AddCourseToRoadMap="addToRoadMap"
          :skills="skills"
          :stepIds="stepIds"
        >
        </CourseListItem>
      </v-col>
      <v-col cols="12" v-if="showEmptyCourseCard">
        <empty-course-card></empty-course-card>
      </v-col>
    </v-row>

    <v-alert
      v-if="showErrorAlert"
      type="error"
      dismissible
      @input="showErrorAlert = false"
    >
      Error fetching data. Please try again.
    </v-alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CourseFilter from "./CourseFilter.vue";
import CourseListItem from "./CourseListItem.vue";
import EmptyCourseCard from "./EmptyCourseCard.vue";
import CourseModel from "../../../../../ProjectPortal-SharedComponents/src/models/CourseModel";
import CourseService from "../../../services/CourseService";
import FindCoursesRequest from "shared-components/src/models/requests/FindCoursesRequest";
import CoreSkill from "shared-components/src/models/CoreSkill";
import CoreSkillService from "../../../services/CoreSkillService";

export default defineComponent({
  components: {
    CourseFilter,
    CourseListItem,
    EmptyCourseCard,
  },
  props: {
    showAsPage: {
      type: Boolean,
      default: false,
    },
    backButtonVisibility: {
      type: Boolean,
      default: true,
    },
    showAddToRoadMap: {
      type: Boolean,
      default: false,
    },
    topValue: {
      type: String,
      default: "1px",
    },
    heightValue: {
      type: String,
      default: "610px",
    },
    paddingValue: {
      type: String,
      default: "40px",
    },
    courseBtnVisiblity: {
      type: Boolean,
      default: true,
    },
    stepIds: {
      type: Array as () => String[],
      default: [],
    },
  },
  data() {
    return {
      showFilteredTime: false,
      selectedTime: null as any,
      overlay: false,
      valueFromChild: false,
      searchQuery: {} as FindCoursesRequest,
      showEmptyCourseCard: false,
      showFilter: false,
      courses: [] as CourseModel[],
      showErrorAlert: false,
      skills: [] as CoreSkill[],
      isDetailsLoading: false,
    };
  },
  async mounted() {
    try {
      this.isDetailsLoading = true;
      await this.loadSkillsList();
      var result = await CourseService.GetList();
      this.courses = result;
    } catch (error) {
      console.error("Error fetching data:", error);
      this.showErrorAlert = true;
    } finally {
      this.isDetailsLoading = false;
    }
  },
  methods: {
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    filterToggle(filterVisible: any) {
      this.overlay = !this.overlay;
      this.showFilter = filterVisible;
      this.$emit("showOverlay", filterVisible);
    },
    clickOnOverlay() {
      this.showFilter = false;
      this.overlay = !this.overlay;
    },
    addToRoadMap(courseDetails: any) {
      this.$emit("AddCourseToRoadMap", courseDetails);
      this.handelBackToRoadMap();
    },
    handelBackToRoadMap() {
      this.$emit("CloseCoursesList");
    },
    handleSearchFilter(filterValues: {
      duration: any;
      level: any;
      skill: any;
      text: any;
    }) {
      this.showFilteredTime = filterValues.duration !== null;
      this.searchQuery.Duration = filterValues.duration;
      this.searchQuery.Level = filterValues.level;
      this.searchQuery.Skill = filterValues.skill;
      this.searchQuery.Text = filterValues.text;
    },
    handelTimeChipClosed() {
      this.selectedTime = null;
    },
  },
  watch: {
    searchQuery: {
      handler(val) {
        this.isDetailsLoading = true;
        this.showEmptyCourseCard = false;
        this.courses = [];
        CourseService.GetList(this.searchQuery).then((res: any) => {
          if (res.length <= 0) {
            this.showEmptyCourseCard = true;
          }
          this.courses = res;
          this.isDetailsLoading = false;
        });
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.courses_container {
  position: relative;

  .card-list {
    padding-top: 100px;

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
    }
  }
}
</style>
