<template>
  <v-card class="assessment-form" :disabled="isLoading" :loading="isLoading">
    <v-card-text class="form-inputs">
      <v-form ref="frmMain" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="3">
            <TextField
              class="fields"
              v-model="assessmentModel.Title"
              label="Title"
              prepend-icon="mdi-rename"
              :rules="[rules.required]"
              clearable
            />
          </v-col>
          <v-col cols="3">
            <TextField
              class="fields"
              v-model="assessmentModel.Objective"
              label="Objective"
              prepend-icon="mdi-code-tags"
              clearable
            />
          </v-col>
          <v-col cols="2">
            <TextField
              class="fields"
              v-model="assessmentModel.PassScore"
              label="Pass Score"
              prepend-icon="mdi-check-circle-outline"
              type="number"
            />
          </v-col>
          <v-col cols="4">
            <ComboBoxField
              clearable
              chips
              multiple
              label="Tag"
              :items="tags"
              item-title="Title"
              item-value="Title"
              closable-chips
              v-model="SelectedTags"
              prepend-icon="mdi-tag"
              :return-object="false"
            />
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="10" class="pt-0">
            <TextAreaField
              class="pt-0"
              v-model="assessmentModel.Description"
              label="Description"
              rows="3"
              prepend-icon="mdi-pencil"
              clearable
            />
          </v-col>
          <v-col cols="2">
            <v-checkbox
              v-model="assessmentModel.IsRestartable"
              label="Is Restartable"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="12" class="mb-5">
          <v-btn
            @click="addQuestion"
            prepend-icon="mdi-plus"
            class="secondary_btn"
            >add new question</v-btn
          >
          <span class="error-text ml-3" v-if="questionIsEmpty"
            >* One Question is required</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model:expanded="expanded"
            show-expand
            :items="assessmentModel.Questions"
            :headers="tableHeaders"
            item-value="Id"
            class="tabel"
            ref="table"
          >
            <template v-slot:item.Title="{ item }">
              <span>{{ truncate(item.Title) }}</span>
            </template>

            <template v-slot:item.Technologies="{ item }">
              {{ getTechnologyName(item.Technologies) }}
            </template>

            <template v-slot:item.Editors="{ item }">
              {{
                item.Editors && item.Editors.length > 0
                  ? getEditorName(item.Editors)
                  : "-"
              }}
            </template>

            <template v-slot:item.Level="{ item }">
              {{ item.Level ? item.Level : "-" }}
            </template>

            <template v-slot:item.Duration="{ item }">
              {{ item.Duration ? formatDuration(item.Duration) : "-" }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon size="small" class="me-2" @click="editQuestion(item)">
                mdi-pencil
              </v-icon>
              <v-icon size="small" @click="deleteQuestion(item)">
                mdi-delete
              </v-icon>
            </template>

            <template v-slot:expanded-row="{ columns, item }">
              <tr>
                <td :colspan="columns.length">
                  <div class="expanded-section">
                    <v-row class="mb-3">
                      <v-col cols="12">
                        <strong>Question:</strong> {{ item.Question }}
                      </v-col>
                    </v-row>
                    <v-row class="mb-3">
                      <v-col cols="12">
                        <strong>Answer:</strong>
                        {{ item.Answer ? item.Answer : "-" }}
                      </v-col>
                    </v-row>
                    <v-row class="mb-3">
                      <v-col cols="6">
                        <strong>Technologies:</strong>
                        {{ getTechnologyName(item.Technologies) }}
                      </v-col>
                      <v-col cols="6">
                        <strong>Editors:</strong>
                        {{
                          item.Editors && item.Editors.length > 0
                            ? getEditorName(item.Editors)
                            : "-"
                        }}
                      </v-col>
                    </v-row>
                    <v-row class="mb-3">
                      <v-col cols="6">
                        <strong>Level:</strong>
                        {{ item.Level ? item.Level : "-" }}
                      </v-col>
                      <v-col cols="6">
                        <strong>Duration:</strong>
                        {{
                          item.Duration ? formatDuration(item.Duration) : "-"
                        }}
                      </v-col>
                    </v-row>
                    <v-row
                      class="mb-3"
                      v-if="item.Evaluations && item.Evaluations.length > 0"
                    >
                      <v-col cols="12"><strong>Evaluations:</strong></v-col>
                    </v-row>
                    <v-row
                      v-for="(evaluation, index) of item.Evaluations"
                      :key="index"
                      class="evaluation-items"
                    >
                      <v-col cols="6">
                        <strong>Evaluation:</strong> {{ evaluation.Criteria }}
                      </v-col>
                      <v-col cols="6">
                        <strong>Point:</strong> {{ evaluation.Point }}
                      </v-col>
                    </v-row>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" class="action-btns">
          <v-btn @click="cancel" class="secondary_btn">Cancel</v-btn>
          <v-btn @click="save" class="primary_btn_v2">Save</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-dialog
      v-model="showAddQuestionModal"
      width="1024"
      height="600"
      class="scrollable-dialog"
    >
      <AddOrEditQuestion
        @closeQuestionModal="closeQuestionModal"
        @saveQuestion="saveQuestion"
        :editors="editors"
        :technologies="technologies"
        :question="selectedQuestionToEdit"
      ></AddOrEditQuestion>
    </v-dialog>
    <v-dialog v-model="showDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="action-btns">
              <v-btn class="secondary_btn" @click="cancelDeletePopup"
                >Cancel</v-btn
              >
              <v-btn class="primary_btn_v2" @click="deleteTableItems"
                >Delete</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import rules from "shared-components/src/utils/validations";
import AssessmentModel from "../../../../../ProjectPortal-SharedComponents/src/models/AssessmentModel";
import { Question } from "../../../../../ProjectPortal-SharedComponents/src/models/AssessmentModel";
import TagModel from "shared-components/src/models/Tag";
import AddOrEditQuestion from "./AddOrEditQuestion.vue";
import AssessmentService from "@/services/AssessmentService";
import { REMOVE_SNAKBAR, SET_SNAKBAR_MODEL } from "../../../store/types";
import { mapMutations } from "vuex";
import { Profile } from "shared-components/src/definitions/config";
import CoreSkill from "shared-components/src/models/CoreSkill";
import Editor from "shared-components/src/models/Editor";
import CoreSkillService from "@/services/CoreSkillService";
import TagService from "@/services/TagService";
import EditorService from "@/services/EditorService";

export default defineComponent({
  props: {
    assessmentId: {
      type: String,
      required: false,
    },
    isPopup: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      rules,
      valid: false,
      showDeleteDialog: false,
      showAddQuestionModal: false,
      isLoading: false,
      expanded: [],
      rowToDeleteIndex: null as number | null,
      assessmentModel: {} as AssessmentModel,
      tags: [] as TagModel[],
      editors: [] as Editor[],
      technologies: [] as CoreSkill[],
      SelectedTags: [] as string[],
      selectedQuestionToEdit: null as Question | null,
      tableHeaders: [
        { title: "Title", key: "Title" },
        { title: "Technologies", key: "Technologies" },
        { title: "Editors", key: "Editors" },
        { title: "Level", key: "Level" },
        { title: "Duration", key: "Duration" },
        { title: "Actions", key: "actions" },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.loadSkillsList();
    await this.loadTagsList();
    await this.loadEditorList();
    if (this.assessmentId) {
      this.assessmentModel = await AssessmentService.GetById(this.assessmentId);
      this.assessmentModel.Tags.forEach((tag: any) => {
        this.SelectedTags.push(tag.Title);
      });
    }
    this.isLoading = false;
  },
  methods: {
    ...mapMutations([SET_SNAKBAR_MODEL, REMOVE_SNAKBAR]),
    getTechnologyName(technologies: CoreSkill[]) {
      const tIds = technologies.map((item) => item.id);
      return this.technologies
        .filter((item) => tIds.includes(item.id))
        .map((item) => item.Text)
        .join(", ");
    },
    getEditorName(editors: Editor[]) {
      const eIds = editors.map((item) => item.Id);
      return this.editors
        .filter((item) => eIds.includes(item.Id))
        .map((item) => item.Value)
        .join(", ");
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
    closeQuestionModal() {
      this.showAddQuestionModal = false;
      this.selectedQuestionToEdit = null;
    },
    async loadSkillsList() {
      this.technologies = await CoreSkillService.getList();
    },
    async loadTagsList() {
      this.tags = await TagService.GetTagsByType("Assessment");
    },
    async loadEditorList() {
      this.editors = await EditorService.getList();
    },
    saveQuestion(model: Question) {
      if (!this.assessmentModel.Questions) {
        this.assessmentModel.Questions = [];
      }
      if (model.Id != null && model.Id != undefined && model.Id != "") {
        const index = this.assessmentModel.Questions.findIndex(
          (item) => item.Id == model.Id
        );
        this.assessmentModel.Questions[index] = model;
      } else {
        model.Id = `${this.assessmentModel.Questions.length + 1}`;
        this.assessmentModel.Questions.push(model);
      }

      this.closeQuestionModal();
    },
    truncate(item: any) {
      if (item && item.length > 30) {
        return item.substring(0, 30) + "...";
      } else {
        return item;
      }
    },
    addQuestion(Id: any) {
      this.showAddQuestionModal = true;
    },
    cancel() {
      if (!this.isPopup) {
        this.$router.push("/client/training/assessment");
      } else {
        this.$emit("assessmentClosed");
      }
    },
    async save() {
      let isValid = await (this.$refs.frmMain as any).validate();
      isValid = isValid.valid && !this.questionIsEmpty;
      if (isValid) {
        try {
          this.isLoading = true;
          for (let question of this.assessmentModel.Questions) {
            question.Id = "";
          }
          await AssessmentService.CreateOrEdit(this.assessmentModel);
          this.SET_SNAKBAR_MODEL({
            body: this.assessmentId
              ? "Assessment Updated Successfuly"
              : "Assessment Created Successfuly",
            status: "success",
            button: "ok",
            timeout: Profile.ErrorSetting.SnackbarTimeout,
            show: true,
            handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
          });
          if (!this.isPopup) {
            this.$router.push("/client/training/assessment");
          } else {
            this.$emit("assessmentSaved");
          }
        } catch (error) {
          this.SET_SNAKBAR_MODEL({
            body: this.assessmentId
              ? "There is an Issue on updating the Assessment"
              : "There is an Issue on creating the Assessment",
            status: "error",
            button: "ok",
            timeout: Profile.ErrorSetting.SnackbarTimeout,
            show: true,
            handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    editQuestion(item: any) {
      this.selectedQuestionToEdit = { ...item };
      this.showAddQuestionModal = true;
    },
    deleteQuestion(item: any) {
      this.rowToDeleteIndex = this.assessmentModel.Questions.indexOf(item);
      this.showDeleteDialog = true;
    },
    cancelDeletePopup() {
      this.rowToDeleteIndex = null;
      this.showDeleteDialog = false;
    },
    deleteTableItems() {
      if (
        this.rowToDeleteIndex !== null &&
        this.rowToDeleteIndex !== undefined
      ) {
        this.assessmentModel.Questions.splice(this.rowToDeleteIndex, 1);
        this.rowToDeleteIndex = null;
      }
      this.showDeleteDialog = false;
    },
  },
  computed: {
    questionIsEmpty() {
      return (
        !this.assessmentModel.Questions ||
        this.assessmentModel.Questions.length < 1
      );
    },
  },
  components: { AddOrEditQuestion },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/_sizes.scss";

.popup-save {
  margin-left: 8px;
  background-color: $racecar;
}

.fields {
  padding: 0px;
}

.assessment-form {
  border-radius: 8px;

  .expanded-section {
    padding: 20px;

    .evaluation-items {
      margin: 10px;
    }
  }
}
</style>
