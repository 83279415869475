import ApiService from "./ApiService";
import {
  PositionDetailResponseModel,
  PositionModel,
} from "shared-components/src/services/openApi/api";
import Utils from "@/Helpers/Utils";

export default class PositionService {
  public static async SavePosition(
    model: any
  ): Promise<PositionDetailResponseModel> {
    return new Promise((resolve, reject) => {
      model.StartDate = Utils.toVsDateFormat(model.StartDate);
      ApiService.post(`/client/position`, model)
        .then((projectResult) => {
          const response = projectResult.data as PositionDetailResponseModel;
          resolve(response);
        })
        .catch((projectError) => reject(projectError));
    });
  }

  public static async SavePositionDetails(
    positionId: string,
    model: any
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      model.StartDate = Utils.toVsDateFormat(model.StartDate);
      ApiService.post(`/client/position/${positionId}/details`, model)
        .then((projectResult) => {
          resolve(projectResult.data);
        })
        .catch((projectError) => reject(projectError));
    });
  }

  private static ConvertToPositionModel(
    model: any
  ): PositionDetailResponseModel {
    return {
      Commitments: model.Commitments,
      CountryId: model.CountryId,
      JobDescription: model.JobDescription,
      JobTitles: model.JobTitles
        ? model.JobTitles.map((item: any) => item.id)
        : [],
      Id: model.Id,
      JobRoleId: model.JobRole,
      NoOfPositions: model.NoOfPositions,
      ProjectId: model.ProjectId,
      Skills: model.Skills ? model.Skills.map((item: any) => item.id) : [],
      StartDate: model.StartDate,
      Status: model.Status,
    } as PositionDetailResponseModel;
  }
}
