import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "ml-3" }
const _hoisted_2 = { class: "text-right mr-3" }
const _hoisted_3 = { class: "rounded-lg listItem ma-3 pa-3 mr-md-0 text-center" }
const _hoisted_4 = { class: "rounded-lg listItem ma-3 pa-3 mr-md-0 ml-md-0 text-center" }
const _hoisted_5 = { class: "rounded-lg listItem ma-3 pa-3 ml-md-0 text-center" }
const _hoisted_6 = {
  key: 0,
  class: "emptyResult d-flex justify-center mb-6"
}
const _hoisted_7 = { class: "pa-md-16 ma-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRange = _resolveComponent("DateRange")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_ProfileAvatar = _resolveComponent("ProfileAvatar")!
  const _component_EpicList = _resolveComponent("EpicList")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_AddEpic = _resolveComponent("AddEpic")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_PositionMenu = _resolveComponent("PositionMenu")!
  const _component_CommitmentMenu = _resolveComponent("CommitmentMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_v_card, {
        loading: _ctx.loading || _ctx.tableLoading,
        disabled: _ctx.loading || _ctx.tableLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_DateRange, {
                offset: _ctx.dayOffset,
                onFetchData: _ctx.fetchData
              }, null, 8, ["offset", "onFetchData"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading", "disabled"]),
      _createVNode(_component_v_card, {
        class: "mt-3 transparent position-static",
        loading: _ctx.loading || _ctx.tableLoading,
        disabled: _ctx.loading || _ctx.tableLoading
      }, {
        default: _withCtx(() => [
          (_ctx.dashboardProjects && _ctx.dashboardProjects.length > 0)
            ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                default: _withCtx(() => [
                  (!_ctx.tableLoading)
                    ? (_openBlock(), _createBlock(_component_v_row, {
                        key: 0,
                        class: "flex-child projItem"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dashboardProjects, (project) => {
                            return (_openBlock(), _createBlock(_component_v_col, {
                              key: project.id,
                              cols: "12",
                              md: "6",
                              sm: "8",
                              xs: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card, { class: "position-static" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_row, { class: "pl-3 pr-3" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, { md: "10" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("h3", _hoisted_1, _toDisplayString(project.Name), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_v_col, { md: "2" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("h3", _hoisted_2, _toDisplayString(project.totalEffort), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_v_divider, { class: "mx-4" }),
                                    _createVNode(_component_v_card_text, { class: "pl-0 pr-0" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ProfileAvatar, {
                                          users: project.teamMembers,
                                          projectId: project.id,
                                          project: project,
                                          justify: 'center',
                                          hasAll: true,
                                          hasMore: true,
                                          onSelectUser: _ctx.selectUser,
                                          showActionIcon: true,
                                          onShowPositionMenu: _ctx.showPositionMenuPopup,
                                          onShowCommitmentMenu: _ctx.showCommitmentMenuPopup,
                                          details: _ctx.details,
                                          skills: _ctx.skills
                                        }, null, 8, ["users", "projectId", "project", "onSelectUser", "onShowPositionMenu", "onShowCommitmentMenu", "details", "skills"]),
                                        _createVNode(_component_v_row, { class: "v-row justify-center mt-2 px-6" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              xs: "12",
                                              sm: "12",
                                              md: "12",
                                              class: "px-6 inner-head-texts"
                                            }, {
                                              default: _withCtx(() => [
                                                _cache[5] || (_cache[5] = _createElementVNode("h4", null, "Code Contributions", -1)),
                                                _createVNode(_component_v_divider)
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              xs: "12",
                                              sm: "12",
                                              md: "4"
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_3, _toDisplayString(project.totalLinesAdded) + " Lines Added ", 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              xs: "12",
                                              sm: "12",
                                              md: "4"
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_4, _toDisplayString(project.totalLinesModified) + " Lines Modified ", 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_v_col, {
                                              cols: "12",
                                              xs: "12",
                                              sm: "12",
                                              md: "4"
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_5, _toDisplayString(project.totalLinesDeleted) + " Lines Deleted ", 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_col, {
                                          cols: "12",
                                          xs: "12",
                                          sm: "12",
                                          md: "12",
                                          class: "px-9 inner-head-texts"
                                        }, {
                                          default: _withCtx(() => [
                                            _cache[6] || (_cache[6] = _createElementVNode("h4", null, "Epics", -1)),
                                            _createVNode(_component_v_divider)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_EpicList, { project: project }, null, 8, ["project"]),
                                        _createVNode(_component_v_row, { class: "justify-center" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_col, { cols: "10" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_btn, {
                                                  color: "accent",
                                                  dark: "",
                                                  class: "fullSize-button rounded-lg",
                                                  onClick: _withModifiers(($event: any) => (_ctx.addNewEpic(project.id)), ["stop"])
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_icon, null, {
                                                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                        _createTextVNode("mdi-plus")
                                                      ])),
                                                      _: 1
                                                    }),
                                                    _cache[8] || (_cache[8] = _createTextVNode(" New Epic "))
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_v_card_text, { key: 1 }, {
                default: _withCtx(() => [
                  (!_ctx.tableLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.message), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
        ]),
        _: 1
      }, 8, ["loading", "disabled"]),
      (_ctx.showNewItem)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 0,
            modelValue: _ctx.showNewItem,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showNewItem) = $event)),
            persistent: "",
            "max-width": "600px",
            onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.showNewItem = false), ["esc"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AddEpic, {
                projectName: _ctx.selectedProjectName,
                projectId: _ctx.selectedProjectId,
                epicLoading: _ctx.epicLoading,
                onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNewItem = false)),
                onSuccess: _ctx.epicAdded
              }, null, 8, ["projectName", "projectId", "epicLoading", "onSuccess"])
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showPositionMenu)
      ? (_openBlock(), _createBlock(_component_PositionMenu, {
          key: 0,
          details: _ctx.details,
          skills: _ctx.skills,
          projectLeads: _ctx.projectLeads,
          publicHolidayZones: _ctx.publicHolidayZones,
          officeLocations: _ctx.officeLocations,
          lineManagers: _ctx.lineManagers,
          portfolioManagers: _ctx.portfolioManagers,
          officeSpaces: _ctx.officeSpaces,
          projects: _ctx.dashboardProjects,
          positionId: _ctx.selectedPositionId,
          projectId: _ctx.selectedProjectId,
          onOnClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPositionMenu = false))
        }, null, 8, ["details", "skills", "projectLeads", "publicHolidayZones", "officeLocations", "lineManagers", "portfolioManagers", "officeSpaces", "projects", "positionId", "projectId"]))
      : _createCommentVNode("", true),
    (_ctx.showCommitmentMenu)
      ? (_openBlock(), _createBlock(_component_CommitmentMenu, {
          key: 1,
          details: _ctx.details,
          projects: _ctx.dashboardProjects,
          projectId: _ctx.selectedProjectId,
          commitmentId: _ctx.selectedCommitmentId,
          onOnClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCommitmentMenu = false))
        }, null, 8, ["details", "projects", "projectId", "commitmentId"]))
      : _createCommentVNode("", true)
  ], 64))
}