import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "assessment-container form-inputs"
}
const _hoisted_2 = { class: "favorite-btn" }
const _hoisted_3 = {
  key: 0,
  class: "f-body-1"
}
const _hoisted_4 = {
  key: 1,
  class: "f-body-2"
}
const _hoisted_5 = {
  key: 2,
  class: "flex-section f-body-2"
}
const _hoisted_6 = { class: "expanded-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    disabled: _ctx.isLoading,
    loading: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      (_ctx.assessmentDetail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.assessmentDetail.IsFavorite != true)
                    ? (_openBlock(), _createBlock(_component_v_tooltip, {
                        key: 0,
                        location: "bottom"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps(props, {
                            style: {"min-width":"0"},
                            variant: "text",
                            onClick: _ctx.addToFavorite
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[0] || (_cache[0] = [
                                  _createTextVNode(" mdi-heart ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _cache[1] || (_cache[1] = _createTextVNode(" Add to Favorites "))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.assessmentDetail.IsFavorite == true)
                    ? (_openBlock(), _createBlock(_component_v_tooltip, {
                        key: 1,
                        location: "bottom"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps(props, {
                            style: {"min-width":"0"},
                            variant: "text",
                            onClick: _ctx.addToFavorite
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { class: "heart_icon" }, {
                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                  _createTextVNode(" mdi-heart ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _cache[3] || (_cache[3] = _createTextVNode(" Remove from Favorites "))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps(props, {
                        style: {"min-width":"0"},
                        variant: "text",
                        onClick: _ctx.editCourse
                      }), {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode(" mdi-pen ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040, ["onClick"])
                    ]),
                    default: _withCtx(() => [
                      _cache[5] || (_cache[5] = _createTextVNode(" Edit "))
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              class: "details-section"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("h2", null, _toDisplayString(_ctx.assessmentDetail.Title), 1)
                                ]),
                                (_ctx.assessmentDetail.Description)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                      _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                        activator: _withCtx(({ props }) => [
                                          _createElementVNode("span", _mergeProps({ class: "description" }, props), _toDisplayString(_ctx.assessmentDetail.Description), 17)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[6] || (_cache[6] = _createTextVNode(" Description "))
                                        ]),
                                        _: 1
                                      })
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.assessmentDetail.Objective)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                      _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                        activator: _withCtx(({ props }) => [
                                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                _createTextVNode("mdi-human-male-board")
                                              ])),
                                              _: 1
                                            }),
                                            _createTextVNode(" " + _toDisplayString(_ctx.assessmentDetail.Objective), 1)
                                          ], 16)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[8] || (_cache[8] = _createTextVNode(" Objective "))
                                        ]),
                                        _: 1
                                      }),
                                      _cache[11] || (_cache[11] = _createTextVNode(" - ")),
                                      (_ctx.assessmentDetail.PassScore)
                                        ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                            key: 0,
                                            location: "bottom"
                                          }, {
                                            activator: _withCtx(({ props }) => [
                                              _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                                _createVNode(_component_v_icon, null, {
                                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                    _createTextVNode("mdi-chart-box")
                                                  ])),
                                                  _: 1
                                                }),
                                                _createTextVNode(" " + _toDisplayString(_ctx.assessmentDetail.PassScore), 1)
                                              ], 16)
                                            ]),
                                            default: _withCtx(() => [
                                              _cache[10] || (_cache[10] = _createTextVNode(" Pass Score "))
                                            ]),
                                            _: 1
                                          }))
                                        : _createCommentVNode("", true)
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.assessmentDetail.Tags.length > 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                      _createVNode(_component_v_tooltip, { location: "bottom" }, {
                                        activator: _withCtx(({ props }) => [
                                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => _cache[12] || (_cache[12] = [
                                                _createTextVNode("mdi-tag")
                                              ])),
                                              _: 1
                                            })
                                          ], 16)
                                        ]),
                                        default: _withCtx(() => [
                                          _cache[13] || (_cache[13] = _createTextVNode(" Tags "))
                                        ]),
                                        _: 1
                                      }),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assessmentDetail.Tags, (tag, index) => {
                                        return (_openBlock(), _createBlock(_component_v_chip, { key: index }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(tag.Title), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true),
                                _cache[25] || (_cache[25] = _createElementVNode("p", null, "Questions:", -1)),
                                _createVNode(_component_v_expansion_panels, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assessmentDetail.Questions, (question, index) => {
                                      return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                                        key: index,
                                        class: "f-body-2"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_expansion_panel_title, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_row, { "no-gutters": "" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_col, { cols: "6" }, {
                                                    default: _withCtx(() => [
                                                      _cache[14] || (_cache[14] = _createElementVNode("strong", null, "Question:", -1)),
                                                      _createTextVNode(" " + _toDisplayString(_ctx.truncate(question.Question)), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_v_col, { cols: "6" }, {
                                                    default: _withCtx(() => [
                                                      _cache[15] || (_cache[15] = _createElementVNode("strong", null, "Technologies: ", -1)),
                                                      _createTextVNode(" " + _toDisplayString(question.Technologies.map(
                              (item) => item.Text
                            ).join(", ")), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_v_expansion_panel_text, null, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_6, [
                                                _createVNode(_component_v_row, { class: "mb-3" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "12" }, {
                                                      default: _withCtx(() => [
                                                        _cache[16] || (_cache[16] = _createElementVNode("strong", null, "Question:", -1)),
                                                        _createTextVNode(" " + _toDisplayString(question.Question), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_row, { class: "mb-3" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "12" }, {
                                                      default: _withCtx(() => [
                                                        _cache[17] || (_cache[17] = _createElementVNode("strong", null, "Answer:", -1)),
                                                        _createTextVNode(" " + _toDisplayString(question.Answer ? question.Answer : "-"), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_row, { class: "mb-3" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "6" }, {
                                                      default: _withCtx(() => [
                                                        _cache[18] || (_cache[18] = _createElementVNode("strong", null, "Technologies:", -1)),
                                                        _createTextVNode(" " + _toDisplayString(question.Technologies.map(
                                (item) => item.Text
                              ).join(", ")), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_v_col, { cols: "6" }, {
                                                      default: _withCtx(() => [
                                                        _cache[19] || (_cache[19] = _createElementVNode("strong", null, "Editors:", -1)),
                                                        _createTextVNode(" " + _toDisplayString(question.Editors && question.Editors.length > 0
                                ? question.Editors.map(
                                    (item) => item.Value
                                  ).join(", ")
                                : "-"), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_v_row, { class: "mb-3" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_col, { cols: "6" }, {
                                                      default: _withCtx(() => [
                                                        _cache[20] || (_cache[20] = _createElementVNode("strong", null, "Level:", -1)),
                                                        _createTextVNode(" " + _toDisplayString(question.Level ? question.Level : "-"), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_v_col, { cols: "6" }, {
                                                      default: _withCtx(() => [
                                                        _cache[21] || (_cache[21] = _createElementVNode("strong", null, "Duration:", -1)),
                                                        _createTextVNode(" " + _toDisplayString(question.Duration
                                ? _ctx.formatDuration(question.Duration)
                                : "-"), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                (
                            question.Evaluations &&
                            question.Evaluations.length > 0
                          )
                                                  ? (_openBlock(), _createBlock(_component_v_row, {
                                                      key: 0,
                                                      class: "mb-3"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_col, { cols: "12" }, {
                                                          default: _withCtx(() => _cache[22] || (_cache[22] = [
                                                            _createElementVNode("strong", null, "Evaluations:", -1)
                                                          ])),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    }))
                                                  : _createCommentVNode("", true),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.Evaluations, (evaluation, index) => {
                                                  return (_openBlock(), _createBlock(_component_v_row, {
                                                    key: index,
                                                    class: "evaluation-items"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_col, { cols: "6" }, {
                                                        default: _withCtx(() => [
                                                          _cache[23] || (_cache[23] = _createElementVNode("strong", null, "Evaluation:", -1)),
                                                          _createTextVNode(" " + _toDisplayString(evaluation.Criteria), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1024),
                                                      _createVNode(_component_v_col, { cols: "6" }, {
                                                        default: _withCtx(() => [
                                                          _cache[24] || (_cache[24] = _createElementVNode("strong", null, "Point:", -1)),
                                                          _createTextVNode(" " + _toDisplayString(evaluation.Point), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                }), 128))
                                              ])
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["disabled", "loading"]))
}