import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, {
    nav: "",
    class: "main-menu-items"
  }, {
    default: _withCtx(() => [
      (_ctx.isProjectLead)
        ? (_openBlock(), _createBlock(_component_v_list_item, {
            key: 0,
            to: "/client/productivity",
            "prepend-icon": "mdi-chart-arc"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Productivity")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isPortfolioManager)
        ? (_openBlock(), _createBlock(_component_v_list_item, {
            key: 1,
            to: "/client/cost",
            "prepend-icon": "mdi-file-tree"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Cost")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_list_group, { class: "dashboard-menu" }, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_list_item, _mergeProps(props, { "prepend-icon": "mdi-book-education" }), {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Training")
            ])),
            _: 2
          }, 1040)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            to: "/client/training/roadMap",
            title: "Training RoadMaps",
            "prepend-icon": "mdi-account-school",
            value: "RoadMap"
          }),
          _createVNode(_component_v_list_item, {
            to: "/client/training/courses",
            title: "Courses",
            "prepend-icon": "mdi-school",
            value: "Courses"
          }),
          _createVNode(_component_v_list_item, {
            to: "/client/training/assessment",
            title: "Assessments",
            "prepend-icon": "mdi-book-clock",
            value: "Assessment"
          })
        ]),
        _: 1
      }),
      (_ctx.isPortfolioManager && false)
        ? (_openBlock(), _createBlock(_component_v_list_item, {
            key: 2,
            to: "/client/search/wizard",
            "prepend-icon": "mdi-file-tree"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Search")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isPortfolioManager || _ctx.isClient)
        ? (_openBlock(), _createBlock(_component_v_list_item, {
            key: 3,
            to: "/client/management",
            "prepend-icon": "mdi-file-tree"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Management")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isPortfolioManager || _ctx.isClient)
        ? (_openBlock(), _createBlock(_component_v_list_item, {
            key: 4,
            to: "/client/planning",
            "prepend-icon": "mdi-text-account"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Planning")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}