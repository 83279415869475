import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 search-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "input-label" }, "Description", -1)),
    _createVNode(_component_v_textarea, {
      class: "search-box",
      "bg-color": "primary",
      density: "comfortable",
      variant: "outlined",
      "hide-details": "auto",
      placeholder: "Search",
      hint: "Describe your job role",
      modelValue: _ctx.description,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.description) = $event)),
      "auto-grow": ""
    }, null, 8, ["modelValue"]),
    _createVNode(_component_v_btn, {
      ripple: false,
      class: "search-icon",
      color: "primary",
      variant: "plain",
      onClick: _ctx.search
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          color: "secondary",
          icon: "mdi-magnify"
        })
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}