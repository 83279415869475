import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    class: _normalizeClass([_ctx.isTotal ? 'text-white pb-2' : ''])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        class: "text-center"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, "Estimated " + _toDisplayString(_ctx.isTotal ? 'total' : '') + " cost: AUD " + _toDisplayString(_ctx.getPerHourPrice) + " per work hour", 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, "Approx. AUD " + _toDisplayString(_ctx.getPerMonthPrice) + " per month", 1),
          (!_ctx.isTotal)
            ? (_openBlock(), _createBlock(_component_v_tooltip, {
                key: 0,
                text: _ctx.getPriceTooltip(),
                "max-width": "300"
              }, {
                activator: _withCtx(({ props }) => [
                  _createVNode(_component_v_btn, _mergeProps({
                    variant: "text",
                    size: "x-small",
                    icon: "mdi-help"
                  }, props), null, 16)
                ]),
                _: 1
              }, 8, ["text"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}