<template>
  <notification-list
    v-if="clientInfo.userId"
    :userId="clientInfo.userId"
    :isClient="true"
  />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import NotificationList from "shared-components/src/components/Notification/NotificationList.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    NotificationList,
  },
  computed: {
    ...mapGetters(["clientInfo"]),
  },
});
</script>
