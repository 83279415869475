<template>
  <div class="main-page-container">
    <v-progress-circular :width="3" :size="70" color="red" indeterminate v-if="isDetailsLoading"
      class="ma-auto"></v-progress-circular>
    <div class="main-search-container" v-else>
      <div>
        <SelectedChips :details="details" :skills="skills" :criteria="criteria" @criteriaChanged="criteriaChanged" />
      </div>
      <div class="result-box">
        <v-row class="h-100">
          <v-col xs="12" md="4">
            <Filter :details="details" :skills="skills" :criteria="criteria" :positionJD="positionJD"
              :experienceYearList="experienceYears" :locations="locations" @doSearch="doSearch"></Filter>
          </v-col>
          <v-progress-circular :width="3" :size="70" color="red" indeterminate v-if="isResultLoading"
            class="ma-auto"></v-progress-circular>
          <v-col xs="12" md="8" v-else>
            <div>
              <v-row class="title-row">
                <v-col cols="12" sm="12" md="8" class="align-self-center">
                  <div>Results - {{ employees.length }} cases</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="4" v-for="(item, index) of employees" :key="index">
                  <EmployeeItem :employee="item" :skills="skills" :details="details" :showInScene="showInScene"
                    @ViewTmDetails="ViewTmDetails" />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";
import store from "../../store";
import CoreSkillService from "@/services/CoreSkillService";
import DetailsService from "@/services/DetailsService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import Details from "shared-components/src/models/Details";
import EmployeeItem from "./EmployeeItem.vue";
import Filter from "./Filter.vue";
import { Profile } from "shared-components/src/definitions/config";
import SelectedChips from "./SelectedChips.vue";
import { AzureAISearchTeammemberByClientResponseModel, AzureAISearchTeammemberRequestModel, AzureAISearchApi } from "shared-components/src/services/openApi/api";

export default defineComponent({
  props: {
    positionJD: {
      type: String,
      required: false,
    },
    showInScene: {
      type: Boolean,
      default: false,
    },
    positionSkills: {
      type: Object as PropType<string[]>,
      required: true
    },
    positionRole: {
      type: String,
      required: true
    }
  },
  async mounted() {
    this.isDetailsLoading = true;
    await this.loadSkillsList();
    await this.loadDetailsList();
    if (store.getters.searchEmployeeCriteria) {
      this.criteria = {
        ...store.getters.searchEmployeeCriteria,
      } as AzureAISearchTeammemberRequestModel;
    }
    if(this.positionSkills){
      this.criteria.skills = this.skills.filter((item: any) => this.positionSkills.includes(item.id)).map((item: any) => item.Text);
    }
    if(this.positionRole){
      this.criteria.jobRoles = this.details.filter((item: any) => this.positionRole.includes(item.id)).map((item: any) => item.Name);
    }
    

    await this.search();
    store.dispatch("setCriteria", {
      searchEmployeeCriteria: null,
    });

    this.updateCriteriaByAvailableEmployees();

    this.isDetailsLoading = false;
  },
  components: { EmployeeItem, Filter, SelectedChips },
  data() {
    return {
      experienceYears: Profile.Members.SkillExperienceList,
      criteria: {} as AzureAISearchTeammemberRequestModel,
      locations: [] as {text: String}[],
      skills: [] as CoreSkill[],
      details: [] as Details[],
      employees: [] as AzureAISearchTeammemberByClientResponseModel[],
      isDetailsLoading: true,
      isResultLoading: false,
      searchRegNumber: 0,
    };
  },
  methods: {
    ViewTmDetails(event: any) {
      this.$emit("ViewTmDetails", event);
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails();
    },
    updateCriteriaByAvailableEmployees() {
      this.locations = this.employees.map(item => item.Country ? { text: item.Country } : null).filter(item => item !== null) as { text: String }[];
      this.experienceYears = Array.from(new Set(this.employees.map(item => item.ExperienceYear))).filter((v): v is string => v !== undefined).sort();
    },
    async doSearch(criteria: any) {
      this.criteria = criteria;
      await this.search();
      this.updateCriteriaByAvailableEmployees();
    },
    async criteriaChanged(criteria: any) {
      this.criteria = criteria;
      this.updateCriteriaByAvailableEmployees();
    },
    async search() {
      this.isResultLoading = true;
      this.searchRegNumber += 1;
      const searchNumber = this.searchRegNumber;
      if (this.criteria) {
        await new AzureAISearchApi().searchTeammemberByClient({
          jobRoles: this.criteria.jobRoles ? [...this.criteria.jobRoles] : undefined,
          skills: this.criteria.skills
            ? [...this.criteria.skills]
            : undefined,
          totalExperience: this.criteria.totalExperience ?? undefined,
          locations: (this.criteria as any).locations
            ? [...(this.criteria as any).locations]
            : undefined,
          description: this.criteria.description ?? undefined
        })
        .then((items: any) => this.employees = items.data)
        .catch((err: any) => store.dispatch("showErrorMessage", err.response.data));
      }
      if (searchNumber == this.searchRegNumber) {
        this.isResultLoading = false;
      }
    },
  },
  computed: {},
});
</script>
<style scoped lang="scss">
.main-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  .main-search-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .result-box {
      height: 100%;

      .title-row {
        color: #fff;
      }
    }
  }
}
</style>
