<template>
  <div class="main-selected-chips-box">
    Filters:
    <div class="chip-box">
      <v-chip
        :closable="item.closable"
        v-for="(item, index) in criteriaChips"
        :key="index"
        :color="item.background"
        variant="outlined"
        @click:close="RemoveFromCriteria(item)"
      >
        {{ item.title }}
      </v-chip>
    </div>
  </div>
</template>
<script lang="ts">
import CoreSkill from "shared-components/src/models/CoreSkill";
import Details from "shared-components/src/models/Details";
import { AzureAISearchTeammemberRequestModel } from "shared-components/src/services/openApi/api";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    criteria: {
      type: Object as () => AzureAISearchTeammemberRequestModel,
      required: true,
    },
    skills: {
      type: Array as () => CoreSkill[],
      required: true,
    },
    details: {
      type: Array as () => Details[],
      required: true,
    },
  },
  async mounted() {
    this.generateCriteriaChips();
  },
  components: {},
  data() {
    return {
      searchCriteria: this.criteria,
      criteriaChips: [] as {
        title: string;
        type: string;
        closable: Boolean;
        id: string;
        background: string;
      }[],
      backgrounds: {
        age: "#7fe300",
        jobRole: "#ccdb04",
        skill: "#0bffeb",
        experience: "#f3f3f3",
        location: "#ef8c8c",
      },
    };
  },
  methods: {
    RemoveFromCriteria(item: any) {
      switch (item.type) {
        case "Age":
          this.searchCriteria.age = undefined
          break;
        case "Role":
          const roleIndex = this.searchCriteria.jobRoles?.findIndex(
            (it) => it == item.id
          );
          if (roleIndex != null || roleIndex != undefined)
            this.searchCriteria.jobRoles?.splice(roleIndex, 1);
          break;
        case "Skill":
          const skillIndex = this.searchCriteria.skills?.findIndex(
            (it) => it == item.id
          );
          if (skillIndex != null || skillIndex != undefined)
            this.searchCriteria.skills?.splice(skillIndex, 1);
          break;
        case "Experience":
          this.searchCriteria.totalExperience = undefined;
          break;
        case "Locations":
          const locationIndex = this.searchCriteria.locations?.findIndex(
            (it) => it == item.id
          );
          if (locationIndex != null || locationIndex != undefined) {
            this.searchCriteria.locations?.splice(locationIndex, 1);
          }
          break;
      }
      this.$emit("criteriaChanged", this.searchCriteria);
    },
    generateCriteriaChips() {
      this.criteriaChips = [];

      setTimeout(() => {
        const chips = [];
        if (this.searchCriteria.age) {
          chips.push({
            title: `Age: ${this.searchCriteria.age[0]} To ${this.searchCriteria.age[1]}`,
            type: "Age",
            closable: true,
            id: "",
            background: this.backgrounds.age,
          });
        }
        if (this.searchCriteria.jobRoles) {
          this.searchCriteria.jobRoles.forEach((role) => {
            const roleItem = this.details.find((item) => item.Name == role);
            if (roleItem) {
              chips.push({
                title: `Role: ${roleItem.Name}`,
                type: "Role",
                closable: true,
                id: roleItem.Name,
                background: this.backgrounds.jobRole,
              });
            }
          });
        }
        if (
          this.searchCriteria.skills &&
          this.searchCriteria.skills.length > 0
        ) {
          this.searchCriteria.skills.forEach((skill) => {
            const skillItem = this.skills.find((item) => item.Text == skill);
            if (skillItem) {
              chips.push({
                title: `Skill: ${skillItem.Text}`,
                type: "Skill",
                closable: true,
                id: skillItem.Text,
                background: this.backgrounds.skill,
              });
            }
          });
        }
        if (this.searchCriteria.totalExperience) {
          chips.push({
            title: `Experience: ${this.searchCriteria.totalExperience} or More`,
            type: "Experience",
            closable: true,
            id: "",
            background: this.backgrounds.experience,
          });
        }
        if (
          this.searchCriteria.locations &&
          this.searchCriteria.locations.length > 0
        ) {
          this.searchCriteria.locations.forEach((location) => {
            chips.push({
              title: `Locations: ${location}`,
              type: "Locations",
              closable: true,
              id: location,
              background: this.backgrounds.location,
            });
          });
        }
        this.criteriaChips = chips;
      }, 5);
    },
  },
  watch: {
    criteria: {
      handler() {
        this.generateCriteriaChips();
      },
      deep: true,
    },
  },
  computed: {},
});
</script>
<style scoped lang="scss">
.main-selected-chips-box {
  display: flex;
  align-items: center;
  color: #fff;
  min-height: 62px;
  .chip-box {
    padding: 15px;
    color: #fff;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
