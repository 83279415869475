import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.type == 'Risk' ? 'bg-error' : _ctx.type == 'Warning' ? 'bg-warning' : 'bg-info'], "alert-item"])
  }, [
    _createVNode(_component_v_icon, { icon: _ctx.icon }, null, 8, ["icon"])
  ], 2)), [
    [_directive_tooltip, _ctx.tooltip, "top"]
  ])
}